import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Modal from '../../../../components/modal/Modal';
import Paginator from '../../../../components/paginator/Paginator';
import { translateMonth, translatePeriodStatuses, translateReportStatuses } from '../../../../helpers/helpers';
import { agentsCreateAgentThunk, AgentsFormActionProps, selectorAgentsIsLoading, } from '../../../../store/agents/agentsSlice';
import { banksLoadBanksListThunk, selectorBank } from '../../../../store/banks/banksSlice';
import { filesLoadFilesListThunk, selectorFilesGetFile } from '../../../../store/files/filesSlice';
import { periodsLoadCurrentPeriodThunk, periodsSendStatusThunk, PeriodStatuses, selectorCurrentPeriod, selectorPeriodsIsCurrentPeriodLoading, } from '../../../../store/periods/periodsSlice';
import { FileStatuses, reportsCreateReportsThunk, reportsFilesStatusUpdateThunk, ReportsGenerateReportsDocumentsThunk, reportsLoadReportsListThunk, ReportsSearchFormActionProps, reportsUpdateReportsThunk, selectorReportsCount, selectorReportsIsLoading, selectorReportsList } from '../../../../store/reports/reportsSlice';
import { UserRoles } from '../../../../store/users/usersSlice';
import AgentsForm from '../../../agents/components/form/AgentsForm';
import ReportsSearchForm from '../../../reports/components/search-form/ReportsSearchForm';
import styles from './PeriodInfoPage.module.scss';

const SCALE = 20;

// TODO интерфейс может расширятся в дальнейшем
interface TableSort {
    field: string;
    sort: number;
};

const PeriodInfoPage = () => {
    const dispatch = useAppDispatch();

    const params = useParams();
    const periodId = params.periodId || '';

    const period = useAppSelector(selectorCurrentPeriod);
    const reports = useAppSelector(selectorReportsList);
    const docInfo = useAppSelector(selectorFilesGetFile(period?.excelFileId || '')); // TODO должен быть отдельный компонент
    const isPeriodsLoading = useAppSelector(selectorPeriodsIsCurrentPeriodLoading);
    const isReportsLoading = useAppSelector(selectorReportsIsLoading);
    const isAgentsLoading = useAppSelector(selectorAgentsIsLoading);
    const reportsCount = useAppSelector(selectorReportsCount);
    const bankData = useAppSelector(selectorBank(period?.bankId || '')); // TODO при увеличении количества банков нужно будет переделать на получение конкретного банка

    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [inn, setInn] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [tableSort, setTableSort] = useState<TableSort | null>(null);
    const [filter, setFilter] = useState<ReportsSearchFormActionProps>({});

    useEffect(() => {
        dispatch(periodsLoadCurrentPeriodThunk({ periodId }))
    }, []);

    useEffect(() => {
        let fileIds: string[] = [];

        dispatch(reportsLoadReportsListThunk({ periodId }));

        if (period) {
            fileIds.push(period.excelFileId);
        }

        dispatch(filesLoadFilesListThunk({ fileIds }));
        dispatch(banksLoadBanksListThunk({}));
    }, [period]);

    const handleChangeStatus = (status: PeriodStatuses) => {
        if (periodId) {
            dispatch(periodsSendStatusThunk({
                periodId,
                periodStatus: status,
            }));
        }
    };

    const handleGenerateDocuments = () => {
        if (period) {
            const {
                periodId,
                decade,
                month,
                year,
                excelFileId,
            } = period;
            
            if (bankData) {
                dispatch(ReportsGenerateReportsDocumentsThunk({
                    periodId,
                    decade,
                    month,
                    year,
                    bankData,
                    excelFileId,
                    onSuccess: ({
                        periodId,
                        generatedReportsData,
                        errorReportsData,
                    }) => {
                        dispatch(reportsUpdateReportsThunk({
                            periodId,
                            generatedReportsData,
                            errorReportsData,
                            onSuccess: () => {
                                dispatch(periodsLoadCurrentPeriodThunk({ periodId })); // TODO необходипо переделать чтобы готовые данные приходили с сервера, а не загружать их заново
                            },
                        }));
                    }
                }));
            }
        }
    };

    const onModalClose = () => {
        setInn('');
        setIsModalOpened(false);
    };

    const handleAddAgent = (inn: string) => {
        setInn(inn);
        setIsModalOpened(true);
    };

    const handlePaginatorClick = (page: number, pageIndex: number) => {
        setPage(page);
        setPageIndex(pageIndex);
        
        dispatch(reportsLoadReportsListThunk({
            periodId,
            organisationName: filter?.organisationName,
            status: filter?.status,
            currentNotification: filter?.currentNotification,
            page,
            sortBy: tableSort ? JSON.stringify(tableSort): '',
            limit: SCALE,
        }));
    };

    const handleTableSort = (tableSort: TableSort) => {
        setTableSort(tableSort);
        
        dispatch(reportsLoadReportsListThunk({
            periodId,
            organisationName: filter?.organisationName,
            status: filter?.status,
            currentNotification: filter?.currentNotification,
            page,
            sortBy: JSON.stringify(tableSort),
            limit: SCALE,
        }));
    };

    const handleSearchReportsFormSubmit = ({
        status,
        organisationName,
        currentNotification,
    }: ReportsSearchFormActionProps) => {
        if (status) {
            filter.status = status;
        } else {
            // удаляется, чтобы не запоминалось в стейте в случае передачи пустого поля
            delete filter.status;
        }

        if (currentNotification) {
            filter.currentNotification = currentNotification;
        } else {
            // удаляется, чтобы не запоминалось в стейте в случае передачи пустого поля
            delete filter.currentNotification;
        }

        if (organisationName) {
            filter.organisationName = organisationName;
        } else {
            // удаляется, чтобы не запоминалось в стейте в случае передачи пустого поля
            delete filter.organisationName;
        }

        setFilter(filter);
        
        dispatch(reportsLoadReportsListThunk({
            periodId,
            organisationName,
            status,
            currentNotification,
            page: 1, // поиск начинается всегда с первой страницы
            sortBy: tableSort ? JSON.stringify(tableSort): '',
            limit: SCALE,
            onSuccess: () => {
                setPage(1);
                setPageIndex(1);
            },
        }));
    };

    const handleFormSubmit = (props: AgentsFormActionProps) => {
        const {
            login,
            password,
            firstName,
            middleName,
            lastName,
            phone,
            email,
            organisationName,
            headFirstName,
            headMiddleName,
            headLastName,
            headPost,
            inn,
            kpp,
            bank,
            bic,
            paymentAccount,
            correspondentAccount,
        } = props;

        dispatch(agentsCreateAgentThunk({
            login,
            password,
            firstName,
            middleName,
            lastName,
            phone,
            email,
            organisationName,
            headFirstName,
            headMiddleName,
            headLastName,
            headPost,
            inn,
            kpp,
            bank,
            bic,
            paymentAccount,
            correspondentAccount,
            onSuccess: () => {
                if (period && bankData) {
                    onModalClose();
                    dispatch(ReportsGenerateReportsDocumentsThunk({
                        periodId: period.periodId,
                        decade: period.decade,
                        month: period.month,
                        year: period.year,
                        bankData,
                        excelFileId: period.excelFileId,
                        agentInn: inn,
                        onSuccess: ({
                            periodId,
                            generatedReportsData,
                            errorReportsData,
                        }) => {
                            dispatch(reportsCreateReportsThunk({
                                periodId,
                                generatedReportsData,
                                errorReportsData,
                                onSuccess: () => {
                                    dispatch(periodsLoadCurrentPeriodThunk({ periodId })); // TODO необходипо переделать чтобы готовые данные приходили с сервера, а не загружать их заново
                                },
                            }));
                        }
                    }))
                }
            }
        }));
    };

    return (
        <div>
            {!!period && (
                <div>
                    <div className={styles.block}>
                        <h2>Ручное управление</h2>
                        <div className={styles.controls}>
                            {/* TODO нужно реализовать процесс перехода статуса периодов после того как всё проверили, пока в ручную */}
                            {period.status !== PeriodStatuses.CREATED && (
                                <button
                                    className={styles.button}
                                    onClick={() => handleChangeStatus(PeriodStatuses.CREATED)}
                                >Новый</button>
                            )}
                            {period.status !== PeriodStatuses.DECLINE && (
                                <button
                                    className={styles.button}
                                    onClick={() => handleChangeStatus(PeriodStatuses.DECLINE)}
                                >Отменён</button>
                            )}
                            {period.status !== PeriodStatuses.SUCCESS && (
                                <button
                                    className={styles.button}
                                    onClick={() => handleChangeStatus(PeriodStatuses.SUCCESS)}
                                >Завершён</button>
                            )}
                        </div>
                    </div>
                    {!isPeriodsLoading && (
                        <div className={styles.content}>
                            <div className={styles.block}>
                                <h3>Отчетный период № <b>{periodId}</b></h3>
                                <div className={styles.blockList}>
                                    <div>Банк: <b>{period.bankName}</b></div>
                                    <div>Организация: <b>{period.organisationName}</b></div>
                                    <div>Месяц: <b>{translateMonth(period.month)}</b></div>
                                    <div>Год: <b>{period.year}</b></div>
                                    <div>Продукт: <b>{period.product}</b></div>
                                    <div>Файл эксель: <b><a href={`/api${docInfo?.fileLink}`}>Скачать</a></b></div>
                                    <div>Статус: <b>{translatePeriodStatuses(period.status)}</b></div>
                                    <div>Создан: <b>{new Date(period?.createdTimestamp || 0).toLocaleString('sv')}</b></div>
                                    <div>Обновлен: <b>{new Date(period?.updatedTimestamp || 0).toLocaleString('sv')}</b></div>
                                </div>
                                {/* {period?.bankId}  TODO нужно ещё делать запрос в диспатч инфы банка */}
                            </div>
                            <h2>Акты</h2>
                            <div className={styles.block}>
                                <ReportsSearchForm
                                    onSubmit={handleSearchReportsFormSubmit}
                                />
                            </div>
                            <div className={styles.block}>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <h3>Агенские акты (успешно сгенерированные)</h3>
                                </div>
                                <div className={styles.controls}>
                                    <button
                                        className={styles.button}
                                        onClick={() => {
                                            dispatch(reportsFilesStatusUpdateThunk({
                                                periodId: period.periodId,
                                                status: FileStatuses.ACCEPTED,
                                            }));
                                        }}  
                                    >Подтвердить документы контрагента</button>
                                    <button
                                        className={styles.button}
                                        onClick={() => {
                                            dispatch(reportsFilesStatusUpdateThunk({
                                                periodId: period.periodId,
                                                status: FileStatuses.HIDDEN,
                                            }));
                                        }} 
                                    >Скрыть документы контрагента</button>
                                    <button
                                        className={styles.button}
                                        onClick={handleGenerateDocuments}
                                    >Перегенерировать документы</button>
                                </div>
                                {/* TODO нужно вынести в отдельный компонент */}
                                <div>
                                    {reportsCount > SCALE && (
                                        <Paginator
                                            parentPage={page}
                                            parentPageIndex={pageIndex}
                                            buttonsCount={5}
                                            items={reportsCount}
                                            scale={SCALE}
                                            onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                                        />
                                    )}
                                    {!!reports && !isReportsLoading && (
                                        <table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th
                                                        className={styles.tableSort}
                                                        onClick={() => {
                                                            handleTableSort({
                                                                field: 'currentNotification',
                                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                                            });
                                                        }}
                                                    >№</th>
                                                    <th
                                                        className={styles.tableSort}
                                                        onClick={() => {
                                                            handleTableSort({
                                                                field: 'agent.organisationName',
                                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                                            });
                                                        }}
                                                    >Агент {tableSort?.sort && tableSort?.field === 'agent.organisationName' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                                    <th
                                                        className={styles.tableSort}
                                                        onClick={() => {
                                                            handleTableSort({
                                                                field: 'status',
                                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                                            });
                                                        }}
                                                    >Cтатус {tableSort?.sort && tableSort?.field === 'status' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                                    <th
                                                        className={styles.tableSort}
                                                        onClick={() => {
                                                            handleTableSort({
                                                                field: 'createdTimestamp',
                                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                                            });
                                                        }}
                                                    >Создан {tableSort?.sort && tableSort?.field === 'createdTimestamp' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                                    <th
                                                        className={styles.tableSort}
                                                        onClick={() => {
                                                            handleTableSort({
                                                                field: 'updatedTimestamp',
                                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                                            });
                                                        }}
                                                    >Обновлен {tableSort?.sort && tableSort?.field === 'updatedTimestamp' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reports.map((report, index) => (
                                                    <tr className={styles.tr} key={index}>
                                                        <td>
                                                            {report.currentNotification === UserRoles.MANAGER && (
                                                                <b className={styles.notification}>!</b>
                                                            )}
                                                        </td>
                                                        <td>{index + 1}</td>
                                                        <td><b>
                                                            <a href={`/manager/agents/${report.agentId}`}>
                                                                {report.agent && report.agent.organisationName}
                                                            </a>
                                                        </b></td>
                                                        <td>{translateReportStatuses(report.status)}</td>
                                                        <td>{new Date(report?.createdTimestamp || 0).toLocaleString('sv')}</td>
                                                        <td>{new Date(report?.updatedTimestamp || 0).toLocaleString('sv')}</td>
                                                        <td><NavLink to={`/manager/reports/${report.reportId}`}>Подробнее</NavLink></td>
                                                    </tr>
                                                ))}
                                            </tbody>    
                                        </table>
                                    )}
                                    {!reports.length && (
                                        <h3>Не найдены!</h3>
                                    )}
                                    {reportsCount > SCALE && (
                                        <Paginator
                                            parentPage={page}
                                            parentPageIndex={pageIndex}
                                            buttonsCount={5}
                                            items={reportsCount}
                                            scale={SCALE}
                                            onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                                        />
                                    )}
                                </div>
                                {isReportsLoading || isAgentsLoading && (
                                    // TODO сделать лоадер
                                    <h3>ЗАГРУЗКА...</h3>
                                )}
                                {/* <ReportsListPage /> */}
                            </div>
                            {/* TODO вынести в отдельный компонент */}
                            <div className={styles.block}>
                                <h3>Агенские акты (сгенерированы с ошибкой)</h3>
                                <div className={styles.blockList}>
                                    {!!period.logs && period.logs.errors.map((error, index) => (
                                        <div key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>ИНН: <b>{error.agentInn}</b> не найден в системе </div>
                                            <button
                                                className={styles.button}
                                                onClick={() => handleAddAgent(error.agentInn)}
                                            >Создать</button>
                                        </div>
                                    ))}
                                </div>
                                <Modal
                                    isOpened={isModalOpened}
                                    onClose={onModalClose}
                                >
                                    <AgentsForm
                                        inn={inn}
                                        isNewAgent={true}
                                        onSubmit={handleFormSubmit}
                                    />
                                </Modal>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {isPeriodsLoading && (
                // TODO лоадер
                <h2>Загрузка...</h2>
            )}
        </div>
    );
};

export default PeriodInfoPage;