import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { AgentsFormActionProps } from '../../../../store/agents/agentsSlice';
import { UserRoles } from '../../../../store/users/usersSlice';
import styles from './AgentsForm.module.scss';
import { useAppSelector } from '../../../../app/hooks';
import { RequestErrorTypes, selectorError } from '../../../../store/errors/errorsSlice';
import { translateError } from '../../../../helpers/helpers';

interface AgentsFormProps {
    login?: string;
    password?: string;
    roles?: UserRoles[];
    firstName?: string;
    middleName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    organisationName?: string;
    headFirstName?: string;
    headMiddleName?: string;
    headLastName?: string;
    headPost?: string;
    inn?: string;
    kpp?: string;
    bank?: string;
    bic?: string;
    paymentAccount?: string;
    correspondentAccount?: string;
    isNewAgent: boolean;
    onSubmit: (props: AgentsFormActionProps) => void;
};

const AgentsForm = ({
    login = '',
    password = '',
    roles = [],
    firstName = '',
    middleName = '',
    lastName = '',
    phone = '',
    email = '',
    organisationName = '',
    headFirstName = '',
    headMiddleName = '',
    headLastName = '',
    headPost = '',
    inn = '',
    kpp = '',
    bank = '',
    bic = '', 
    paymentAccount = '',
    correspondentAccount = '',
    isNewAgent,
    onSubmit,
}: AgentsFormProps) => {
    const isErrorPassword = useAppSelector(selectorError(RequestErrorTypes.PASSWORD_FORMAT_ERROR));
    const [isValidForm, setIsValidForm] = useState<boolean>(false);

    const initialValues: AgentsFormActionProps = {
        login,
        password,
        // roles,
        firstName,
        middleName,
        lastName,
        phone,
        email,
        organisationName,
        headFirstName,
        headMiddleName,
        headLastName,
        headPost,
        inn,
        kpp,
        bank,
        bic, 
        paymentAccount,
        correspondentAccount,
    };

    const formik = useFormik({
        initialValues,
        onSubmit: ({
            login,
            password,
            // roles, // TODO надо убрать, роль будет браться из локалтсраджа
            firstName,
            middleName,
            lastName,
            phone,
            email,
            organisationName,
            headFirstName,
            headMiddleName,
            headLastName,
            headPost,
            inn,
            kpp,
            bank,
            bic, 
            paymentAccount,
            correspondentAccount,
        }) => {
            if (isValidForm) {
                onSubmit({
                    login,
                    password,
                    firstName,
                    middleName,
                    lastName,
                    phone,
                    email,
                    organisationName,
                    headFirstName,
                    headMiddleName,
                    headLastName,
                    headPost,
                    inn,
                    kpp,
                    bank,
                    bic,
                    paymentAccount,
                    correspondentAccount,
                })
            }
        },
    });

    useEffect(() => {
        if (
            !!formik.values.login
            && ((isNewAgent && !!formik.values.password) || !isNewAgent)
            // && !!formik.values.role
            && !!formik.values.firstName
            && !!formik.values.middleName
            && !!formik.values.lastName
            && !!formik.values.phone
            && !!formik.values.email
            && !!formik.values.organisationName
            && !!formik.values.headFirstName
            && !!formik.values.headMiddleName
            && !!formik.values.headLastName
            && !!formik.values.headPost
            && !!formik.values.inn
            && !!formik.values.bank
            && !!formik.values.bic
            && !!formik.values.paymentAccount
            && !!formik.values.correspondentAccount
        ) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    }, [formik.values]);

    return(
        <div>
            <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
            >
                <div className={styles.formWrapper}>
                    <div className={styles.formBlock}>
                        <h3>Пользовательские данные</h3>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Логин<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Логин"
                                name="login"
                                value={formik.values.login}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        {/* TODO в дальнейшем для менеджера не будет инпута, а будет сгенерированный пароль */}
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Пароль<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Пароль"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                required={isNewAgent}
                                className={styles.input}
                            />
                            {!!isErrorPassword && (
                                <div className={styles.error}>{translateError(isErrorPassword.type)}</div>
                            )}
                        </div>
                        <div className={styles.formTitle}>
                            <b>ФИО</b>
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Фамилия<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Фамилия"
                                name="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Имя<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Имя"
                                name="firstName"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Отчество<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Отчество"
                                name="middleName"
                                value={formik.values.middleName}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formTitle}>
                            <b>Контактные данные</b>
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Телефон<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Телефон"
                                name="phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Email<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <div className={styles.formBlock}>
                        <h3>Агентские данные</h3>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Название организации<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Название организации"
                                name="organisationName"
                                value={formik.values.organisationName}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Фамилия руководителя<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Фамилия руководителя"
                                name="headLastName"
                                value={formik.values.headLastName}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Имя руководителя<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Имя руководителя"
                                name="headFirstName"
                                value={formik.values.headFirstName}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Отчество руководителя<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Отчество руководителя"
                                name="headMiddleName"
                                value={formik.values.headMiddleName}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Должнсть руководителя<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Должность руководителя"
                                name="headPost"
                                value={formik.values.headPost}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>ИНН организации<span>*</span></div>
                            <input
                                type="text"
                                placeholder="ИНН организации"
                                name="inn"
                                value={formik.values.inn}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>КПП организации</div>
                            <input
                                type="text"
                                placeholder="КПП организации"
                                name="kpp"
                                value={formik.values.kpp}
                                onChange={formik.handleChange}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formTitle}>
                            <b>Реквизиты</b>
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Банк<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Банк"
                                name="bank"
                                value={formik.values.bank}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>БИК<span>*</span></div>
                            <input
                                type="text"
                                placeholder="БИК"
                                name="bic"
                                value={formik.values.bic}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Расчетный счет<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Расчетный счет"
                                name="paymentAccount"
                                value={formik.values.paymentAccount}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Корреспондентский счет<span>*</span></div>
                            <input
                                type="text"
                                placeholder="Корреспондентский счет"
                                name="correspondentAccount"
                                value={formik.values.correspondentAccount}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.controls}>
                    <button
                        disabled={!isValidForm}
                        type="submit"
                        className={styles.button}
                    >Готово</button>
                </div>
            </form>
        </div>
    )
};

export default AgentsForm;