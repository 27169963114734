import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectorCurrentUser, selectorUsersIsLoading, UserRoles, usersDeleteUserThunk, UsersFormActionProps, usersLoadCurrentUserThunk, usersUpdateUserThunk } from '../../../../store/users/usersSlice';
import UsersForm from '../../components/form/UsersForm';
import styles from './UserInfoPage.module.scss';

const UserInfoPage = () => {
    const dispatch = useAppDispatch();

    const params = useParams();
    const userId = params.userId;

    const navigate = useNavigate();

    const user = useAppSelector(selectorCurrentUser);
    const usersIsLoading = useAppSelector(selectorUsersIsLoading);

    const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(false);

    useEffect(() => {
        dispatch(usersLoadCurrentUserThunk({ userId: userId || '' }));
    }, []);

    const handleFormSubmit = (props: UsersFormActionProps) => {
        const {
            login,
            password,
            firstName,
            middleName,
            lastName,
            phone,
            email,
            roles,
            isBlocked,
        } = props;

        if (userId) {
            dispatch(usersUpdateUserThunk({
                userId,
                login,
                password,
                firstName,
                middleName,
                lastName,
                phone,
                email,
                roles,
                isBlocked,
                onSuccess: () => {
    
                },
            }));
        }
    };

    const handleDelete = () => {
        if (window.confirm('Точно хотите удалить?')) {
            if (!!userId) {
                dispatch(usersDeleteUserThunk({
                    userId,
                    onSuccess: () => {
                        navigate('/admin/users');
                    },
                    onError: () => {
                        // TODO
                    }
                }));
            }
        }

        return;
    };

    return (
        <>
            {user && !usersIsLoading && (
                <div>
                    <div className={styles.block}>
                        <div className={styles.controls}>
                            {!isFormDisplayed && (
                                <>
                                    <button
                                        className={styles.button}
                                        onClick={() => setIsFormDisplayed(true)}
                                    >Редактировать</button>
                                    <button
                                        onClick={handleDelete}
                                        className={cn(
                                            styles.button,
                                            styles.buttonDeclined,
                                        )}
                                    >
                                        Удалить
                                    </button>
                                </>
                            )}
                        </div>
                        {isFormDisplayed && (
                            <>
                                <div className={styles.control}>
                                    <button
                                        className={styles.button}
                                        onClick={() => setIsFormDisplayed(false)}
                                    >Отмена</button>
                                </div>
                                    <UsersForm
                                        login={user.login}
                                        password={user.hashPassword}
                                        roles={user.roles} // TODO тут в дальнейшем будет смена роли для пользователей агента
                                        firstName={user.userInfo?.firstName}
                                        middleName={user.userInfo?.middleName}
                                        lastName={user.userInfo?.lastName}
                                        phone={user.userInfo?.phone}
                                        email={user.userInfo?.email}
                                        isBlocked={user.isBlocked}
                                        isNewUser={false}
                                        onSubmit={handleFormSubmit}
                                    />
                            </>
                        )}
                    </div>
                    <div className={styles.block}>
                        <div>
                            <h2>Пользовательские данные</h2>
                            <div><b>Логин:</b> {user.login}</div>
                            <div><b>ФИО:</b> {user.userInfo?.lastName} {user.userInfo?.firstName} {user.userInfo?.middleName}</div>
                            <div><b>Телефон:</b> {user.userInfo?.phone}</div>
                            <div><b>Email:</b> {user.userInfo?.email}</div>
                            <div><b>Роль:</b> {user.roles && user.roles.map((role, index) => (
                                <div key={index}>{role}</div>
                            ))}
                            </div>
                            {!!user.agentId && (
                                <div><b>Агент:</b> <NavLink to={`/manager/agents/${user.agentId}`}>Подробнее</NavLink></div>
                            )}
                            {!user.agentId && user.roles && user.roles.includes(UserRoles.AGENT) && (
                                <div><b>Пользователь не прикреплён к агенту</b></div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

export default UserInfoPage;