import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { translateFilesStatuses } from '../../../../helpers/helpers';
import { selectorFilesGetFile } from '../../../../store/files/filesSlice';
import { Doc, FileStatuses, reportsAddDocForAccouningAction, reportsRemoveDocForAccouningAction, reportsSendFileStatusThunk, selectorDocForAccounting } from '../../../../store/reports/reportsSlice';
import styles from './FileItem.module.scss';
import cn from 'classnames';

interface FileItemProps {
    file: Doc;
    reportId: string;
};

const FileItem = ({
    file,
    reportId,
}: FileItemProps) => {
    const dispatch = useAppDispatch();

    const {
        fileId,
        status,
    } = file;

    const docInfo = useAppSelector(selectorFilesGetFile(file.fileId));
    const isDocForAccounting = useAppSelector(selectorDocForAccounting(fileId));
    
    const [fileName, setFileName] = useState<string>('');

    useEffect(() => {        
        let paths: string[] = [];

        if (docInfo) {
            paths = docInfo.fileLink.split('/');
        }

        // TODO данный формат может не подходить если сметется паттерн названия файла, плоэтому в дельнейшем стоит перейти к варианту, когда тип документа хранится в объекте mainDocs/agentDocs
        if (paths.length) {
            const fileNameArr = paths[paths.length - 1].split('_');
            const downloadName = fileNameArr.slice(0, fileNameArr.length - 1);
            const fileType = fileNameArr[fileNameArr.length - 1].split('.').pop();
            
            setFileName(`${downloadName.join('_')}.${fileType}`);
        }
    }, [docInfo]);

    const handleCheckboxChange = (checked: boolean) => {
        if (!!checked) {
            dispatch(reportsAddDocForAccouningAction(file));
        }
    
        if (!checked) {
            dispatch(reportsRemoveDocForAccouningAction(file));
        }
    };

    return (
        <>
            {docInfo && (
                <div className={styles.document}>
                    <div className={styles.documentBody}>
                        <div><a href={`/api${docInfo.fileLink}`} download={fileName}>{fileName}</a>: <b>{translateFilesStatuses(status)}</b></div>
                        {status === FileStatuses.ACCEPTED && (
                            <label>
                                <input
                                    type="checkbox"
                                    checked={!!isDocForAccounting}
                                    onChange={(e) => handleCheckboxChange(e.target.checked)}
                                />
                                <b>Для бухгалтера</b>
                            </label>
                        )}
                    </div>
                    <div className={styles.controls}>
                        {status !== FileStatuses.HIDDEN && (
                            <button
                                className={cn(styles.button)}
                                onClick={() => {
                                    if (!!reportId) {
                                        dispatch(reportsSendFileStatusThunk({
                                            reportId,
                                            fileIds: [fileId],
                                            docStatus: FileStatuses.HIDDEN,
                                        }));
                                    }
                                    dispatch(reportsRemoveDocForAccouningAction(file));
                                }}
                            >Скрыть</button>
                        )}
                        {status !== FileStatuses.ACCEPTED && (
                            <button
                                className={cn(styles.button)}
                                onClick={() => {
                                    if (!!reportId) {
                                        dispatch(reportsSendFileStatusThunk({
                                            reportId,
                                            fileIds: [fileId],
                                            docStatus: FileStatuses.ACCEPTED,
                                        }));
                                    }
                                }}
                            >Подтвердить</button>
                        )}
                        {status !== FileStatuses.LOADED && (
                            <button
                                className={cn(styles.button)}
                                onClick={() => {
                                    if (!!reportId) {
                                        dispatch(reportsSendFileStatusThunk({
                                            reportId,
                                            fileIds: [fileId],
                                            docStatus: FileStatuses.LOADED,
                                        }));
                                    }
                                    dispatch(reportsRemoveDocForAccouningAction(file));
                                }}
                            >Черновик</button>
                        )}
                    </div>
                </div>
            )}
        </>
    )
};

export default FileItem;