import React from 'react';
import { NavLink } from 'react-router-dom';
import { User } from '../../../../../store/users/usersSlice';
import styles from './UsersListItem.module.scss';

interface UsersListItemProps {
    user: User;
    index: number;
};

const UsersListItem = ({
    user,
    index,
}: UsersListItemProps) => {
    const {
        login,
        roles,
        userInfo,
        createdTimestamp,
        userId,
    } = user;

    return (
        <tr className={styles.tr}>
            <td>{index + 1}</td>
            <td><b>{login}</b></td>
            <td>{userInfo?.email}</td>
            <td>{userInfo?.lastName} {userInfo?.firstName} {userInfo?.middleName}</td>
            <td>{createdTimestamp && new Date(createdTimestamp).toLocaleString('sv')}</td>
            <td>
                {roles && roles.map((role, index) => (
                    <b key={index}>{role} </b>
                ))}
            </td>
            {/* <td><NavLink to={`/manager/agents/${agentId}`}>Агент</NavLink></td> */}
            <td><NavLink to={`/admin/users/${userId}`}>Подробнее</NavLink></td>
        </tr>
    )
};

export default UsersListItem;