import alertRedSrc from './images/alertRed.svg';

export enum IconsType {
    alertRed = 'alertRed',
}

type IIcons = {
    [type in IconsType]: any;
};

const icons: IIcons = {
    [IconsType.alertRed]: alertRedSrc,
}

export interface IconsProps {
    type: IconsType;
}

const Icons = ({
    type,
}: IconsProps) => {
    const iconSrc = icons[type];

    if (iconSrc) {
        return (
            <img src={iconSrc} alt="" />
        )
    }

    return null;
}

export default Icons;