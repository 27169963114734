import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk, RootState } from "../../app/store";
import { ERRORS_LIMIT, RECONNECT_TIMEOUT } from "../../constants/constants";
import { authExecAfterAuthThunk, authLogoutAction } from "../auth/authSlice";
import { errorsDeleteActiveRequestErrorsAction, errorsSetActiveErrorAction, RequestErrorTypes, RequestFunctionNames } from "../errors/errorsSlice";
import { DocTypes } from "../files/filesSlice";

export const BANKS_SCALE = 20; // TODO в дальнейшем это может настраиваться через стор

export interface Bank {
	bankId: string; // уникальный ID банка
	name: string; // наименование банка юридическое название для отчетов
	aliasName: string; // внутреннее имя для нас
	correspondentAccount: string; // корр. счет
	bic: string; // БИК банка
	inn: string; // ИНН банка
	kpp: string; // КПП банка
	siteAddres: string; // адрес в интернете
	instance: string; // инстанция ( уточнить что это)
	organisation: { // Наша организация работающая с банком
		name: string; // организация
		inn: string; // инн организации
		kpp: string; // КПП организации
		head: { // тот кто работет с банком
			firstName: string; // имя руководителя 
			lastName: string; // фамилия руководителя
			middleName: string; // отчество руководителя
			post: string;
		}
	};
	templates?: {
		reports: string;
		bills: string;
	},
};

export interface BanksState {
    isLoading: boolean;
	isFileLoading: boolean;
	isCurrentBankLoading: boolean;
	currentBank: Bank | null;
    banksList: Bank[];
	banksCount: number;
};

const initialState: BanksState = {
    isLoading: false,
	isFileLoading: false,
	isCurrentBankLoading: false,
	currentBank: null,
    banksList: [],
	banksCount: 0,
};

export const banksSlice = createSlice({
    name: 'banks',
    initialState,
    reducers: {
        banksSetIsLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;  
        },
        banksSetIsCurrentBankLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isCurrentBankLoading = action.payload;  
        },
		banksSetCurrentBankAction : (state, action: PayloadAction<Bank | null>) => {
			state.currentBank = action.payload;
		},
        banksSetBanksListAction: (state, action: PayloadAction<Bank[]>) => {
            state.banksList = action.payload;
        },
		banksSetIsFileLoading: (state, action: PayloadAction<boolean>) => {
			state.isFileLoading = action.payload;
		},
		banksSetBankAction: (state, action: PayloadAction<Bank>) => {
			state.banksList = state.banksList.map((bank) => {
				if (bank.bankId === action.payload.bankId) {
                    return action.payload;
                }

                return bank;
			})
		},
		banksSetBanksCountAction: (state, action: PayloadAction<number>) => {
            state.banksCount = action.payload;
        },
    },
	extraReducers: (builder) => {
        // очищение стора при logoutAction
        builder.addCase(authLogoutAction, () => {
            return initialState;
        });
    },
});

export const {
    banksSetIsLoadingAction,
    banksSetBanksListAction,
	banksSetIsFileLoading,
	banksSetIsCurrentBankLoadingAction,
	banksSetBankAction,
	banksSetCurrentBankAction,
	banksSetBanksCountAction,
} = banksSlice.actions;

export const selectorBanksIsLoading = (state: RootState) => state.banks.isLoading;
export const selectorCurrentBank = (state: RootState) => state.banks.currentBank;
export const selectorBanksIsCurrentBankLoading = (state: RootState) => state.banks.isCurrentBankLoading;
export const selectorBanksIsFileLoading = (state: RootState) => state.banks.isFileLoading;
export const selectorBanksList = (state: RootState) => state.banks.banksList;
export const selectorBank = (bankId: string) => {
	return (state: RootState) => state.banks.banksList.find((bank) => bank.bankId === bankId);
};
export const selectorBanksCount = (state: RootState) => state.banks.banksCount;

interface BanksManagerLoadCurrentBankThunkProps {
	bankId: string;
	errorCounter?: number;
};

export const banksManagerLoadCurrentBankThunk = (props: BanksManagerLoadCurrentBankThunkProps): AppThunk => (dispatch, getState) => {
	const isLoading = selectorBanksIsCurrentBankLoading(getState());
	
	if (isLoading) {
		return;
	}

	const {
		bankId,
		errorCounter = 0,
	} = props;

	const request = RequestFunctionNames.BANKS__MANAGER_LOAD_CURRENT_BANK;

	dispatch(banksSetIsCurrentBankLoadingAction(true));

	axios.get(`/api/manager/banks/${bankId}`)
		.then(({ data }) => {
			if (data.status === 200) {
				dispatch(banksSetCurrentBankAction(data.body.bank));
				dispatch(errorsDeleteActiveRequestErrorsAction(request));
			}
		})
		.catch((error) => {
			dispatch(authExecAfterAuthThunk({
				props,
				error,
				thunk: banksManagerLoadCurrentBankThunk,
				onError: () => {
					const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(banksManagerLoadCurrentBankThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch((banksSetCurrentBankAction(null)))
					}
				},
			}))
		})
		.finally(() => {
			dispatch(banksSetIsCurrentBankLoadingAction(false));
		});
};

export interface BanksSearchFormActionProps {
    name?: string;
    bic?: string;
    inn?: string;
};

interface BanksLoadBanksListThunkProps extends BanksSearchFormActionProps {
    sortBy?: string;
    limit?: number;
    page?: number;
	errorCounter?: number;
    onSuccess?: () => void;
    onError?: (error: string) => void;
};

export const banksLoadBanksListThunk = (props: BanksLoadBanksListThunkProps): AppThunk => (dispatch, getState) => {
	const isLoading = selectorBanksIsLoading(getState());
	
	if (isLoading) {
		return;
	}
	
	const {
        name,
        bic,
        inn,
        sortBy,
        limit = 20,
        page = 1,
		errorCounter = 0,
        onSuccess,
        onError,
    } = props;

	const urlParams = [];

    if (name) {
        urlParams.push(`name=${name}`);
    }

    if (bic) {
        urlParams.push(`bic=${bic}`);
    }

    if (inn) {
        urlParams.push(`inn=${inn}`);
    }

    if (sortBy) {
        urlParams.push(`sortBy=${sortBy}`);
    }

	urlParams.push(`from=${(page - 1) * limit}&limit=${limit}`);

	const request = RequestFunctionNames.BANKS__LOAD_BANKS_LIST;
    const url = `/api/manager/banks?${urlParams.join('&')}`;

	dispatch(banksSetIsLoadingAction(true));

	axios.get(url)
		.then(({ data }) => {
			if (data.status === 200) {
				dispatch(banksSetBanksListAction(data.body.banks));
                dispatch(banksSetBanksCountAction(data.body.length));
				dispatch(errorsDeleteActiveRequestErrorsAction(request));

				if (onSuccess) {
					onSuccess();
				}
			}
		})
		.catch((error) => {
			dispatch(authExecAfterAuthThunk({
				props,
				error,
				thunk: banksLoadBanksListThunk,
				onError: () => {
					const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(banksLoadBanksListThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch(banksSetBanksListAction([]));
					}

					if (onError) {
						onError(error);
					}
				},
			}));
		})
		.finally(() => {
			dispatch(banksSetIsLoadingAction(false));
		})
};

export interface BanksFormActionProps {
	name: string;
	aliasName: string;
	correspondentAccount: string;
	bic: string;
	inn: string;
	kpp: string;
	siteAddres: string;
	instance: string;
	organisationName: string;
	organisationInn: string;
	organisationKpp: string;
	organisationHeadFirstName: string;
	organisationHeadMiddleName: string;
	organisationHeadLastName: string;
	organisationHeadPost: string;
	templateReportFileString: string;
	templateBillFileString: string;
};

interface BanksCreateBankThunkProps extends BanksFormActionProps {
	errorCounter?: number;
	onSuccess?: (bankId?: string) => void;
	onError?: (error: any) => void;
};

export const banksCreateBankThunk = (props: BanksCreateBankThunkProps): AppThunk => (dispatch, getState) => {
	const isLoading = selectorBanksIsLoading(getState());

	if (isLoading) {
		return;
	}

	const {
		name,
		aliasName,
		correspondentAccount,
		bic,
		inn,
		kpp,
		siteAddres,
		instance,
		organisationName,
		organisationInn,
		organisationKpp,
		organisationHeadFirstName,
		organisationHeadMiddleName,
		organisationHeadLastName,
		organisationHeadPost,
		templateReportFileString,
		templateBillFileString,
		errorCounter = 0,
		onSuccess,
		onError,
	} = props;

	const request = RequestFunctionNames.BANKS__CREATE_BANK;
	const banksList = selectorBanksList(getState());
	const banksCount = selectorBanksCount(getState());

	dispatch(banksSetIsLoadingAction(true));

	axios.post('/api/manager/banks', {
		name,
		aliasName,
		correspondentAccount,
		bic,
		inn,
		kpp,
		siteAddres,
		instance,
		organisationName,
		organisationInn,
		organisationKpp,
		organisationHeadFirstName,
		organisationHeadMiddleName,
		organisationHeadLastName,
		organisationHeadPost,
		templateReportFileString,
		templateBillFileString,
	})
		.then(({ data }) => {
			if (data.status === 201) {
				let newData = [...banksList];

				if (newData.length < BANKS_SCALE) {
                    newData.push(data.body.newBank);
                } else {
                    newData.unshift(data.body.newBank);
                    newData.pop();
                }

				dispatch(banksSetBanksListAction(newData));
				dispatch(banksSetBanksCountAction(banksCount + 1));
				dispatch(errorsDeleteActiveRequestErrorsAction(request));

				if (onSuccess) {
					onSuccess(data.body.newBank.bankId);
				}

				return;
			}
		})
		.catch((error) => {
			dispatch(authExecAfterAuthThunk({
				props,
				error,
				thunk: banksCreateBankThunk,
				onError: (error) => {
					const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(banksCreateBankThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
                        onError(error); 
					}
				},
			}));
		})
		.finally(() => {
			dispatch(banksSetIsLoadingAction(false));
		});
};

interface BanksUpdateBankThunkProps extends BanksFormActionProps {
	bankId: string;
	errorCounter?: number;
	onSuccess?: () => void;
    onError?: () => void;
};

export const banksUpdateBankThunk = (props: BanksUpdateBankThunkProps): AppThunk => (dispatch, getState) => {
	const isLoading = selectorBanksIsFileLoading(getState());

	if (isLoading) {
		return;
	}

	const {
		bankId,
		name,
		aliasName,
		correspondentAccount,
		bic,
		inn,
		kpp,
		siteAddres,
		instance,
		organisationName,
		organisationInn,
		organisationKpp,
		organisationHeadFirstName,
		organisationHeadMiddleName,
		organisationHeadLastName,
		organisationHeadPost,
		errorCounter = 0,
		onSuccess,
		onError,
	} = props;

	const request = RequestFunctionNames.BANKS__UPDATE_BANK;

	dispatch(banksSetIsLoadingAction(true));

	axios.put(`/api/manager/banks/${bankId}`, {
		name,
		aliasName,
		correspondentAccount,
		bic,
		inn,
		kpp,
		siteAddres,
		instance,
		organisationName,
		organisationInn,
		organisationKpp,
		organisationHeadFirstName,
		organisationHeadMiddleName,
		organisationHeadLastName,
		organisationHeadPost,
	})
		.then(({ data }) => {
			if (data.status === 200) {
				dispatch(banksSetCurrentBankAction(data.body.bank));
				dispatch(errorsDeleteActiveRequestErrorsAction(request));

				if (onSuccess) {
					onSuccess();
				}

				return;
			}
		})
		.catch((error) => {
			dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: banksUpdateBankThunk,
                onError: () => {
					const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(banksUpdateBankThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    if (onError) {
                        onError(); 
                    }
                },
            }));
		})
		.finally(() => {
			dispatch(banksSetIsLoadingAction(false));
		});
};

interface BanksDeleteBankThunkProps {
	bankId: string;
	errorCounter?: number;
	onSuccess: () => void;
	onError: () => void;
};

export const banksDeleteBankThunk = (props: BanksDeleteBankThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorBanksIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
		bankId,
		errorCounter = 0,
		onSuccess,
		onError,
	} = props;

	const request = RequestFunctionNames.BANKS__DELETE_BANK;
	const banksCount = selectorBanksCount(getState());
    const banks = selectorBanksList(getState());

    dispatch(banksSetIsLoadingAction(true));

    axios.delete(`/api/manager/banks/${bankId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(banksSetBanksListAction(banks.filter((bank) => bank.bankId !== bankId)));
				dispatch(banksSetBanksCountAction(banksCount - 1));
				dispatch(errorsDeleteActiveRequestErrorsAction(request));

                onSuccess();
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: banksDeleteBankThunk,
                onError: () => {
					const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(banksDeleteBankThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    onError(); 
                },
            }));
        })
        .finally(() => {
            dispatch(banksSetIsLoadingAction(false));
        });
};

interface BanksUploadDocTemplateFileProps {
	bankId: string;
	fileString: string;
	docType: DocTypes;
	errorCounter?: number;
	onSuccess: (docFileId: string) => void;
    onError?: () => {}; 
};

export const banksUploadDocTemplateFile = (props: BanksUploadDocTemplateFileProps): AppThunk => (dispatch, getState) => {
	const isLoading = selectorBanksIsFileLoading(getState());

	if (isLoading) {
		return;
	}

	const {
		bankId,
		fileString,
		docType,
		errorCounter = 0,
		onSuccess,
		onError,
	} = props;

	const request = RequestFunctionNames.BANKS__UPLOAD_DOC_TEMPLATE_FILE;

	dispatch(banksSetIsFileLoading(true));

	axios.post(`/api/manager/banks/${bankId}/fileUpload`, {
		fileString,
		docType,
	})
		.then(({ data }) => {
			if (data.status === 201) {
				dispatch(banksSetIsFileLoading(false));
				dispatch(errorsDeleteActiveRequestErrorsAction(request));

				onSuccess(data.body.docFileId);
			}
		})
		.catch((error) => {
			dispatch(authExecAfterAuthThunk({
				error,
				thunk: banksUploadDocTemplateFile,
				props,
				onError: () => {
					const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(banksUploadDocTemplateFile({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
						onError();
					}
				}
			}));

            dispatch(banksSetIsFileLoading(false));

            return;
        });
};

interface BanksUpdateDocTemplateFileProps {
	bankId: string;
	docFileId: string;
	docType: DocTypes;
	errorCounter?: number;
	onSuccess? : () => void;
	onError? : () => void;
};

export const banksUpdateDocTemplateFile = (props: BanksUpdateDocTemplateFileProps): AppThunk => (dispatch, getState) => {
	const {
		bankId,
		docFileId,
		docType,
		errorCounter = 0,
		onSuccess,
		onError,
	} = props;

	const request = RequestFunctionNames.BANKS__UPDATE_DOC_TEMPLATE_FILE;

	axios.put(`/api/manager/banks/${bankId}/fileUpload`, {
		docFileId,
		docType,
	})
		.then(({ data }) => {
			if (data.status === 200) {
				dispatch(banksSetCurrentBankAction(data.body.bank));
				dispatch(errorsDeleteActiveRequestErrorsAction(request));

				if (onSuccess) {
					onSuccess();
				}
			}
		})
		.catch((error) => {
			dispatch(authExecAfterAuthThunk({
				props,
				error,
				thunk: banksUpdateDocTemplateFile,
				onError: () => {
					const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(banksUpdateDocTemplateFile({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
						onError();
					}
				},
			}));

			return;
		});
}

export default banksSlice.reducer;