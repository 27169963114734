import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from '../store/auth/authSlice';
import periodsReducer from '../store/periods/periodsSlice';
import usersReducer from '../store/users/usersSlice';
import banksReducer from '../store/banks/banksSlice';
import reportsReducer from '../store/reports/reportsSlice';
import filesReducer from '../store/files/filesSlice';
import agentsReducer from '../store/agents/agentsSlice';
import errorsReducer from '../store/errors/errorsSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        periods: periodsReducer,
        banks: banksReducer,
        reports: reportsReducer,
        files: filesReducer,
        agents: agentsReducer,
        errors: errorsReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;