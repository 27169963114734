import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { ReportsSearchFormActionProps, ReportStatuses } from '../../../../store/reports/reportsSlice';
import { translateReportStatuses } from '../../../../helpers/helpers';
import { UserRoles } from '../../../../store/users/usersSlice';
import styles from './ReportsSearchForm.module.scss';

interface ReportsSearchFormProps {
    onSubmit: (formData: ReportsSearchFormActionProps) => void;
};

const ReportsSearchForm = ({
    onSubmit,
}: ReportsSearchFormProps) => {
    const [isAgentNotification, setIsAgentNotification] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            organisationName: '',
            status: undefined,
            currentNotification: undefined,
        },
        onSubmit: (props: ReportsSearchFormActionProps) => {
            onSubmit(props);
        },
    });

    useEffect(() => {
        if (isAgentNotification) {
            formik.setFieldValue('currentNotification', UserRoles.MANAGER);

            // очищается для отображения всех актуальных актов, а не только с каким-то статусом
            formik.setFieldValue('status', undefined);
        }

        if (!isAgentNotification) {
            formik.setFieldValue('currentNotification', undefined);
        }
    }, [isAgentNotification]);

    const handleResetForm = (e: any) => {
        formik.handleReset(e);
        setIsAgentNotification(false);
        onSubmit({});
    };

    return (
        <div>
            <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
            >
                <div className={styles.controls}>
                    <input   
                        type="text"
                        name="organisationName"
                        value={formik.values.organisationName}
                        onChange={formik.handleChange}
                        placeholder="Название организации"
                        className={styles.input}
                    />
                    <select
                        name="status"
                        value={formik.values.status || ''}
                        onChange={formik.handleChange}
                        className={styles.select}
                    >
                        <option value="" >Все</option>
                        <option value={[ReportStatuses.ACCEPTED]}>{translateReportStatuses(ReportStatuses.ACCEPTED)}</option>
                        <option value={[ReportStatuses.CANCELED]}>{translateReportStatuses(ReportStatuses.CANCELED)}</option>
                        <option value={[ReportStatuses.NEW]}>{translateReportStatuses(ReportStatuses.NEW)}</option>
                        <option value={[ReportStatuses.ON_REVISION]}>{translateReportStatuses(ReportStatuses.ON_REVISION)}</option>
                        <option value={[ReportStatuses.ON_VERIFICATION]}>{translateReportStatuses(ReportStatuses.ON_VERIFICATION)}</option>
                    </select>
                </div>
                <div className={styles.controls}>
                    <button
                        className={styles.button}
                        type="submit"
                    >
                        Найти
                    </button>
                    <button
                        className={styles.button}
                        type="button"
                        onClick={handleResetForm}
                    >
                        Сбросить фильтр
                    </button>
                </div>
            </form>

            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={isAgentNotification}
                        onChange={(e) => setIsAgentNotification(e.target.checked)}
                    />
                    Показать актуальные
                </label>
            </div>
        </div>
    );
};

export default ReportsSearchForm;
