import React, { useEffect } from "react";
import { Outlet } from "react-router";
import { useAppDispatch } from "../../app/hooks";
import { agentsLoadAgentsListThunk } from "../../store/agents/agentsSlice";
import styles from './Agents.module.scss';

const AgentsPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(agentsLoadAgentsListThunk({
            limit: 20,
        }));
    }, []);

    return (
        <div className={styles.wrapper}>
            <h1>Список агентов</h1>
            <div>
                <Outlet />
            </div>
        </div>
    );
};

export default AgentsPage;