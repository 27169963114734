import React from 'react';
import { NavLink } from 'react-router-dom';
import { translateMonth, translatePeriodStatuses } from '../../../../../helpers/helpers';
import { Period } from '../../../../../store/periods/periodsSlice';
import styles from './PeriodsListItem.module.scss';

interface PeriodsListItemProps {
    period: Period;
    index: number;
}
const PeriodsListItem = ({
    period,
    index,
}: PeriodsListItemProps) => {
    const {
        bankId,
        bankName,
        organisationName,
        decade,
        month,
        year,
        status,
        periodId,
        createdTimestamp,
    } = period;

    return(
        <tr className={styles.tr}>
            <td>{index + 1}</td>
            <td><NavLink to={`/manager/banks/${bankId}`}>{bankName}</NavLink></td>
            {/* <td>{decade.first}</td> */}
            <td>{translateMonth(month)}</td>
            <td>{year}</td>
            <td>{new Date(createdTimestamp).toLocaleString('sv')}</td>
            <td><b>{translatePeriodStatuses(status)}</b></td>
            <td>
            </td>
            <td>
                <NavLink to={`/manager/periods/${periodId}`}>Подробнее</NavLink>
            </td>
        </tr>
    );
};

export default PeriodsListItem;