import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FileLoader from '../../../../components/file-loader/FileLoader';
import {
    banksDeleteBankThunk,
    BanksFormActionProps,
    banksManagerLoadCurrentBankThunk,
    banksUpdateBankThunk,
    banksUpdateDocTemplateFile,
    banksUploadDocTemplateFile,
    selectorBanksIsCurrentBankLoading,
    selectorCurrentBank,
} from '../../../../store/banks/banksSlice';
import { DocTypes, filesLoadFilesListThunk, selectorFilesGetFile } from '../../../../store/files/filesSlice';
import {
    periodsLoadPeriodsListThunk,
    selectorPeriodsIsLoading,
    selectorPeriodsList,
} from '../../../../store/periods/periodsSlice';
import BanksForm from '../../components/form/BanksForm';
import styles from './BankInfoPage.module.scss';
import { UploadedFile } from '../../../../store/reports/reportsSlice';
import PeriodsListPage from '../../../periods/pages/list/PeriodsListPage';

const BankInfoPage = () => {
    const dispatch = useAppDispatch();

    const params = useParams();
    const bankId = params.bankId || '';

    const navigate = useNavigate();

    const bank = useAppSelector(selectorCurrentBank);
    const periods = useAppSelector(selectorPeriodsList);
    const isBankLoading = useAppSelector(selectorBanksIsCurrentBankLoading);
    const isPeriodsLoading = useAppSelector(selectorPeriodsIsLoading);
    const reportDocInfo = useAppSelector(selectorFilesGetFile(bank?.templates?.reports || ''));
    const billDocInfo = useAppSelector(selectorFilesGetFile(bank?.templates?.bills || ''));

    const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(false);
    const [isAddReportTemplateDisplayed, setIsAddReportTemplateDisplayed] = useState<boolean>(false);
    const [isAddBillTemplateDisplayed, setIsAddBillTemplateDisplayed] = useState<boolean>(false);
    const [reportFile, setReportFile] = useState<string>('');
    const [billFile, setBillFile] = useState<string>('');
    
    useEffect(() => {
        dispatch(banksManagerLoadCurrentBankThunk({ bankId }))
    },[]);

    useEffect(() => {
        let fileIds: string[] = [];

        dispatch(periodsLoadPeriodsListThunk({ bankId }));
        
        if (!!bank) {
            fileIds.push(bank.templates?.reports || '');
            fileIds.push(bank.templates?.bills || '');
        }
        
        dispatch(filesLoadFilesListThunk({ fileIds }));
    }, [bank]);

    const saveDoc = (docType: DocTypes) => {
        let file = '';

        if (docType === DocTypes.REPORT_TEMPLATE) {
            file = reportFile;
        } 
        if (docType === DocTypes.BILL_TEMPLATE) {
            file = billFile;
        }

        if (bankId) {
            dispatch(banksUploadDocTemplateFile({
                bankId,
                fileString: file,
                docType,
                onSuccess: (docFileId) => {
                    dispatch(banksUpdateDocTemplateFile({
                        bankId,
                        docFileId,
                        docType,
                        onSuccess: () => {
                            if (docType === DocTypes.REPORT_TEMPLATE) {
                                setIsAddReportTemplateDisplayed(false);
                                setReportFile('');
                            }

                            if (docType === DocTypes.BILL_TEMPLATE) {
                                setIsAddBillTemplateDisplayed(false);
                                setBillFile('');
                            }
                        }
                    }))
                }
            }))
        }
    };

    const handleFormSubmit = (props: BanksFormActionProps) => {
        const {
            name,
            aliasName,
            correspondentAccount,
            bic,
            inn,
            kpp,
            siteAddres,
            instance,
            organisationName,
            organisationInn,
            organisationKpp,
            organisationHeadFirstName,
            organisationHeadMiddleName,
            organisationHeadLastName,
            organisationHeadPost,
            templateReportFileString,
            templateBillFileString,
        } = props;

        if (bankId) {
            dispatch(banksUpdateBankThunk({
                bankId,
                name,
                aliasName,
                correspondentAccount,
                bic,
                inn,
                kpp,
                siteAddres,
                instance,
                organisationName,
                organisationInn,
                organisationKpp,
                organisationHeadFirstName,
                organisationHeadMiddleName,
                organisationHeadLastName,
                organisationHeadPost,
                templateReportFileString,
                templateBillFileString,
                onSuccess: () => {
                    setIsFormDisplayed(false)
                }
            }));
        }
    };

    const handleDelete = () => {
        if (window.confirm('Точно хотите удалить?')) {
            if (!!bankId) {
                dispatch(banksDeleteBankThunk({
                    bankId,
                    onSuccess: () => {
                        navigate('/manager/banks');
                    },
                    onError: () => {
                        // TODO
                    }
                }));
            }
        }

        return;
    };

    const getReportFile = (files: UploadedFile[]) => {
        const { fileString } = files[0];
        setReportFile(fileString);
    };

    const getBillFile = (files: UploadedFile[]) => {
        const { fileString } = files[0];
        setBillFile(fileString);
    };

    return (
        <>
            {bank && !isBankLoading && (
                <div>
                    <div className={styles.block}>
                        <div className={styles.controls}>
                            {!isFormDisplayed && (
                                <>
                                    <button
                                        className={styles.button}
                                        onClick={() => setIsFormDisplayed(true)}
                                    >Редактировать</button>
                                <button
                                    onClick={handleDelete}
                                    className={cn(
                                        styles.button,
                                        styles.buttonDeclined,
                                    )}
                                >
                                    Удалить
                                </button>
                                </>
                            )}
                        </div>
                        {isFormDisplayed && (
                            <>
                                <div className={styles.control}>
                                    <button
                                        className={styles.button}
                                        onClick={() => setIsFormDisplayed(false)}
                                    >Отмена</button>
                                </div>
                                <BanksForm
                                    name={bank.name}
                                    aliasName={bank.aliasName}
                                    correspondentAccount={bank.correspondentAccount}
                                    bic={bank.bic}
                                    inn={bank.inn}
                                    kpp={bank.kpp}
                                    siteAddres={bank.siteAddres}
                                    instance={bank.instance}
                                    organisationName={bank.organisation.name}
                                    organisationInn={bank.organisation.inn}
                                    organisationKpp={bank.organisation.kpp}
                                    organisationHeadFirstName={bank.organisation.head.firstName}
                                    organisationHeadMiddleName={bank.organisation.head.middleName}
                                    organisationHeadLastName={bank.organisation.head.lastName}
                                    organisationHeadPost={bank.organisation.head.post}
                                    onSubmit={handleFormSubmit}
                                />
                            </>
                        )}
                    </div>
                    <div className={cn(
                        styles.block,
                        styles.blockText
                    )}>
                        <h2>Информация о банке</h2>
                        <div>Наименование: <b>{bank?.name}</b></div>
                        <div>Корр.счет: <b>{bank?.correspondentAccount}</b></div>
                        <div>БИК: <b>{bank?.bic}</b></div>
                        <div>ИНН: <b>{bank?.inn}</b></div>
                        <div>КПП: <b>{bank?.kpp}</b></div>
                        <div>Адрес в интернете: <b>{bank?.siteAddres}</b></div>
                        <div>Инстанция: <b>{bank?.instance}</b></div>
                        <div>Организация: <b>{bank?.organisation.name}</b></div>
                        <div>Инн организации: <b>{bank?.organisation.inn}</b></div>
                        <div>Кпп организации: <b>{bank?.organisation.kpp}</b></div>
                        <div>Должность руководителя организации: <b>{bank?.organisation.head.post}</b></div>
                        <div>Руководитель организации: <b>{bank?.organisation.head.lastName} {bank?.organisation.head.firstName} {bank?.organisation.head.middleName}</b></div>
                        <div>
                            <div className={styles.blockRow}>
                                <div className={styles.blockTitle}>
                                    Шаблон документа акта: <b>
                                        {reportDocInfo && (
                                            <a href={`/api${reportDocInfo.fileLink}`}>
                                                Скачать
                                            </a>
                                        )}
                                    </b>
                                </div>
                                {!isAddReportTemplateDisplayed && (
                                    <button
                                        onClick={() => setIsAddReportTemplateDisplayed(true)}
                                        className={styles.button}
                                    >
                                        Загрузить новый шаблон
                                    </button>
                                )}
                                {isAddReportTemplateDisplayed && (
                                    <>
                                        <div className={styles.blockRow}>
                                            <FileLoader
                                                type='.sig,.doc,.docx'
                                                onLoadFile={getReportFile}
                                            />
                                        </div>
                                        <div className={styles.controls}>
                                            <button
                                                onClick={ () => saveDoc(DocTypes.REPORT_TEMPLATE) }
                                                className={styles.button}
                                                disabled={!reportFile}
                                            >
                                                Сохранить
                                            </button>
                                            <button
                                                onClick={ () => setIsAddReportTemplateDisplayed(false) }
                                                className={styles.button}
                                            >
                                                Отмена
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={styles.blockRow}>
                                <div className={styles.blockTitle}>
                                    Шаблон документа счёта: <b>
                                        {billDocInfo && (
                                            <a href={`/api${billDocInfo.fileLink}`}>Скачать</a>
                                        )}
                                    </b>
                                </div>
                                {!isAddBillTemplateDisplayed && (
                                    <button
                                        onClick={() => setIsAddBillTemplateDisplayed(true)}
                                        className={styles.button}
                                    >
                                        Загрузить новый шаблон
                                    </button>
                                )}
                                {isAddBillTemplateDisplayed && (
                                    <>
                                        <div className={styles.blockRow}>
                                            <FileLoader
                                                type='.sig,.doc,.docx'
                                                onLoadFile={getBillFile}
                                            />
                                        </div>
                                        <div className={styles.controls}>
                                            <button
                                                onClick={ () => saveDoc(DocTypes.BILL_TEMPLATE) }
                                                className={styles.button}
                                                disabled={!billFile}
                                            >
                                                Сохранить
                                            </button>
                                            <button
                                                onClick={ () => setIsAddBillTemplateDisplayed(false) }
                                                className={styles.button}
                                            >
                                                Отмена
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <h2>Отчётные периоды</h2>
                    <PeriodsListPage bankId={bankId}/>
                </div>
            )}
            {(!bank && isBankLoading) && (
                <h2>Загрузка</h2>
            )}
            {(!bank && !isBankLoading) && (
                <h2>Банк не найден</h2>
            )}
        </>
    );
};

export default BankInfoPage;