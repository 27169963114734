import React from 'react';
import { NavLink } from 'react-router-dom';
import { AgentInfo } from '../../../../../store/users/usersSlice';
import styles from './AgentListItem.module.scss';

interface AgentsListItemProps {
    index: number;
    agent: AgentInfo;
};

const AgentsListItem = ({
    agent,
    index,
}: AgentsListItemProps) => {
    const {
        agentId,
        organisationName,
        headLastName,
        headFirstName,
        headMiddleName,
        headPost,
        inn,
        kpp,
        requisites,
    } = agent;

    return(
        <tr className={styles.tr}>
            <td>{index + 1}</td>
            <td><b>{organisationName}</b></td>
            <td>{inn}</td>
            <td>{kpp}</td>
            <td>{headLastName} {headFirstName} {headMiddleName}</td>
            <td>{headPost}</td>
            <td><NavLink to={`/manager/agents/${agentId}`}>Подробнее</NavLink></td>
            {/* <td>{requisites}</td> */}
        </tr>
    )
};

export default AgentsListItem;