import { RequestError, RequestErrorTypes } from "../store/errors/errorsSlice";
import { PeriodStatuses } from "../store/periods/periodsSlice";
import { FileStatuses, ReportStatuses } from "../store/reports/reportsSlice";

export const translateReportStatuses = (status: ReportStatuses) => {
    switch (status) {
        case ReportStatuses.NEW:
            return 'Новый';

        case ReportStatuses.ON_REVISION:
            return 'Отправлен на доработку';

        case ReportStatuses.ON_VERIFICATION:
            return 'Отправлен на проверку';

        case ReportStatuses.ACCEPTED:
            return 'Проверен';

        case ReportStatuses.CANCELED:
            return 'Отменён';
    
        default:
            return;
    }
};

export const translatePeriodStatuses = (status: PeriodStatuses) => {
    switch (status) {
        case PeriodStatuses.CREATED:
            return 'Новый';

        case PeriodStatuses.DECLINE:
            return 'Отменён';

        case PeriodStatuses.SUCCESS:
            return 'Завершён';
    
        default:
            return;
    }
};

export const translateFilesStatuses = (status: FileStatuses) => {
    switch (status) {
        case FileStatuses.LOADED:
            return 'Загружен';

        case FileStatuses.ACCEPTED:
            return 'Подтверждён';

        case FileStatuses.DECLINED:
            return 'Отклонён';

        case FileStatuses.HIDDEN:
            return 'Скрыт';
    
        default:
            return;
    }
};

export const translateMonth = (month: number) => {
    switch (month) {
        case 1:
            return 'Январь';

        case 2:
            return 'Февраль';

        case 3:
            return 'Март';

        case 4:
            return 'Апрель';

        case 5:
            return 'Май';

        case 6:
            return 'Июнь';

        case 7:
            return 'Июль';

        case 8:
            return 'Август';

        case 9:
            return 'Сентябрь';

        case 10:
            return 'Октябрь';

        case 11:
            return 'Ноябрь';

        case 12:
            return 'Декабрь';
    
        default:
            return;
    }
};

export const checkError = (activeErrors: RequestError[], status: number | null) => {    
    return activeErrors.find((error) => error.status === status);
};

export const translateError = (error: RequestErrorTypes) => {
    switch (error) {
        case error = RequestErrorTypes.REQUIRED_FIELDS:
            return 'Заполнены не все поля!';

        case error = RequestErrorTypes.SERVER_ERROR:
            return 'Ошибка сервера!';

        case error = RequestErrorTypes.MAIL_SENDING_ERROR:
            return 'Ошибка отправки почты!';

        case error = RequestErrorTypes.UNAUTHORIZED:
            return 'Неавторизован!';

        case error = RequestErrorTypes.SERVER_ERROR:
            return 'Доступ запрещён!';

        case error = RequestErrorTypes.ERR_BAD_RESPONSE:
            return 'Сервер не отвечает! Перезагрузите страницу или повторите попытку позже!';

        case error = RequestErrorTypes.ERR_NETWORK:
            return 'Ошибка подключения! Проверьте интернет соединение!';

        case error = RequestErrorTypes.ERR_UNEXPECTED:
            return 'Непредвиденная ошибка, обратитесь к администратору';

        case error = RequestErrorTypes.WRONG_FILE_TYPE:
            return 'Неверный тип файла!';

        case error = RequestErrorTypes.EMPTY_FILE:
            return 'Пустой файл!';

        case error = RequestErrorTypes.EMPTY_TEMPLATE:
            return 'Пустой файл!';

        case error = RequestErrorTypes.FILE_LIMIT:
            return 'Одновременно можно загрузить 10 файлов!';

        case error = RequestErrorTypes.BANKS_NOT_FOUND:
            return 'Банки с такими данными не найдены!';

        case error = RequestErrorTypes.BANK_NOT_FOUND:
            return 'Банк с такими данными не найден!';
   
        case error = RequestErrorTypes.BANK_ALREADY_EXISTS:
            return 'Банк с таким БИК и организацией уже существует!';
   
        case error = RequestErrorTypes.BANK_TEMPLATES_NOT_FOUND:
            return 'Шаблон для банка не найден!';
   
        case error = RequestErrorTypes.AGENTS_NOT_FOUND:
            return 'Агенты не найдены!';
   
        case error = RequestErrorTypes.AGENT_NOT_FOUND:
            return 'Агент не найден!';
   
        case error = RequestErrorTypes.AGENT_ALREADY_EXISTS:
            return 'Агент с таким ИНН уже существует';
   
        case error = RequestErrorTypes.USERS_NOT_FOUND:
            return 'Пользователи не найдены!';
   
        case error = RequestErrorTypes.USER_NOT_FOUND:
            return 'Пользователь не найден!';
   
        case error = RequestErrorTypes.USER_ALREADY_EXISTS:
            return 'Пользователь с таким логином уже существует';
   
        case error = RequestErrorTypes.PERIODS_NOT_FOUND:
            return 'Периоды не найдены!';
   
        case error = RequestErrorTypes.PERIOD_NOT_FOUND:
            return 'Период не найден!';
   
        case error = RequestErrorTypes.PERIOD_HAS_UNACCEPTED_REPORTS:
            return 'У периода есть незавершённые акты!';
   
        case error = RequestErrorTypes.REPORT_NOT_FOUND:
            return 'Отчёт не найден!';
   
        case error = RequestErrorTypes.REPORTS_NOT_FOUND:
            return 'Отчёты не найдены!';
   
        case error = RequestErrorTypes.EMAIL_FORMAT_ERROR:
            return 'Неправильный формат email';
   
        case error = RequestErrorTypes.PASSWORD_FORMAT_ERROR:
            return 'Пароль должен состоять из: более 8 или менее 48 символов, латинских заглавных и строчных букв, как минимум одной цифры и спецсимвола: !@#$%^&';
   
        default:
            return 'Непредвиденная ошибка, обратитесь к администратору';
    }
};