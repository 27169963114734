import React from 'react';
import { useFormik } from 'formik';
import { BanksSearchFormActionProps } from '../../../../store/banks/banksSlice';
import styles from './BanksSearchForm.module.scss';

interface BanksSearchFormProps {
    onSubmit: (formData: BanksSearchFormActionProps) => void;
};

const BanksSearchForm = ({
    onSubmit,
}: BanksSearchFormProps) => {
    const formik = useFormik({
        initialValues: {
            name: '',
            bic: '',
            inn: '',
        },
        onSubmit: (props: BanksSearchFormActionProps) => {
            onSubmit(props);
        },
    });

    const handleResetForm = (e: any) => {
        formik.handleReset(e);
        onSubmit({});
    };

    return (
        <div>
            <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
            >
                <div className={styles.controls}>
                    <input
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        placeholder="Название банка"
                        className={styles.input}
                    />
                    <input
                        type="text"
                        name="bic"
                        value={formik.values.bic}
                        onChange={formik.handleChange}
                        placeholder="БИК"
                        className={styles.input}
                    />
                    <input
                        type="text"
                        name="inn"
                        value={formik.values.inn}
                        onChange={formik.handleChange}
                        placeholder="ИНН организации"
                        className={styles.input}
                    />
                </div>
                <div className={styles.controls}>
                    <button
                        className={styles.button}
                        type="submit"
                    >
                        Найти банки
                    </button>
                    <button
                        className={styles.button}
                        type="button"
                        onClick={handleResetForm}
                    >
                        Сбросить фильтр
                    </button>
                </div>
            </form>
        </div>
    );
};

export default BanksSearchForm;
