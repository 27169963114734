import React, { useEffect, useState } from 'react';
import styles from './Paginator.module.scss';
import { ReactComponent as PaginationIcon } from './images/PaginationIcon.svg';
import cn from 'classnames';

interface PaginatorProps {
    buttonsCount: number;
    items: number;
    scale: number;
    parentPage: number;
    parentPageIndex: number;
    onPage: (page: number, pageIndex: number) => void;
}

const Paginator = ({
    buttonsCount,
    items,
    scale,
    parentPage,
    parentPageIndex,
    onPage,
}: PaginatorProps) => {
    const [page, setPage] = useState<number>(1);
    const [pageIndex, setPageIndex] = useState<number>(1);

    const handlePaginationClick = (pageNumber: number, index: number) => {
        setPage(pageNumber);

        if (index === (buttonsCount - 1) && pageNumber < Math.ceil(items / scale)) {
            setPageIndex(pageIndex + 1);
        }

        if (index === 0 && pageIndex > 1) {
            setPageIndex(pageIndex - 1);
        }

        if (pageNumber === 1) {
            setPageIndex(pageNumber);
        }

        if (pageNumber === Math.ceil(items / scale) && (pageNumber > buttonsCount)) {
            setPageIndex(pageNumber - buttonsCount + 1);
        }
    };

    useEffect(() => {
        onPage(page, pageIndex);
    }, [page]);

    useEffect(() => {
        setPage(parentPage);
    }, [parentPage]);

    useEffect(() => {
        setPageIndex(parentPageIndex);
    }, [parentPageIndex]);

    return (
        <div className={styles.wrapper}>
            <button
                className={cn(styles.button, styles.buttonPrev)}
                onClick={() => { handlePaginationClick(1, 0); }}
            >
                <PaginationIcon />
            </button>
            {Array(buttonsCount).fill(null).map((val, index) => {
                if (Math.ceil(items / scale) > index) {
                    return (
                        <button
                            className={styles.button}
                            key={index}
                            disabled={pageIndex + index === page}
                            onClick={() => { handlePaginationClick(pageIndex + index, index); }}
                        >
                            {pageIndex + index}
                        </button>
                    );
                }
            })}
            <button
                className={styles.button}
                onClick={() => {
                    handlePaginationClick(Math.ceil(items / scale), buttonsCount - 1);
                }}>
                <PaginationIcon />
            </button>
        </div>
    );
};

export default Paginator;
