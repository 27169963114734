import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FileLoader from '../../../../components/file-loader/FileLoader';
import Modal from '../../../../components/modal/Modal';
import { Bank, banksLoadBanksListThunk, selectorBanksList } from '../../../../store/banks/banksSlice';
import { periodsCreatePeriodThunk, periodsUploadFileThunk, selectorPeriodsIsCreating, selectorPeriodsIsFileLoading, selectorPeriodsIsLoading } from '../../../../store/periods/periodsSlice';
import { reportsCreateReportsThunk, ReportsGenerateReportsDocumentsThunk, selectorReportsIsCreating, selectorReportsIsLoading, UploadedFile } from '../../../../store/reports/reportsSlice';
import styles from './PeriodsCreateForm.module.scss';

const PeriodsCreateFrom = () => {
    const dispatch = useAppDispatch();

    const isFileLoading = useAppSelector(selectorPeriodsIsFileLoading);
    const isPeriodsLoading = useAppSelector(selectorPeriodsIsLoading);
    const isReportsLoading = useAppSelector(selectorReportsIsLoading);
    const isPeriodCreating = useAppSelector(selectorPeriodsIsCreating);
    const isReportCreating = useAppSelector(selectorReportsIsCreating);
    const banks = useAppSelector(selectorBanksList);

    const [fileString, setFileString] = useState<string>('');
    const [isValidForm, setIsValidForm] = useState<boolean>(false);
    const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(false);

    // TODO данная логика требует выделения в отдельный компонент селектора
    const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            bankId: '',
            firstDecade: true,
            secondDecade: true,
            thirdDecade: true,
            month: '',
            year: 2024,
        },
        onSubmit: ({
            bankId,
            firstDecade,
            secondDecade,
            thirdDecade,
            month,
            year,
        }) => {
            filterBanks(bankId);

            if (selectedBank) {
                dispatch(periodsUploadFileThunk({
                    bankId,
                    fileString,
                    onSuccess: ({
                        periodId,
                        excelFileId,
                    }) => {
                        dispatch(periodsCreatePeriodThunk({
                            bankId: selectedBank.bankId,
                            bankName: selectedBank.name,
                            organisationName: selectedBank.organisation.name,
                            decade: {
                                first: firstDecade,
                                second: secondDecade,
                                third: thirdDecade,
                            },
                            month: Number(month),
                            year,
                            product: 'Банковская гарантия',
                            excelFileId,
                            periodId,
                            onSuccess: ({
                                periodId,
                                decade,
                                month,
                                year,                   
                            }) => {
                                dispatch(ReportsGenerateReportsDocumentsThunk({
                                    periodId,
                                    decade,
                                    month,
                                    year,
                                    bankData: selectedBank,
                                    excelFileId,
                                    onSuccess: ({
                                        periodId,
                                        generatedReportsData,
                                        errorReportsData,
                                    }) => {
                                        dispatch(reportsCreateReportsThunk({
                                            periodId,
                                            generatedReportsData,
                                            errorReportsData,
                                        }));
                                    }
                                }))
                            }
                        }));
                    }
                }))
            }
        },
    });

    const getFile = (files: UploadedFile[]) => {
        const { fileString } = files[0];
        setFileString(fileString);
    };

    // TODO данная логика требует выделения в отдельный компонент селектора
    const filterBanks = (id: string) => {
        formik.setFieldValue('bankId', id);
        
        const selected = banks.find((bank) => bank.bankId === id);

        if (selected) {
            setSelectedBank(selected);
        }
    };

    // TODO загрузка банков должна быть в другом месте
    useEffect(() => {
        dispatch(banksLoadBanksListThunk({
            limit: 9999, // TODO заглушка, в дальнейшем дуолжен быть поиск по банку внутри инпута
        }));
    }, []);

    useEffect(() => {
        if (
            !!formik.values.bankId
            && (
                !!formik.values.firstDecade
                || !!formik.values.secondDecade
                || !!formik.values.thirdDecade
            )
            && !!formik.values.month
            && !!formik.values.year
        ) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    }, [formik.values]);

    useEffect(() => {
        if (!isFileLoading && !isPeriodsLoading && !isReportsLoading) {
            setIsModalOpened(false);
        } else {
            setIsModalOpened(true);
        }
    }, [
        isFileLoading,
        isPeriodsLoading,
        isReportsLoading,
    ]);

    const onModalClose = () => {
        setIsModalOpened(false);
    };

    return (
        <div>
            {!isFormDisplayed && (
                <button
                    className={styles.button}
                    onClick={() => setIsFormDisplayed(true)}
                >Создать период</button>
            )}
            {isFormDisplayed && (
                <>
                    <div className={styles.controls}>
                        <button
                            className={styles.button}
                            onClick={() => setIsFormDisplayed(false)}
                        >Отмена</button>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                    >
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Банк<span>*</span></div>
                            {/* TODO должен быть кастомный селектор */}
                            <select
                                name="bankId"
                                value={formik.values.bankId}
                                onChange={(e) => {filterBanks(e.target.value)}}
                                required={true}
                                className={styles.select}
                            >
                                <option value="" disabled>Выберите банк</option>
                                {banks && banks.map((bank, index) => (
                                    <option key={index} value={bank.bankId}>{bank.name}: ({bank.organisation.name})</option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.formRow}>
                            <label>
                                {/* TODO должен быть селектор */}
                                <input
                                    type="checkbox"
                                    name="firstDecade"
                                    checked={formik.values.firstDecade}
                                    onChange={formik.handleChange}
                                    // required={true}
                                />
                                Первая декада
                            </label>
                        </div>
                        <div className={styles.formRow}>
                            <label>
                                {/* TODO должен быть селектор */}
                                <input
                                    type="checkbox"
                                    name="secondDecade"
                                    // value={formik.values.firstDecade}
                                    checked={formik.values.secondDecade}
                                    onChange={formik.handleChange}
                                    // required={true}
                                />
                                Вторая декада
                            </label>
                        </div>
                        <div className={styles.formRow}>
                            <label>
                                {/* TODO должен быть селектор */}
                                <input
                                    type="checkbox"
                                    name="thirdDecade"
                                    // value={formik.values.firstDecade}
                                    checked={formik.values.thirdDecade}
                                    onChange={formik.handleChange}
                                    // required={true}
                                />
                                Третья декада
                            </label>
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Месяц<span>*</span></div>
                                <select
                                    name="month"
                                    value={formik.values.month}
                                    onChange={formik.handleChange}
                                    required={true}
                                    className={styles.select}
                                >
                                    <option value="" disabled>Выберите месяц</option>
                                    <option value={1}>Январь</option>
                                    <option value={2}>Февраль</option>
                                    <option value={3}>Март</option>
                                    <option value={4}>Апрель</option>
                                    <option value={5}>Май</option>
                                    <option value={6}>Июнь</option>
                                    <option value={7}>Июль</option>
                                    <option value={8}>Август</option>
                                    <option value={9}>Сентябь</option>
                                    <option value={10}>Октябрь</option>
                                    <option value={11}>Ноябрь</option>
                                    <option value={12}>Декабрь</option>
                                </select>
                            </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Год<span>*</span></div>
                            {/* TODO должен быть селектор даты*/}
                            <input
                                type="number"
                                placeholder="Год"
                                name="year"
                                min="2010"
                                max="2099"
                                step="1"
                                value={formik.values.year}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            {/* TODO тут должна быть обработка пустого поля файла */}
                            <div className={styles.formTitle}>Excel файл<span>*</span></div>
                            <FileLoader
                                type='.xlsx' // TODO нужен enum
                                onLoadFile={getFile}
                            />
                        </div>
                        {!isPeriodCreating && !isReportCreating ? (
                            <button
                                type="submit"
                                disabled={!isValidForm}
                                className={styles.button}
                            >Создать</button>
                        ) : (
                            // TODO добавить лоадер
                            <Modal
                                isOpened={isModalOpened}
                                onClose={onModalClose}
                            >
                                <div>
                                    <h2>Период генерируется</h2>
                                </div>
                            </Modal>
                        )}
                    </form>
                </>
            )}
        </div>
    );
};

export default PeriodsCreateFrom;