import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { translateFilesStatuses } from '../../../../helpers/helpers';
import { selectorFilesGetFile } from '../../../../store/files/filesSlice';
import { Doc } from '../../../../store/reports/reportsSlice';
import styles from './AgentFileItem.module.scss';
import cn from 'classnames';

interface AgentFileItemProps {
    file: Doc;
};

const AgentFileItem = ({
    file,
}: AgentFileItemProps) => {
    const docInfo = useAppSelector(selectorFilesGetFile(file.fileId || ''));
    const [fileName, setFileName] = useState<string>('');

    useEffect(() => {        
        let paths: string[] = [];

        if (docInfo) {
            paths = docInfo.fileLink.split('/');
        }

        // TODO данный формат может не подходить если сметется паттерн названия файла, плоэтому в дельнейшем стоит перейти к варианту, когда тип документа хранится в объекте mainDocs/agentDocs
        if (paths.length) {
            const fileNameArr = paths[paths.length - 1].split('_');
            const downloadName = fileNameArr.slice(0, fileNameArr.length - 1);
            const fileType = fileNameArr[fileNameArr.length - 1].split('.').pop();
            
            setFileName(`${downloadName.join('_')}.${fileType}`);
        }
    }, [docInfo]);

    return (
        <div>
            {docInfo && (
                <div className={styles.documentBody}>
                    <div>
                        <a href={`/api${docInfo.fileLink}`} download={fileName}>{fileName}</a>
                        {file.status && (
                            <b>: {translateFilesStatuses(file.status)}</b>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
};

export default AgentFileItem;