import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export enum RequestFunctionNames {
    BANKS__MANAGER_LOAD_CURRENT_BANK = 'BANKS__MANAGER_LOAD_CURRENT_BANK',
    BANKS__LOAD_BANKS_LIST = 'BANKS__LOAD_BANKS_LIST',
    BANKS__CREATE_BANK = 'BANKS__CREATE_BANK',
    BANKS__UPDATE_BANK = 'BANKS__UPDATE_BANK',
    BANKS__DELETE_BANK = 'BANKS__DELETE_BANK',
    BANKS__UPLOAD_DOC_TEMPLATE_FILE = 'BANKS__UPLOAD_DOC_TEMPLATE_FILE',
    BANKS__UPDATE_DOC_TEMPLATE_FILE = 'BANKS__UPDATE_DOC_TEMPLATE_FILE',

    AGENTS__MANAGER_LOAD_CURRENT_AGENT = 'AGENTS__MANAGER_LOAD_CURRENT_AGENT',
    AGENTS__AGENT_LOAD_CURRENT_AGENT = 'AGENTS__AGENT_LOAD_CURRENT_AGENT',
    AGENTS__LOAD_AGENTS_LIST = 'AGENTS__LOAD_AGENTS_LIST',
    AGENTS__LOAD_AGENTS_LIST_BY_IDS = 'AGENTS__LOAD_AGENTS_LIST_BY_IDS',
    AGENTS__CREATE_AGENT = 'AGENTS__CREATE_AGENT',
    AGENTS__UPDATE_AGENT = 'AGENTS__UPDATE_AGENT',
    AGENTS__DELETE_AGENT = 'AGENTS__DELETE_AGENT',

    PERIODS__LOAD_CURRENT_PERIOD = 'PERIODS__LOAD_CURRENT_PERIOD',
    PERIODS__AGENT_LOAD_CURRENT_PERIOD = 'PERIODS__AGENT_LOAD_CURRENT_PERIOD',
    PERIODS__LOAD_PERIODS_LIST = 'PERIODS__LOAD_PERIODS_LIST',
    PERIODS_UPLOAD_FILE = 'PERIODS_UPLOAD_FILE',
    PERIODS__CREATE_PERIOD = 'PERIODS__CREATE_PERIOD',
    PERIODS__SEND_STATUS = 'PERIODS__SEND_STATUS',

    REPORTS__LOAD_CURRENT_REPORT = 'REPORTS__LOAD_CURRENT_REPORT',
    REPORTS__AGENT_LOAD_CURRENT_REPORT = 'REPORTS__AGENT_LOAD_CURRENT_REPORT',
    REPORTS__LOAD_REPORTS_LIST = 'REPORTS__LOAD_REPORTS_LIST',
    REPORTS__LOAD_AGENT_REPORTS_LIST = 'REPORTS__LOAD_AGENT_REPORTS_LIST',
    REPORTS__GENERATE_REPORTS_DOCUMENTS = 'REPORTS__GENERATE_REPORTS_DOCUMENTS',
    REPORTS__CREATE_REPORTS = 'REPORTS__CREATE_REPORTS',
    REPORTS__UPDATE_REPORTS = 'REPORTS__UPDATE_REPORTS',
    REPORTS__SEND_STATUS = 'REPORTS__SEND_STATUS',
    REPORTS__AGENT_SEND_STATUS = 'REPORTS__AGENT_SEND_STATUS',
    REPORTS__SEND_FILE_STATUS = 'REPORTS__SEND_FILE_STATUS',
    REPORTS__MANAGER_SEND_MESSAGE = 'REPORTS__MANAGER_SEND_MESSAGE',
    REPORTS__SEND_MESSAGE = 'REPORTS__SEND_MESSAGE',
    REPORTS__UPLOAD_FILE = 'REPORTS__UPLOAD_FILE',
    REPORTS__CREATE_MAIN_FILE = 'REPORTS__CREATE_MAIN_FILE',
    REPORTS__CREATE_AGENT_FILE = 'REPORTS__CREATE_AGENT_FILE',
    REPORTS__SEND_DOCS_FOR_ACCOUNTING = 'REPORTS__SEND_DOCS_FOR_ACCOUNTING',
    REPORTS__FILES_STATUS_UPDATE = 'REPORTS__FILES_STATUS_UPDATE',

    USERS__LOAD_CURRENT_USER = 'USERS__LOAD_CURRENT_USER',
    USERS__LOAD_USER_PROFILE = 'USERS__LOAD_USER_PROFILE',
    USERS__MANAGER_LOAD_USERS_LIST = 'USERS__MANAGER_LOAD_USERS_LIST',
    USERS__ADMIN_LOAD_USERS_LIST = 'USERS__ADMIN_LOAD_USERS_LIST',
    USERS__CREATE_USER = 'USERS__CREATE_USER',
    USERS__UPDATE_USER = 'USERS__UPDATE_USER',
    USERS__DELETE_USER = 'USERS__DELETE_USER',
};

export enum RequestErrorTypes {
    REQUIRED_FIELDS = 'REQUIRED_FIELDS',

    SERVER_ERROR = 'SERVER_ERROR', // 500
    MAIL_SENDING_ERROR = 'MAIL_SENDING_ERROR',
    ERR_BAD_RESPONSE = 'ERR_BAD_RESPONSE', // 504
    ERR_NETWORK = 'ERR_NETWORK', // Network error
    ERR_UNEXPECTED = 'ERR_UNEXPECTED', // необработанные ошибки 

    WRONG_FILE_TYPE = 'WRONG_FILE_TYPE',
    EMPTY_FILE = 'EMPTY_FILE',
    EMPTY_TEMPLATE = 'EMPTY_TEMPLATE',
    FILE_LIMIT = 'FILE_LIMIT',

    UNAUTHORIZED = 'UNAUTHORIZED',
    FORBIDDEN ='FORBIDDEN',

    BANKS_NOT_FOUND = 'BANKS_NOT_FOUND',
    BANK_NOT_FOUND = 'BANK_NOT_FOUND',
    BANK_ALREADY_EXISTS = 'BANK_ALREADY_EXISTS',
    BANK_TEMPLATES_NOT_FOUND = 'BANK_TEMPLATES_NOT_FOUND',

    AGENTS_NOT_FOUND = 'AGENTS_NOT_FOUND',
    AGENT_NOT_FOUND = 'AGENT_NOT_FOUND',
    AGENT_ALREADY_EXISTS = 'AGENT_ALREADY_EXISTS',

    USERS_NOT_FOUND = 'USERS_NOT_FOUND',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',

    PERIODS_NOT_FOUND = 'PERIODS_NOT_FOUND',
    PERIOD_NOT_FOUND = 'PERIOD_NOT_FOUND',
    PERIOD_HAS_UNACCEPTED_REPORTS = 'PERIOD_HAS_UNACCEPTED_REPORTS',

    REPORT_NOT_FOUND = 'REPORT_NOT_FOUND',
    REPORTS_NOT_FOUND = 'REPORTS_NOT_FOUND',

    EMAIL_FORMAT_ERROR = 'EMAIL_FORMAT_ERROR',
    PASSWORD_FORMAT_ERROR = 'PASSWORD_FORMAT_ERROR',
};

export interface RequestError {
    request: RequestFunctionNames;
    status: number | null; // null если нет ответа
    type: RequestErrorTypes;
};

export interface ErrorsState {
    isErrorModalOpened: boolean;
    activeErrors: RequestError[],
};

const initialState: ErrorsState = {
    isErrorModalOpened: false,
    activeErrors: [],
};

export const errorsSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        errorsSetActiveErrorAction: (state, action: PayloadAction<RequestError>) => {
            const existedError = state.activeErrors.filter((error) => {
                if (error.status === action.payload.status
                    && error.request === action.payload.request
                    && error.type === action.payload.type
                ) {
                    return error;
                }
            });
            
            if (!existedError.length) {
                state.activeErrors.push(action.payload); 
            }
        },
        errorsDeleteActiveErrorAction: (state, action: PayloadAction<RequestError>) => {
            state.activeErrors = state.activeErrors.filter((error) => {
                if (error.status !== action.payload.status
                    && error.request !== action.payload.request
                    && error.type !== action.payload.type
                ) {
                    return error;
                }
            });
        },
        errorsDeleteActiveRequestErrorsAction: (state, action: PayloadAction<RequestFunctionNames>) => {
            state.activeErrors = state.activeErrors.filter((error) => error.request !== action.payload);
        },
        errorsDeleteErrorsAction: (state) => {
            state.activeErrors = [];
        },
    },
});

export const {
    errorsSetActiveErrorAction,
    errorsDeleteActiveErrorAction,
    errorsDeleteActiveRequestErrorsAction,
    errorsDeleteErrorsAction,
} = errorsSlice.actions;

export const selectorActiveErrors = (state: RootState) => state.errors.activeErrors;
export const selectorError = (error: RequestErrorTypes) => (state: RootState) => {
    return state.errors.activeErrors.find((item) => item.type === error);
};
export const selectIsErrorModalOpened = (state: RootState) => state.errors.isErrorModalOpened;

export default errorsSlice.reducer;