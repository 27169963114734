import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { reportsLoadReportsListThunk } from '../../store/reports/reportsSlice';
import styles from './Reports.module.scss';

const ReportsPage = () =>{
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(reportsLoadReportsListThunk({
            limit: 20,
        }));
    }, []);

    return (
        <div className={styles.wrapper}>
            <h1>Акты</h1>
            <Outlet />
        </div>
   );
};

export default ReportsPage;