import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk, RootState } from "../../app/store";
import { authExecAfterAuthThunk, authLogoutAction } from "../auth/authSlice";

export const enum DocTypes {
    REPORT_TEMPLATE = 'REPORT_TEMPLATE', // шаблон отчета для банка
    BILL_TEMPLATE = 'BILL_TEMPLATE', // шаблон счета для банка
    PERIOD_TEMPLATE = 'PERIOD_TEMPLATE', // excel файла периода
    REPORT = 'REPORT', // файл отчета
    BILL = 'BILL', // файл счета
};

// интерфейс объекта файла в коллекции файлов
export interface File {
    fileId: string;
    fileLink: string;
    createdTimestamp: number;
    updatedTimestamp: number;
};

export interface FilesState {
    filesList: File[];
    isLoading: boolean;
};

const initialState: FilesState = {
    filesList: [],
    isLoading: false,
};

export const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        filesSetFilesListAction: (state, action: PayloadAction<File[]>) => {
            state.filesList = action.payload;
        },
        filesSetIsLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        // очищение стора при logoutAction
        builder.addCase(authLogoutAction, () => {
            return initialState;
        });
    },
});

export const {
    filesSetFilesListAction,
    filesSetIsLoadingAction,
} = filesSlice.actions;

export const selectorFilesList = (state: RootState) => state.files.filesList; 
export const selectorFilesIsLoading = (state: RootState) => state.files.isLoading; 
export const selectorFilesGetFile = (fileId: string) => {
    return (state: RootState) => state.files.filesList.find((file) => file.fileId === fileId);
};

interface FilesLoadFilesListThunkProps {
    fileIds: string[];
};

export const filesLoadFilesListThunk = (props: FilesLoadFilesListThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorFilesIsLoading(getState());

    if (isLoading) {
        return;
    }

    const { fileIds } = props;

    // dispatch(filesSetIsLoadingAction(true));

    // TODO разделить на разные эндпоинты для агента и других
    axios.post('/api/files', { fileIds })
        .then(({ data }) => {
            if (data.status === 200) {
                // dispatch(filesSetIsLoadingAction(false));
                dispatch(filesSetFilesListAction(data.body.links));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: filesLoadFilesListThunk,
                onError: () => {
                    // TODO обработка ошибок по аналогии с quietex
                },
            }))
        })
        .finally(() => {
            dispatch(filesSetIsLoadingAction(false));
        });
};

export default filesSlice.reducer;