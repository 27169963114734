import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Paginator from '../../../../components/paginator/Paginator';
import {
    agentsCreateAgentThunk,
    AgentsSearchFormActionProps,
    AgentsFormActionProps,
    agentsLoadAgentsListThunk,
    selectorAgentsCount,
    selectorAgentsIsLoading,
    selectorAgentsList,
    AGENTS_SCALE
} from '../../../../store/agents/agentsSlice';
import AgentsForm from '../../components/form/AgentsForm';
import AgentsSearchForm from '../../components/search-form/AgentsSearchForm';
import AgentsListItem from './item/AgentsListItem';
import styles from './AgentsListPage.module.scss';

// TODO интерфейс может расширятся в дальнейшем
interface TableSort {
    field: string;
    sort: number;
};

const AgentsListPage = () => {
    const dispatch = useAppDispatch();

    const agents = useAppSelector(selectorAgentsList);
    const agentsCount = useAppSelector(selectorAgentsCount);
    const isLoading = useAppSelector(selectorAgentsIsLoading);

    const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [tableSort, setTableSort] = useState<TableSort | null>(null);
    const [filter, setFilter] = useState<AgentsSearchFormActionProps>({});

    const handlePaginatorClick = (page: number, pageIndex: number) => {
        setPage(page);
        setPageIndex(pageIndex);

        dispatch(agentsLoadAgentsListThunk({
            inn: filter.inn,
            organisationName: filter.organisationName,
            page,
            sortBy: tableSort ? JSON.stringify(tableSort): '',
            limit: AGENTS_SCALE,
        }));
    };

    const handleSearchAgentsFormSubmit = ({
        inn,
        organisationName,
    }: AgentsSearchFormActionProps) => {
        filter.inn = inn || '';
        filter.organisationName = organisationName || '';

        setFilter(filter);

        dispatch(agentsLoadAgentsListThunk({
            inn,
            organisationName,
            page: 1,
            sortBy: tableSort ? JSON.stringify(tableSort): '',
            limit: AGENTS_SCALE,
            onSuccess: () => {
                setPage(1);
                setPageIndex(1);
            },
        }));
    };

    const handleFormSubmit = (props: AgentsFormActionProps) => {
        const {
            login,
            password,
            firstName,
            middleName,
            lastName,
            phone,
            email,
            organisationName,
            headFirstName,
            headMiddleName,
            headLastName,
            headPost,
            inn,
            kpp,
            bank,
            bic,
            paymentAccount,
            correspondentAccount,
        } = props;

        dispatch(agentsCreateAgentThunk({
            login,
            password,
            firstName,
            middleName,
            lastName,
            phone,
            email,
            organisationName,
            headFirstName,
            headMiddleName,
            headLastName,
            headPost,
            inn,
            kpp,
            bank,
            bic,
            paymentAccount,
            correspondentAccount,
            onError: (error) => {
                // TODO тут должна быть всплывашка с ошибкой
                if (error.response.status === 400) {
                    alert('ОШИБКА | Агент с таким инн уже есть в системе');
                }
            }
        }));
    };

    const handleTableSort = (tableSort: TableSort) => {
        setTableSort(tableSort);
        
        dispatch(agentsLoadAgentsListThunk({
            inn: filter.inn,
            organisationName: filter.organisationName,
            page,
            sortBy: JSON.stringify(tableSort),
            limit: AGENTS_SCALE,
        }));
    };

    return (
        <>
            <div className={styles.block}>
                {!isFormDisplayed && (
                    <button
                        className={styles.button}
                        onClick={() => setIsFormDisplayed(true)}
                    >Добавить агента</button>
                )}
                {isFormDisplayed && (
                    <>
                        <button
                            className={styles.button}
                            onClick={() => setIsFormDisplayed(false)}
                        >Отмена</button>
                        <AgentsForm
                            isNewAgent={true}
                            onSubmit={handleFormSubmit}
                        />
                    </>
                )}
            </div>
            <div className={styles.block}>
                <AgentsSearchForm
                    onSubmit={handleSearchAgentsFormSubmit}
                />
            </div>
            {!!agents.length && (
                <div className={styles.block}>
                    {agentsCount > AGENTS_SCALE && (
                        <Paginator
                            parentPage={page}
                            parentPageIndex={pageIndex}
                            buttonsCount={5}
                            items={agentsCount}
                            scale={AGENTS_SCALE}
                            onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                        />
                    )}

                    {!isLoading && (
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'organisationName',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >
                                        Название организации {tableSort?.sort && (tableSort?.sort < 0 ? '↓' : '↑')}
                                    </th>
                                    <th>ИНН</th>
                                    <th>КПП</th>
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'headLastName',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >ФИО руководителя</th>
                                    <th>Должность руководителя</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {agents.map((agent, index) => (
                                    <AgentsListItem
                                        key={agent.agentId}
                                        agent={agent}
                                        index={index + (page - 1) * AGENTS_SCALE}
                                    />
                                ))}
                            </tbody>
                        </table>
                    )}
                    {agentsCount > AGENTS_SCALE && (
                        <Paginator
                            parentPage={page}
                            parentPageIndex={pageIndex}
                            buttonsCount={5}
                            items={agentsCount}
                            scale={AGENTS_SCALE}
                            onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                        />
                    )}

                    {!!isLoading && (
                        // TODO сделать лоадер
                        <h2>Загрузка...</h2>
                    )}
                </div>
            )}
            {!agents.length && (
                <div className={styles.block}>
                    <b>Агенты не найдены</b>
                </div>
            )}
        </>
    )
};

export default AgentsListPage;