import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import axios from 'axios';

export interface AuthState {
    isLoading: boolean;
    isAuthorized: boolean;
};

const initialState: AuthState = {
    isLoading: false,
    isAuthorized: false,
};

// TODO нужно подумать куда перенести (например в константы)
// enum для значений в localStorage
export const enum LocalStorageValues {
    REFRESH_TOKEN = 'refreshToken',
    ROLES = 'roles',
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authSetIsAuthorizedAction: (state, action: PayloadAction<boolean>) => {
            state.isAuthorized = action.payload;
        },
        authSetIsLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        authLogoutAction: (state) => {
            state.isAuthorized = false;
            localStorage.removeItem(LocalStorageValues.REFRESH_TOKEN);
            localStorage.removeItem(LocalStorageValues.ROLES);
        },
    },
});

export const {
    authSetIsAuthorizedAction,
    authSetIsLoadingAction,
    authLogoutAction,
} = authSlice.actions;

export const selectorAuthIsAuthorized = (state: RootState) => state.auth.isAuthorized;
export const selectorAuthIsLoading = (state: RootState) => state.auth.isLoading;

interface AuthLoginThunkProps {
    login: string;
    password: string;
    onSuccess?: () => void;
    onError?: (errorCode: number) => void;
};

export const authLoginThunk = (props: AuthLoginThunkProps): AppThunk => (dispatch, getState) => {
    const {
        login,
        password,
        onSuccess,
        onError,
    } = props;

    const isLoading = selectorAuthIsLoading(getState());

    if (isLoading) {
        return;
    }

    dispatch(authSetIsLoadingAction(true));

    axios.post('/api/users/token', {
        login,
        password,
    })
        .then(({ data }) => {
            const {
                refreshToken,
                roles,
                userId, // TODO можно использовать userId в сессии
            } = data.body;

            // добавление токена в память и вызов колбэка onSucces
            localStorage.setItem(LocalStorageValues.REFRESH_TOKEN, refreshToken);
            localStorage.setItem(LocalStorageValues.ROLES, roles);
            dispatch(authSetIsAuthorizedAction(true));

            if (onSuccess) {
                onSuccess();
            }
        })
        .catch((error) => {
            // удаление токена из памяти и вызов колбэка onError
            localStorage.removeItem(LocalStorageValues.REFRESH_TOKEN);
            localStorage.removeItem(LocalStorageValues.ROLES);
            dispatch(authSetIsAuthorizedAction(false));

            if (onError) {
                onError(error?.response?.status);
            }
        })
        .finally(() => {
            dispatch(authSetIsLoadingAction(false));
        });
};

interface AuthUpdateTokenThunkProps {
    onSuccess?: () => void;
    onError?: (error: any) => void;
};

export const authUpdateTokenThunk = (props: AuthUpdateTokenThunkProps): AppThunk => (dispatch, getState) => {
    const {
        onSuccess,
        onError,
    } = props;

    const isLoading = selectorAuthIsLoading(getState());
    const refreshToken = localStorage.getItem(LocalStorageValues.REFRESH_TOKEN);

    if (isLoading || !refreshToken) {
        return;
    }

    dispatch(authSetIsLoadingAction(true));
    
    axios.post('/api/users/token', {
        refreshToken,
    })
        .then(({ data }) => {
            const {
                refreshToken,
                roles,
                userId,
            } = data.body;
            
            // обновление токена в памяти и вызов колбэка onSucces
            localStorage.setItem(LocalStorageValues.REFRESH_TOKEN, refreshToken);
            localStorage.setItem(LocalStorageValues.ROLES, roles);
            dispatch(authSetIsAuthorizedAction(true));

            try {
                if (onSuccess) {
                    onSuccess();
                }
            } catch (error) {
                console.error(error);
            }
        })
        .catch((error) => {
            // удаление токена из памяти и вызов колбэка onError
            localStorage.removeItem(LocalStorageValues.REFRESH_TOKEN);
            localStorage.removeItem(LocalStorageValues.ROLES);
            dispatch(authSetIsAuthorizedAction(false));

            if (onError) {
                onError(error);
            }
        })
        .finally(() => {
            dispatch(authSetIsLoadingAction(false));
        });
};

interface AuthExecAfterAuthThunkProps {
    error: any;
    thunk: any;
    props?: any;
    onError?: (error: any) => void;
};

// thunk обработчик статусов аутентификации от эндпоинтов сервера
export const authExecAfterAuthThunk = ({
    error,
    thunk,
    props,
    onError,
}: AuthExecAfterAuthThunkProps): AppThunk => (dispatch) => {
    // если от сервера пришла 403 ошибка (нет ролей для совершения действия) то разлогин
    if (error?.response?.status === 403) {
        dispatch(authLogoutAction());

        return;
    }

    // обработка других статусов от сервера
    if (error?.response?.status !== 401 && onError) {
        onError(error);

        return;
    }

    // обновление refresh токена и выполнение переданной thunk функции если всё
    dispatch(authUpdateTokenThunk({
        onSuccess: () => {
            dispatch(thunk(props));
        },
        onError,
    }));
}

export default authSlice.reducer;