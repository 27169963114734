import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk, RootState } from "../../app/store";
import { ERRORS_LIMIT, RECONNECT_TIMEOUT } from "../../constants/constants";
import { agentsSetCurrentAgentAction } from "../agents/agentsSlice";
import { authExecAfterAuthThunk, authLogoutAction } from "../auth/authSlice";
import { Bank } from "../banks/banksSlice";
import { errorsDeleteActiveRequestErrorsAction, errorsSetActiveErrorAction, RequestErrorTypes, RequestFunctionNames } from "../errors/errorsSlice";
import { DocTypes } from "../files/filesSlice";
import { periodsSetPeriodsListAction, selectorPeriodsList } from "../periods/periodsSlice";
import { AgentInfo, UserRoles } from "../users/usersSlice";

export const REPORTS_SCALE = 20; // TODO в дальнейшем это может настраиваться через стор

// интерфейс документа в объекте отчёта
export interface Doc {
    fileId: string;
    status: FileStatuses;
};

export const enum MessageAuthors {
    SYSTEM = 'SYSTEM',
    MANAGER = 'MANAGER',
    AGENT = 'AGENT',
};

export interface ReportMessage {
    messageId: number;
    author: MessageAuthors;
    message: string;
    created: string;
    createdTimestamp: number;
};

export const enum FileStatuses {
    LOADED = 'LOADED', // файл загружен и виден всем в акте 
    HIDDEN = 'HIDDEN',  // файл скрыт и виден только менеджеру
    ACCEPTED = 'ACCEPTED', // файл проверен менеджером
    DECLINED = 'DECLINED', // файл отклонён менеджером и требует доработки
}

export const enum ReportStatuses {
    NEW = 'NEW', // акт для агента выгружен в систему
    ON_REVISION = 'ON_REVISION', // пописанные и направленные на проверку документы нуждаются в корректировке
    ON_VERIFICATION = 'ON_VERIFICATION', // агент подписал, прикрепил и направил документы на проверку (первично или после доработки)
    ACCEPTED = 'ACCEPTED', // проверка проведена, всё в порядке, акт направлен в бух.
    CANCELED = 'CANCELED', // акт отменён
};

export interface Report {
    reportId: string;
	periodId: string; // ссылка на период для которого сформированы документы
	agentId: string; // ключ агента
    agent: AgentInfo;
	status: ReportStatuses; // новый | на проверке | завершён | отклонён
	mainDocs: Doc[]; // массив ссылок на документы с нашей стороны
	agentDocs: Doc[]; // массив ссылок на документы со стороны агента
	createdTimestamp: number;
    updatedTimestamp: number;
	created: string; // дата создания в iso формате
    messages: ReportMessage[],
    currentNotification?: UserRoles | null;
};

export interface GeneratedReportDocument {
    agentId: string;
    files: string[];
};

export interface ErrorReport {
    agentInn: string;
};

export interface UploadedFile {
    fileName: string;
    fileString: any; // TODO проработка типов
}

export interface ReportsState {
    isLoading: boolean;
    isCreating: boolean;
    reportsList: Report[];
    currentReport: Report | null;
    docsForAccounting: Doc[];
    reportsCount: number;
};

const initialState: ReportsState = {
    isLoading: false,
    isCreating: false,
    reportsList: [],
    currentReport: null,
    docsForAccounting: [],
    reportsCount: 0,
};

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        reportsSetIsLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        reportsSetIsCreatingAction: (state, action: PayloadAction<boolean>) => {
            state.isCreating = action.payload;
        },
        reportsSetReportsListAction: (state, action: PayloadAction<Report[]>) => {
            state.reportsList = action.payload;
        },
        reportsSetCurrentReportAction: (state, action: PayloadAction<Report | null>) => {
            // TODO для оптимизации нужен стейт текущего объекта отчета на странице currentOrder.
            state.currentReport = action.payload;
        },
        reportsSetDocsAction: (state, action: PayloadAction<Doc[]>) => {
            state.docsForAccounting = action.payload;
        },
        reportsAddDocForAccouningAction: (state, action: PayloadAction<Doc>) => {
            state.docsForAccounting.push(action.payload);
        },
        reportsRemoveDocForAccouningAction: (state, action: PayloadAction<Doc>) => {
            const { fileId } = action.payload;
            state.docsForAccounting = state.docsForAccounting
                .filter((doc) => fileId !== doc.fileId);
        },
        reportsSetReportsCountAction: (state, action: PayloadAction<number>) => {
            state.reportsCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        // очищение стора при logoutAction
        builder.addCase(authLogoutAction, () => {
            return initialState;
        });
    },
});

export const {
    reportsSetIsLoadingAction,
    reportsSetReportsListAction,
    reportsSetCurrentReportAction,
    reportsSetDocsAction,
    reportsAddDocForAccouningAction,
    reportsRemoveDocForAccouningAction,
    reportsSetReportsCountAction,
    reportsSetIsCreatingAction,
} = reportsSlice.actions;

export const selectorReportsIsLoading = (state: RootState) => state.reports.isLoading;
export const selectorReportsIsCreating = (state: RootState) => state.reports.isCreating;
export const selectorReportsList = (state: RootState) => state.reports.reportsList;
export const selectorReport = (reportId: string) => {
    // TODO при смене формата id может изменится тип reportId 
    return (state: RootState) => state.reports.reportsList.find((report) => String(report.reportId) === String(reportId));
};
export const selectorCurrentReport = (state: RootState) => state.reports.currentReport;
export const selectorDocsListForAccouning = (state: RootState) => state.reports.docsForAccounting;
export const selectorDocForAccounting = (fileId: string) => {
    return (state: RootState) => state.reports.docsForAccounting.find((doc) => doc.fileId === fileId);
};
export const selectorReportsCount = (state: RootState) => state.reports.reportsCount;

interface ReportsLoadReportThunkProps {
    reportId: string;
    errorCounter?: number;
    onSuccess?: () => {};
    onError?: () => {};
};

export const reportsLoadCurrentReportThunk = (props: ReportsLoadReportThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        reportId,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.REPORTS__LOAD_CURRENT_REPORT;

    // TODO обработка ошибок и проверка на лоадинг
    axios.get(`/api/manager/reports/${reportId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(reportsSetCurrentReportAction(data.body.report));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: reportsLoadCurrentReportThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsLoadCurrentReportThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch((reportsSetCurrentReportAction(null)))
					}
                }
            }))
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
        });
};

interface ReportsAgentLoadReportThunkProps {
    reportId: string;
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: () => void;
};

export const reportsAgentLoadCurrentReportThunk = (props: ReportsAgentLoadReportThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        reportId,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.REPORTS__AGENT_LOAD_CURRENT_REPORT;

    dispatch(reportsSetIsLoadingAction(true));

    // TODO обработка ошибок и проверка на лоадинг
    axios.get(`/api/reports/${reportId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(reportsSetCurrentReportAction(data.body.report));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: reportsAgentLoadCurrentReportThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsAgentLoadCurrentReportThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch((agentsSetCurrentAgentAction(null)))
					}
                }
            }))
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
        });
};

// TODO при расширении формы могут поменятся, поэтому нужно наследоваться от разных интерфесов для форм
export interface ReportsSearchFormActionProps {
    organisationName?: string,
    status?: ReportStatuses;
    currentNotification?: UserRoles;
};

interface ReportsLoadReportsListThunkProps extends ReportsSearchFormActionProps {
    agentId?: string;
    periodId?: string;
    sortBy?: string;
    limit?: number;
    page?: number;
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: (error: string) => void;
};

export const reportsLoadReportsListThunk = (props: ReportsLoadReportsListThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        agentId,
        status,
        organisationName,
        currentNotification,
        periodId,
        sortBy,
        limit = 20,
        page = 1,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;
    
    dispatch(reportsSetIsLoadingAction(true));

    const urlParams = [];

    if (agentId) {
        urlParams.push(`agentId=${agentId}`);
    }

    if (status) {
        urlParams.push(`status=${status}`);
    }

    if (organisationName) {
        urlParams.push(`organisationName=${organisationName}`);
    }

    if (currentNotification) {
        urlParams.push(`currentNotification=${currentNotification}`);
    }

    if (sortBy) {
        urlParams.push(`sortBy=${sortBy}`);
    }

    if (periodId) {
        urlParams.push(`periodId=${periodId}`);
    }

    urlParams.push(`from=${(page - 1) * limit}&limit=${limit}`);

    const url = `/api/manager/reports?${urlParams.join('&')}`;
    const request = RequestFunctionNames.REPORTS__LOAD_REPORTS_LIST;

    axios.get(url)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(reportsSetReportsListAction(data.body.reports));
                dispatch(reportsSetReportsCountAction(data.body.length));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: reportsLoadReportsListThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;
                    
					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}
                    
					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}
                    
					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsLoadReportsListThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch(reportsSetReportsListAction([]));
					}

					if (onError) {
						onError(error);
					}
                }
            }));
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
        });
};

interface ReportsLoadAgentReportsListThunkProps extends ReportsSearchFormActionProps {
    periodId?: string;
    sortBy?: string;
    limit?: number;
    page?: number;
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: (error: string) => void;
};

// thunk загрузки списка актов для агента
export const reportsLoadAgentReportsListThunk = (props: ReportsLoadAgentReportsListThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState());

    // TODO  протестировать прверку isLodaing так как этот thunk не выполняется если переходить из акта в список актов
    // if (isLoading) {
    //     return;
    // }

    const {
        status,
        currentNotification,
        periodId,
        sortBy,
        limit = 20,
        page = 1,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    dispatch(reportsSetIsLoadingAction(true));

    const urlParams = [];

    if (status) {
        urlParams.push(`status=${status}`);
    }

    if (currentNotification) {
        urlParams.push(`currentNotification=${currentNotification}`);
    }

    if (sortBy) {
        urlParams.push(`sortBy=${sortBy}`);
    }

    if (periodId) {
        urlParams.push(`periodId=${periodId}`);
    }

    urlParams.push(`from=${(page - 1) * limit}&limit=${limit}`);

    const url = `/api/reports?${urlParams.join('&')}`;
    const request = RequestFunctionNames.REPORTS__LOAD_AGENT_REPORTS_LIST;

    axios.get(url)
        .then(({ data }) => {
            dispatch(reportsSetReportsListAction(data.body.reports));
            dispatch(reportsSetReportsCountAction(data.body.length));
            dispatch(errorsDeleteActiveRequestErrorsAction(request));

            if (onSuccess) {
                onSuccess();
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: reportsLoadAgentReportsListThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsLoadAgentReportsListThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch(reportsSetReportsListAction([]));
					}

					if (onError) {
						onError(error);
					}
                }
            }));
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
        })
};

export interface ReportsGenerateReportsDocumentsThunkProps {
    periodId: string,
    decade: {
        first: boolean,
        second: boolean,
        third: boolean,
    },
    month: number,
    year: number,
    bankData: Bank,
    excelFileId: string,
    agentInn?: string // необязательное поле, если нужно досоздать агента
    errorCounter?: number;
    onSuccess: ({
        periodId,
        generatedReportsData,
        errorReportsData,
    }: {
        periodId: string;
        generatedReportsData: GeneratedReportDocument[];
        errorReportsData: ErrorReport[],
    }) => void;
    onError?: () => void; 
};

export const ReportsGenerateReportsDocumentsThunk = (props: ReportsGenerateReportsDocumentsThunkProps): AppThunk => (dispatch, getState) => {
    // TODO возможно лучше добавить дополнительный стейт проверки генерации файлов отчетов
    const isLoading = selectorReportsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        periodId,
        decade,
        month,
        year,
        bankData,
        excelFileId,
        agentInn,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;
    
    const request = RequestFunctionNames.REPORTS__GENERATE_REPORTS_DOCUMENTS;
    const periodsList = selectorPeriodsList(getState());

    dispatch(reportsSetIsLoadingAction(true));
    dispatch(reportsSetIsCreatingAction(true));

    axios.post('/api/manager/generateReportsDoc', {
        periodId,
        decade,
        month,
        year,
        bankData,
        excelFileId,
        agentInn,
    })
        .then(({ data }) => {
            if (data.status === 201) {
                const newPeriodsList = periodsList.map((period) => {
                    let newPeriod = { ...period }; // прямая модификация period невозможна

                    if (newPeriod.periodId === periodId) {
                        newPeriod.logs = {
                            success: data.body.reports.generatedReportsData,
                            errors: data.body.reports.errorReportsData,
                        };
                    }

                    return newPeriod;
                });

                dispatch(periodsSetPeriodsListAction(newPeriodsList));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                onSuccess(data.body.reports);
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: ReportsGenerateReportsDocumentsThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(ReportsGenerateReportsDocumentsThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
                        onError(); 
					}
                }
            }))
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
        });
};

export interface ReportsCreateReportsThunkProps {
    periodId: string;
    generatedReportsData: GeneratedReportDocument[];
    errorReportsData: ErrorReport[], // TODO (пока не используется) в дальнейшем должна быть возможность пересоздать акт ещё раз, после добавления в бд
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: (error: string) => void;
};

export const reportsCreateReportsThunk = (props: ReportsCreateReportsThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState()); // TODO другой лоадер, либо добавить в success так как finaly предыдущего этапа выполнится после
    
    // TODO протеститровать проверку на загрузку
    // if (isLoading) {
    //     return;
    // }
    
    const {
        periodId,
        generatedReportsData,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.REPORTS__CREATE_REPORTS;

    dispatch(reportsSetIsLoadingAction(true));

    axios.post('/api/manager/reports', {
        periodId,
        generatedReportsData,
    })
        .then(({ data }) => {
            if (data.status === 201) {
                dispatch(reportsSetReportsListAction(data.body.reports));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
                // TODO объект изменённого состояния должен приходить со стороны сервера, и записывать его надо в другой стейт объекта акта currentReport

                if (onSuccess) {
                    onSuccess();
                } 
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsCreateReportsThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsCreateReportsThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    
                    if (onError) {
                        onError(error);
                    }
                }
            }))
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
            dispatch(reportsSetIsCreatingAction(false));
        });
};

export interface ReportsUpdateReportsThunkProps {
    periodId: string;
    generatedReportsData: GeneratedReportDocument[];
    errorReportsData: ErrorReport[], // TODO (пока не используется) в дальнейшем должна быть возможность пересоздать акт ещё раз, после добавления в бд
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: (error: string) => void;
};

export const reportsUpdateReportsThunk = (props: ReportsUpdateReportsThunkProps): AppThunk => (dispatch, getState) => {
    const {
        periodId,
        generatedReportsData,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.REPORTS__UPDATE_REPORTS;

    axios.put('/api/manager/reports', {
        periodId,
        generatedReportsData,
    })
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(reportsSetReportsListAction(data.body.reports));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsUpdateReportsThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsUpdateReportsThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    if (onError) {
                        onError(error);
                    }
                },
            }));
        });
};

interface ReportsSendStatusThunkProps {
    reportId: string;
    reportStatus: ReportStatuses;
    errorCounter?: number;
};

export const reportsSendStatusThunk = (props: ReportsSendStatusThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        reportId,
        reportStatus,
        errorCounter = 0,
    } = props;

    const request = RequestFunctionNames.REPORTS__SEND_STATUS;
    
    dispatch(reportsSetIsLoadingAction(true));

    axios.put(`/api/manager/reports/${reportId}`, {
        reportStatus,
    })
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(reportsSetCurrentReportAction(data.body.report));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
                // dispatch(reportsLoadCurrentReportThunk({ reportId }));
                // dispatch(reportsLoadReportsListThunk({}))
                // TODO объект изменённого состояния должен приходить со стороны сервера, и записывать его надо в другой стейт объекта акта currentReport
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsSendStatusThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsSendStatusThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));
                },
            }))
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
        })
};

interface ReportsAgentSendStatusThunkProps {
    reportId: string;
    errorCounter?: number;
};

export const reportsAgentSendStatusThunk = (props: ReportsAgentSendStatusThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState());
    
    if (isLoading) {
        return;
    }

    const {
        reportId,
        errorCounter = 0,
    } = props;

    const request = RequestFunctionNames.REPORTS__AGENT_SEND_STATUS;

    dispatch(reportsSetIsLoadingAction(true));

    axios.put(`/api/reports/${reportId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                // TODO переделать на изменение именно состояния 
                dispatch(reportsSetIsLoadingAction(false));
                dispatch(reportsSetCurrentReportAction(data.body.report));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
                // dispatch(reportsAgentLoadCurrentReportThunk({ reportId }))
                // dispatch(reportsLoadReportsListThunk({}))
                // TODO объект изменённого состояния должен приходить со стороны сервера, и записывать его надо в другой стейт объекта акта currentReport
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsAgentSendStatusThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsAgentSendStatusThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));
                },
            }))
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
        });
};

interface ReportsSendFlieStatusThunkProps {
    reportId: string;
    fileIds: string[];
    docStatus: FileStatuses;
    errorCounter?: number;
};

// TODO возможно стоит разделить на два эндпоинта для main и agent
export const reportsSendFileStatusThunk = (props: ReportsSendFlieStatusThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        reportId,
        fileIds,
        docStatus,
        errorCounter = 0,
    } = props;

    const request = RequestFunctionNames.REPORTS__SEND_FILE_STATUS;

    dispatch(reportsSetIsLoadingAction(true));

    axios.put(`/api/manager/reports/${reportId}`, {
        fileIds,
        docStatus,
    })
        .then(({ data }) => {
            if (data.status === 200) {
                // TODO должено обновлятся только нужный отчет, поэтому нужен другой thunk
                // TODO объект изменённого состояния должен приходить со стороны сервера, и записывать его надо в другой стейт объекта акта currentReport

                // dispatch(reportsLoadReportsListThunk({}))
                // dispatch(reportsLoadCurrentReportThunk({ reportId }))
                dispatch(reportsSetCurrentReportAction(data.body.report));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsSendFileStatusThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsSendFileStatusThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));
                },
            }))
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
        });
};

interface ReportsManagerSendMessageThunkProps {
    reportId: string;
    message: string;
    errorCounter?: number;
}

// отправка сообщения для менеджера
export const reportsManagerSendMessageThunk = (props: ReportsManagerSendMessageThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        reportId,
        message,
        errorCounter = 0,
    } = props;

    const request = RequestFunctionNames.REPORTS__MANAGER_SEND_MESSAGE;

    dispatch(reportsSetIsLoadingAction(true));

    axios.put(`/api/manager/reports/${reportId}/sendMessage`, {
        message,
    })
        .then(({ data }) => {
            if (data.status === 200) {
                // TODO должено обновлятся только нужный отчет, поэтому нужен другой thunk
                // TODO объект изменённого состояния должен приходить со стороны сервера, и записывать его надо в другой стейт объекта акта currentReport
                // dispatch(reportsLoadCurrentReportThunk({ reportId }));
                dispatch(reportsSetCurrentReportAction(data.body.report));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsManagerSendMessageThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsManagerSendMessageThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));
                },
            }))
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
        });
};

interface ReportsSendMessageThunkProps {
    reportId: string;
    message: string;
    errorCounter?: number;
}

// отправка сообщения для агента
export const reportsSendMessageThunk = (props: ReportsSendMessageThunkProps): AppThunk => (dispatch, getState) => {
    // const isLoading = selectorReportsIsLoading(getState());

    // if (isLoading) {
    //     return;
    // }

    const {
        reportId,
        message,
        errorCounter = 0,
    } = props;

    // dispatch(reportsSetIsLoadingAction(true));

    const request = RequestFunctionNames.REPORTS__SEND_MESSAGE;

    axios.put(`/api/reports/${reportId}/sendMessage`, {
        message,
    })
        .then(({ data }) => {
            if (data.status === 200) {
                // dispatch(reportsSetIsLoadingAction(false));
                dispatch(reportsSetCurrentReportAction(data.body.report));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsSendMessageThunk,
                onError: () => {
                    // dispatch(reportsSetIsLoadingAction(false));
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsSendMessageThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));
                },
            }))
        })
        .finally(() => {
            // dispatch(reportsSetIsLoadingAction(false));
        });
};

interface ReportsUploadFileProps {
    files: UploadedFile[];
    agentInn: string; // TODO рассмотреть вариант поиска агента на стороне сервера
    reportId: string;
    errorCounter?: number;
	onSuccess: (fileIds: string[]) => void;
    onError?: () => {}; 
}

export const reportsUploadFileThunk = (props: ReportsUploadFileProps): AppThunk => (dispatch, getState) => {
    // const isLoading = selectorReportsIsLoading(getState());

    // if (isLoading) {
    //     return;
    // }

    const {
        files,
        agentInn,
        reportId,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    // dispatch(reportsSetIsLoadingAction(true));

    const request = RequestFunctionNames.REPORTS__UPLOAD_FILE;
    
    axios.post(`/api/report/fileUpload`, {
        files,
        agentInn,
        reportId,
    })
        .then(({ data }) => {
            if (data.status === 201) {
                // thunk вызывается по цепочке и обновление isLoading перенесено в then и catch
                dispatch(reportsSetIsLoadingAction(false));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

				onSuccess(data.body.fileIds);
			}
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsUploadFileThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;
                    
					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsUploadFileThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    dispatch(reportsSetIsLoadingAction(false));
                },
            }));
        });
};

interface ReportsCreateMainFileThunkProps {
    reportId: string;
	fileIds: string[];
    docType: DocTypes;
    errorCounter?: number;
	onSuccess? : () => void;
	onError? : () => void;
}

export const reportsCreateMainFileThunk = (props: ReportsCreateMainFileThunkProps): AppThunk => (dispatch, getState) => {
    const {
        reportId,
        fileIds,
        errorCounter = 0,
        // docType, // TODO должен также отправлятся тип загружаемого документа
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.REPORTS__CREATE_MAIN_FILE;

    axios.post(`/api/manager/reports/${reportId}/fileUpload`, {
        fileIds,
        // docType,
    })
        .then(({ data }) => {
            if (data.status === 201) {
                // TODO проработать вопрос обязательности onSuccess
                dispatch(reportsLoadCurrentReportThunk({ reportId }));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

				if (onSuccess) {
					onSuccess();
				}
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsCreateMainFileThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsCreateMainFileThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    if (onError) {
						onError();
					}
                }
            }))

			return;
        })
};

interface ReportsCreateAgentFileThunkProps {
    reportId: string;
	fileIds: string[];
    docType: DocTypes;
    errorCounter?: number;
	onSuccess? : () => void;
	onError? : () => void;
};

export const reportsCreateAgentFileThunk = (props: ReportsCreateAgentFileThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        reportId,
        fileIds,
        errorCounter = 0,
        // docType,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.REPORTS__CREATE_AGENT_FILE;

    dispatch(reportsSetIsLoadingAction(true));

    axios.post(`/api/reports/${reportId}/fileUpload`, {
        fileIds,
        // docType, // TODO должен также отправлятся тип загружаемого документа
    })
        .then(({ data }) => {
            if (data.status === 201) {
                // TODO проработать вопрос обязательности onSuccess
                // thunk вызывается по цепочке и обновление isLoading перенесено в then и catch
                dispatch(reportsSetIsLoadingAction(false));
                dispatch(reportsSetCurrentReportAction(data.body.report));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
					onSuccess();
				}
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsCreateAgentFileThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsCreateAgentFileThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}
                    
                    dispatch(reportsSetIsLoadingAction(false));
					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
						onError();
					}
                },
            }));

			return;
        });
};

export interface ReportsEmailFormActionProps {
    targetMail: string;
};

interface ReportsSendDocsForAccountingThunkProps extends ReportsEmailFormActionProps {
    docs: Doc[];
    managerFio: string;
    managerMail: string;
    agentInn: string;
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: (error: string) => void;
};

// отправка документов на почту
export const reportsSendDocsForAccountingThunk = (props: ReportsSendDocsForAccountingThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState()); // TODO другой лоадер, либо добавить в success так как finaly предыдущего этапа выполнится после

    if (isLoading) {
        return;
    }

    dispatch(reportsSetIsLoadingAction(true));

    const {
        docs,
        targetMail,
        managerFio,
        managerMail,
        agentInn,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.REPORTS__SEND_DOCS_FOR_ACCOUNTING;

    axios.post('/api/manager/sendMail', {
        docs,
        targetMail,
        managerFio,
        managerMail,
        agentInn,
    })
        .then(({ data }) => {
            const { docs } = data.body;

            if (data.status === 200 && Array.isArray(docs)) {
                dispatch(reportsSetDocsAction([]));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: reportsSendDocsForAccountingThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsSendDocsForAccountingThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));
                }
            }))
        })
        .finally(() => {            
            dispatch(reportsSetIsLoadingAction(false));
        });
};

// TODO в дальнейшем данный thunk можно переделать на чекбоксы для отдельных отчётов
interface ReportsFilesStatusUpdateThunkProps {
    periodId: string;
    status: FileStatuses;
    errorCounter?: number
    onSuccess?: () => {}
    onError?: () => {}
};

export const reportsFilesStatusUpdateThunk = (props: ReportsFilesStatusUpdateThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorReportsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        periodId,
        status,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.REPORTS__FILES_STATUS_UPDATE;

    dispatch(reportsSetIsLoadingAction(true));

    axios.put(`/api/manager/periods/${periodId}/filesStatus`, {
        status,
    })
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(reportsSetReportsListAction(data.body.reports));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: reportsFilesStatusUpdateThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(reportsFilesStatusUpdateThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
						onError();
					}
                },
            }));
        })
        .finally(() => {
            dispatch(reportsSetIsLoadingAction(false));
        });
};

export default reportsSlice.reducer;