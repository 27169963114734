import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Paginator from '../../../../components/paginator/Paginator';
import { reportsLoadReportsListThunk, ReportsSearchFormActionProps, REPORTS_SCALE, selectorReportsCount, selectorReportsIsLoading, selectorReportsList } from '../../../../store/reports/reportsSlice';
import ReportsSearchForm from '../../components/search-form/ReportsSearchForm';
import ReportsListItem from './item/ReportsListItem';
import styles from './ReportsListPage.module.scss';

// TODO интерфейс может расширятся в дальнейшем
interface TableSort {
    field: string;
    sort: number;
};

// TODO такая зависимость от agentId обусловленна
interface ReportsListPageProps {
    agentId?: string;
}

const ReportsListPage = ({
    agentId
}: ReportsListPageProps) => {
    const dispatch = useAppDispatch();

    const reports = useAppSelector(selectorReportsList);
    const reportsCount = useAppSelector(selectorReportsCount);
    const isLoading = useAppSelector(selectorReportsIsLoading);

    const [page, setPage] = useState<number>(1);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [tableSort, setTableSort] = useState<TableSort | null>(null);
    const [filter, setFilter] = useState<ReportsSearchFormActionProps>({});

    const handlePaginatorClick = (page: number, pageIndex: number) => {
        setPage(page);
        setPageIndex(pageIndex);
        
        dispatch(reportsLoadReportsListThunk({
            agentId,
            organisationName: filter?.organisationName,
            status: filter?.status,
            currentNotification: filter?.currentNotification,
            page,
            sortBy: tableSort ? JSON.stringify(tableSort): '',
            limit: REPORTS_SCALE,
        }));
    };

    const handleSearchReportsFormSubmit = ({
        organisationName,
        status,
        currentNotification,
    }: ReportsSearchFormActionProps) => {
        if (status) {
            filter.status = status;
        } else {
            // удаляется, чтобы не запоминалось в стейте в случае передачи пустого поля
            delete filter.status;
        }

        if (currentNotification) {
            filter.currentNotification = currentNotification;
        } else {
            // удаляется, чтобы не запоминалось в стейте в случае передачи пустого поля
            delete filter.currentNotification;
        }

        if (organisationName) {
            filter.organisationName = organisationName;
        } else {
            // удаляется, чтобы не запоминалось в стейте в случае передачи пустого поля
            delete filter.organisationName;
        }

        setFilter(filter);
        
        dispatch(reportsLoadReportsListThunk({
            agentId,
            status,
            organisationName: filter?.organisationName,
            currentNotification,
            page: 1, // поиск начинается всегда с первой страницы
            sortBy: tableSort ? JSON.stringify(tableSort): '',
            limit: REPORTS_SCALE,
            onSuccess: () => {
                setPage(1);
                setPageIndex(1);
            },
        }));
    };

    const handleTableSort = (tableSort: TableSort) => {
        setTableSort(tableSort);
        
        dispatch(reportsLoadReportsListThunk({
            agentId,
            status: filter?.status,
            organisationName: filter?.organisationName,
            currentNotification: filter?.currentNotification,
            page,
            sortBy: JSON.stringify(tableSort),
            limit: REPORTS_SCALE,
        }));
    };

    return(
        <>
            <div className={styles.block}>
                <ReportsSearchForm
                    onSubmit={handleSearchReportsFormSubmit}
                />
            </div>
            {!!reports.length && (
                <div className={styles.block}>
                    {reportsCount > REPORTS_SCALE && (
                        <Paginator
                            parentPage={page}
                            parentPageIndex={pageIndex}
                            buttonsCount={5}
                            items={reportsCount}
                            scale={REPORTS_SCALE}
                            onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                        />
                    )}
                    {!isLoading && (
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>№</th>
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'createdTimestamp',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >Дата создания {tableSort?.sort && tableSort?.field === 'createdTimestamp' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'status',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >Cтатус {tableSort?.sort && tableSort?.field === 'status' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                    <th>Период</th>
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'agent.organisationName',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >Агент</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {reports.map((report, index) => (
                                    <ReportsListItem
                                        key={report.reportId}
                                        report={report}
                                        index={index + (page - 1) * REPORTS_SCALE}
                                    />
                                ))}
                            </tbody>
                        </table>
                    )}
                    {reportsCount > REPORTS_SCALE && (
                        <Paginator
                            parentPage={page}
                            parentPageIndex={pageIndex}
                            buttonsCount={5}
                            items={reportsCount}
                            scale={REPORTS_SCALE}
                            onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                        />
                    )}
                    {isLoading && (
                        // TODO сделать лоадер
                        <h2>ЗАГРУЗКА...</h2>
                    )}
                </div>
            )}
            {!reports.length && (
                <div className={styles.block}>
                    <b>Акты не найдены</b>
                </div>
            )}
        </>
    );
};

export default ReportsListPage;