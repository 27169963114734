import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import LoginPage from './pages/login/LoginPage';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
	Routes,
	Route,
	NavLink,
    useNavigate,
    useLocation,
} from 'react-router-dom';
import { authLogoutAction, authUpdateTokenThunk, selectorAuthIsAuthorized } from './store/auth/authSlice';
import { selectorUserProfile, UserRoles, usersLoadUserProfileThunk } from './store/users/usersSlice';
import UsersPage from './pages/users/UsersPage';
import UsersListPage from './pages/users/pages/list/UsersListPage';
import styles from './App.module.scss';
import ReportsPage from './pages/reports/ReportsPage';
import ReportsListPage from './pages/reports/pages/list/ReportsListPage';
import PeriodsPage from './pages/periods/PeriodsPage';
import PeriodsListPage from './pages/periods/pages/list/PeriodsListPage';
import BanksListPage from './pages/banks/pages/list/BanksListPage';
import BanksPage from './pages/banks/BanksPage';
import BankInfoPage from './pages/banks/pages/bank-info/BankInfoPage';
import PeriodInfoPage from './pages/periods/pages/period-info/PeriodInfoPage';
import ReportInfoPage from './pages/reports/pages/report-info/ReportInfoPage';
import AgentsPage from './pages/agents/AgentsPage';
import AgentsListPage from './pages/agents/pages/list/AgentsListPage';
import AgentInfoPage from './pages/agents/pages/agent-info/AgentInfoPage';
import AgentReportsPage from './pages/agent-reports/AgentReportsPage';
import AgentReportsListPage from './pages/agent-reports/list/AgentReportsListPage';
import AgentReportInfoPage from './pages/agent-reports/report-info/AgentReportInfoPage';
import UserInfoPage from './pages/users/pages/user-info/UserInfoPage';
import UserProfilePage from './pages/users/pages/profile/UserProfilePage';
import ProtectedComponent from './components/protected-component/ProtectedComponent';
import ProtectedRoute from './components/protected-route/ProtectedRoute';
import FaqPage from './pages/faq/FaqPage';
import ErrorModal from './components/modal/ErrorModal';

const App = () => {
	const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

	const isAuthorized = useAppSelector(selectorAuthIsAuthorized);
	const profile = useAppSelector(selectorUserProfile);

    const [isOpenedMenu, setIsOpenedMenu] = useState<boolean>(false);

    useEffect(() => {
        setIsOpenedMenu(false);
    }, [location]);

	useEffect(() => {
		dispatch(authUpdateTokenThunk({}));
	}, []);

	useEffect(() => {
		if (isAuthorized) {
			dispatch(usersLoadUserProfileThunk({}));
		}
	}, [isAuthorized]);

	const handleLogoutClick = () => {
        navigate('/', { replace: true });
        dispatch(authLogoutAction());
    };

	if (!isAuthorized) {
		return (
			<Routes>
				<Route path='/*' element={
					<ProtectedRoute allowedRoles={[]}>
						<LoginPage />
					</ProtectedRoute>
				} />
			</Routes>
			// TODO сделать лоадер
		);
	}
	
    // TODO переделать на один приватный роут для нескольких вложенных роутов (возможно разделить на компоненты <AdminUsersRoute />)
	return (
        <div className={styles.wrapper}>
            <ErrorModal />
            {/* TODO вынести в отдельный компонент */}
            <div className={cn(
                styles.menu, {
                    [styles.menuOpened]: isOpenedMenu,
                }
            )}>
                <div className={styles.menuLinks}>
                    <ProtectedComponent allowedRoles={[]}>
                        <NavLink to ="/profile">Профиль</NavLink>
                    </ProtectedComponent>
                    <ProtectedComponent allowedRoles={[UserRoles.ADMIN]}>
                        <NavLink to="/admin/users">Пользователи</NavLink>
                    </ProtectedComponent>
                    <ProtectedComponent allowedRoles={[UserRoles.MANAGER]}>
                        <NavLink to="/manager/banks">Банки</NavLink>
                        <NavLink to="/manager/periods">Отчетные периоды</NavLink>
                        <NavLink to="/manager/reports">Акты</NavLink>
                        <NavLink to="/manager/agents">Агенты</NavLink>
                    </ProtectedComponent>
                    <ProtectedComponent allowedRoles={[UserRoles.AGENT]}>
                        <NavLink to ="/reports">Агентские акты</NavLink>
                    </ProtectedComponent>
                    <ProtectedComponent allowedRoles={[]}>
                        <NavLink to ="/faq">FAQ</NavLink>
                    </ProtectedComponent>
                </div>
                <button
                    className={styles.button}
                    onClick={handleLogoutClick}
                >Выход</button>
            </div>
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <div className={styles.contentHeaderProfile}>
                        {/* TODO вынести хедер в другой компонент и добавить инфу о пользователе */}
                        <div>Пользователь: <b>{profile?.login}</b></div>
                    </div>
                    <div
                        className={cn(
                            styles.menuBurger, {
                                [styles.menuBurgerOpened]: isOpenedMenu,
                        })}
                        onClick={() => setIsOpenedMenu(prev => !prev)}
                    >
                        <div></div>
                    </div>
                </div>
                <div className={styles.contentWrapper}>
                    <Routes>
                        {/* TODO добавить страницу по умолчанию '/' */}
                        <Route path="/manager/banks"
                            element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <BanksPage />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <BanksListPage />
                                </ProtectedRoute>
                            } />
                            <Route path="list" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <BanksListPage />
                                </ProtectedRoute>
                            } />
                            <Route path=":bankId" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <BankInfoPage />
                                </ProtectedRoute>
                            } />
                        </Route>

                        <Route path="/manager/periods"
                            element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <PeriodsPage />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <PeriodsListPage />
                                </ProtectedRoute>
                            } />
                            <Route path="list" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <PeriodsListPage />
                                </ProtectedRoute>
                            } />
                            <Route path=":periodId" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <PeriodInfoPage />
                                </ProtectedRoute>
                            } />
                        </Route>

                        <Route path="/manager/reports"
                            element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <ReportsPage />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <ReportsListPage />
                                </ProtectedRoute>
                            } />
                            <Route path="list" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <ReportsListPage />
                                </ProtectedRoute>
                            } />
                            <Route path=":reportId" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <ReportInfoPage />
                                </ProtectedRoute>
                            } />
                        </Route>

                        <Route path="/manager/agents"
                            element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <AgentsPage />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <AgentsListPage />
                                </ProtectedRoute>
                            } />
                            <Route path="list" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <AgentsListPage />
                                </ProtectedRoute>
                            } />
                            <Route path=":agentId" element={
                                <ProtectedRoute allowedRoles={[UserRoles.MANAGER]}>
                                    <AgentInfoPage />
                                </ProtectedRoute>
                            } />
                        </Route>

                        <Route path="/admin/users"
                            element={
                                <ProtectedRoute allowedRoles={[UserRoles.ADMIN]}>
                                    <UsersPage />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="" element={
                                <ProtectedRoute allowedRoles={[UserRoles.ADMIN]}>
                                    <UsersListPage />
                                </ProtectedRoute>
                            } />
                            <Route path="list" element={
                                <ProtectedRoute allowedRoles={[UserRoles.ADMIN]}>
                                    <UsersListPage />
                                </ProtectedRoute>
                            } />
                            <Route path=":userId" element={
                                <ProtectedRoute allowedRoles={[UserRoles.ADMIN]}>
                                    <UserInfoPage />
                                </ProtectedRoute>
                            } />
                        </Route>

                        <Route path="/reports"
                            element={
                                <ProtectedRoute allowedRoles={[UserRoles.AGENT]}>
                                    <AgentReportsPage />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="" element={
                                <ProtectedRoute allowedRoles={[UserRoles.AGENT]}>
                                    <AgentReportsListPage />
                                </ProtectedRoute>
                            } />
                            <Route path="list" element={
                                <ProtectedRoute allowedRoles={[UserRoles.AGENT]}>
                                    <AgentReportsListPage />
                                </ProtectedRoute>
                            } />
                            <Route path=":reportId" element={
                                <ProtectedRoute allowedRoles={[UserRoles.AGENT]}>
                                    <AgentReportInfoPage />
                                </ProtectedRoute>
                            } />
                        </Route>

                        <Route path="/profile"
                            element={
                                <ProtectedRoute allowedRoles={[]}>
                                    <UserProfilePage />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="" element={
                                <ProtectedRoute allowedRoles={[]}>
                                    <UserProfilePage />
                                </ProtectedRoute>
                            } />
                        </Route>

                        <Route path="/faq" element={
                            <ProtectedRoute allowedRoles={[]}>
                                <FaqPage />
                            </ProtectedRoute>
                        }>
                        </Route>
                    </Routes>
                </div>
            </div>
        </div>
	);
};

export default App;
