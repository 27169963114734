import React, { useRef, useState } from 'react';
import { ReactComponent as ArrowIcon } from './images/ArrowIcon.svg';
import styles from './Accordion.module.scss';
import cn from 'classnames';

interface AccordionProps {
    title: string;
    children: any;
};

const Accordion = ({
    title,
    children,
}: AccordionProps) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const handleOpenAccordion = () => {
        if (!isOpened) {
            contentRef.current!.style.maxHeight = contentRef.current?.scrollHeight + 'px';
            setIsOpened(true);
        } else {
            contentRef.current!.style.maxHeight = 0 + 'px';
            setIsOpened(false);
        }
    }

    return (
        <div
            className={styles.accordion}
            onClick={handleOpenAccordion}
        >
            <div className={styles.accordionTitle}>
                <span className={styles.accordionTitleText}>{title}</span>
                <div className={cn([styles.accordionTitleIcon], {
                    [styles.accordionTitleIconActive]: isOpened,
                })}>
                    <ArrowIcon />
                </div>
            </div>
            <div 
                className={styles.accordionContent}
                ref={contentRef}
            >   
                <div className={styles.accordionContentWrapper}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Accordion;