import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { checkError, translateError } from '../../helpers/helpers';
import { errorsDeleteErrorsAction, selectorActiveErrors } from '../../store/errors/errorsSlice';
import Icons, { IconsType } from '../icons/Icons';
import styles from './Modal.module.scss';
import cn from 'classnames';

const ErrorModal = () => {
    const dispatch = useAppDispatch();
    
    const activeErrors = useAppSelector(selectorActiveErrors);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    //отлов ошибок
    const isErrorNetwork = checkError(activeErrors, null);
    const isError500 = checkError(activeErrors, 500);
    const isError504 = checkError(activeErrors, 504);
    const isError400 = checkError(activeErrors, 400);
    const isError415 = checkError(activeErrors, 415);
    // const isError404 = checkError(activeErrors, 404);

    useEffect(() => {
        if (!isErrorNetwork
            && !isError500
            && !isError504
            && !isError400
            && !isError415
            // && !isError404
        ) {
            setIsOpened(false);
        } else {
            setIsOpened(true);
        }
    },[activeErrors]);

    const handleCloseClick = () => {
        setIsOpened(false);
        dispatch(errorsDeleteErrorsAction());
        // TODO не все ошибки нужны в модалке
    };

    return (
        <>
            {isOpened && (
                <div className={cn(styles.modalWrapper, styles.modalWrapperError)}>
                    <div className={styles.modalBackground}></div>
                    <div className={styles.modalWindow}>
                        <div className={styles.modalContent}>
                            <div className={styles.modalContentWrapper}>
                                {activeErrors.map((error, index) => (
                                    <div
                                        key={index}
                                        className={styles.errorModal}
                                    >
                                        <div className={styles.errorModalTitle}>
                                            <Icons type={IconsType.alertRed} />
                                            <h2>Ошибка! {!!error.status && (`Код: ${error.status}`)}</h2>
                                        </div>
                                        <p>{translateError(error.type)}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div
                            className={styles.modalClose}
                            onClick={handleCloseClick}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default ErrorModal;