import React from 'react';
import { LocalStorageValues, authLogoutAction } from '../../store/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { UserRoles } from '../../store/users/usersSlice';
import { Navigate, useNavigate } from 'react-router-dom';

interface ProtectedRouteProps {
    allowedRoles: UserRoles[];
    children: any;
};

const ProtectedRoute = ({
    allowedRoles,
    children,
}: ProtectedRouteProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const authUserRoles = localStorage.getItem(LocalStorageValues.ROLES)?.split(',') || [];

    const isAllowedVisit = allowedRoles.some((role: UserRoles) => authUserRoles.includes(role));

    if (!allowedRoles.length) {
        return <>{children}</>;
    }

    if (!isAllowedVisit) {
        dispatch(authLogoutAction());
    };

    return (
        <>
            {isAllowedVisit || !allowedRoles.length ? (
                <>{children}</>
            ) : (
                <Navigate to='/'/> // TODO редирект на форму логина при переходе на недоступную для пользователя с данной ролью ссылку
            )}
        </>
    );
};

export default ProtectedRoute;