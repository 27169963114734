import React from 'react';
import { NavLink } from 'react-router-dom';
import { Bank } from '../../../../../store/banks/banksSlice';
import styles from './BanksListItem.module.scss';

interface BanksListItemProps {
    bank: Bank;
    index: number;
};

const BanksListItem = ({
    bank,
    index,
}: BanksListItemProps) => {
    const {
        bankId,
        name,
        aliasName,
        bic,
        organisation,
    } = bank;

    return (
        <tr className={styles.tr}>
            <td>{index + 1}</td>
            <td><b>{name}</b></td>
            <td>{aliasName}</td>
            <td>{bic}</td>
            <td>{organisation.name}</td>
            <td>{organisation.inn}</td>
            <td>{organisation.kpp}</td>
            <td><NavLink to={`/manager/banks/${bankId}`}>Подробнее</NavLink></td>
        </tr>
    );
};

export default BanksListItem;