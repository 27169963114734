import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ReportsEmailFormActionProps } from '../../../../store/reports/reportsSlice';
import styles from './ReportsEmailForm.module.scss';

interface ReportsEmailFormProps {
    onSubmit: (formData: ReportsEmailFormActionProps) => void;
};

const ReportsEmailForm = ({
    onSubmit,
}: ReportsEmailFormProps) => {
    const [isInput, setIsInput] = useState<boolean>(false);
    const [input, setInput] = useState<string>('');

    const formik = useFormik({
        initialValues: {
            targetMail: '',
        },
        onSubmit: (props: ReportsEmailFormActionProps) => {
            if (!!input) {
                formik.values.targetMail = input;
            }
            
            onSubmit(props);
        },
    });

    useEffect(() => {
        if (!formik.values.targetMail) {
            setIsInput(true);
        } else {
            setInput('');
            setIsInput(false);
        }
    }, [formik.values]);

    return (
        <div>
            <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
            >
                <div className={styles.controls}>
                    <select
                        name="targetMail"
                        value={formik.values.targetMail}
                        onChange={formik.handleChange}
                        className={styles.select}
                        required={true}
                    >
                        {/* TODO должно приходить с сервера */}
                        <option value="kuzina_sa@srvhub.ru">kuzina_sa@srvhub.ru</option>
                        <option value="bugrova_oa@srvhub.ru">bugrova_oa@srvhub.ru</option>
                        <option value="">Другое</option>
                    </select>
                    {isInput && (
                        <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Введите email"
                            className={styles.input}
                            required={true}
                        />
                    )}
                </div>
                <div className={styles.controls}>
                    <button
                        className={styles.button}
                        type="submit"
                    >
                        Отправить
                    </button>
                </div>
            </form>
        </div>
    )
};

export default ReportsEmailForm;