import React from 'react';
import cn from 'classnames';
import styles from './Modal.module.scss';

interface ModalProps {
    isOpened: boolean;
    className?: string;
    children?: any; // Заглушка чтобы TS не ругался
    onClose: () => void;
}

const Modal = ({
    isOpened,
    className,
    children,
    onClose,
}: ModalProps) => {
    if (!isOpened) {
        return null;
    }

    return (
        <div className={styles.modalWrapper}>
            <div className={styles.modalBackground}></div>
            <div className={cn(styles.modalWindow, className)}>
                <div className={styles.modalContent}>
                    <div className={styles.modalContentWrapper}>
                        {children}
                    </div>
                </div>
                <div className={styles.modalClose} onClick={onClose} />
            </div>
        </div>
    );
}

export default Modal;