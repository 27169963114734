import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { reportsLoadAgentReportsListThunk } from '../../store/reports/reportsSlice';
import styles from './AgentReports.module.scss';

const AgentReportsPage = () =>{
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(reportsLoadAgentReportsListThunk({
            limit: 20,
        }));
    }, []);
    
    return(
    <div className={styles.wrapper}>
        <h1>Агентские акты</h1>
        <Outlet />
    </div>
   );
};

export default AgentReportsPage;