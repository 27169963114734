import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { UploadedFile } from '../../store/reports/reportsSlice';
import styles from './FileLoader.module.scss';

export interface FileLoaderRef {
    clear: () => void;
};

interface FileLoaderProps {
    multiply?: boolean;
    limit?: number;
    type?: string;
    onLoadFile: ([]: UploadedFile[]) => void;
};

const FileLoader = ({
    multiply = false,
    limit = 10,
    type,
    onLoadFile,
}: FileLoaderProps, ref?: Ref<FileLoaderRef>) => {
    const [inputFiles, setInputFiles] = useState<FileList | null>(null);
    const input = useRef<any>();

    // вызов функции из родителя
    useImperativeHandle(ref, () => ({ clear }));

    useEffect(() => {
        if (!!inputFiles) {
            upload(inputFiles);
        }
    }, [inputFiles]);

    const clear = () => {
        input.current.value = null;            
    };

    const upload = async (files: FileList) => {
        const promises = Object.values(files).map((file) => {
            const reader = new FileReader();

            return new Promise<UploadedFile>((res, rej) => {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    res({
                        fileName: file.name || '',
                        fileString: reader.result,
                    });
                };
            })
        });

        const result = await Promise.all(promises);

        if (!!result.length) {
            onLoadFile(result);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            if (e.currentTarget.files.length > limit) {
                alert(`Одновременно можно загрузить ${limit} файлов`);

                return;
            }

            setInputFiles(e.currentTarget.files);
        }
    };

    return (
        <label className={styles.fileLoader}>
            <input
                ref={input}
                className={styles.fileLoaderInput}
                name="load-file"
                type="file"
                multiple={multiply}
                accept={type ? type : ".doc,.docx,.xlsx"}
                placeholder="Файл"
                required={true}
                onChange={handleChange}
            />
            <div className={styles.button}>
                {multiply && (<span>Выберите файлы</span>)}
                {!multiply && (<span>Выберите файл</span>)}
            </div>
            <div className={styles.fileLoaderFiles}>
                {!!inputFiles && Object.values(inputFiles).map((file, index) => (
                    <span key={index}>{file.name} </span>
                ))}
                {!inputFiles?.length && (
                    <>
                        {multiply && (<span>Файлы не выбраны</span>)}
                        {!multiply && (<span>Файл не выбран</span>)}
                    </>
                )}
            </div>
        </label>
    )
};

export default forwardRef(FileLoader);