import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { banksLoadBanksListThunk } from '../../store/banks/banksSlice';
import styles from './BanksPage.module.scss';

const BanksPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(banksLoadBanksListThunk({
            limit: 20,
        }));
    }, []);

    return (
        <div className={styles.wrapper}>
            <h1>Банки</h1>
            <Outlet />
        </div>
    );
};

export default BanksPage;