import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectorUserProfile, usersLoadUserProfileThunk } from '../../../../store/users/usersSlice';
import styles from './UserProfilePage.module.scss';

const UserProfilePage = () => {
    const dispatch = useAppDispatch();

    const userProfile = useAppSelector(selectorUserProfile);

    useEffect(() => {
        dispatch(usersLoadUserProfileThunk({}));
    }, []);

    return (
        <div className={styles.wrapper}>
            <h1>Профиль</h1>
            {userProfile && (
                <div className={styles.profile}>
                    <div className={styles.block}>
                        <h2>Пользовательские данные</h2>
                        <div className={styles.blockList}>
                            <div><b>Логин:</b> {userProfile.login}</div>
                            <div><b>ФИО:</b> {userProfile.userInfo?.lastName} {userProfile.userInfo?.firstName} {userProfile.userInfo?.middleName}</div>
                            <div><b>Телефон:</b> {userProfile.userInfo?.phone}</div>
                            <div><b>Email:</b> {userProfile.userInfo?.email}</div>
                            <div>
                                <b>Роли:</b>
                                {userProfile.roles.map((role, index) => (
                                    <div key={index}>
                                        {role}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {userProfile.agentInfo && (
                        <div className={styles.block}>
                            <h2>Агентские данные</h2>
                            <div className={styles.blockList}>
                                <div><b>Название организации:</b> {userProfile.agentInfo.organisationName}</div>
                                <div><b>ИНН:</b> {userProfile.agentInfo.inn}</div>
                                <div><b>КПП:</b> {userProfile.agentInfo.kpp}</div>
                                <div><b>ФИО руководителя:</b> {userProfile.agentInfo.headFirstName} {userProfile.agentInfo.headLastName} {userProfile.agentInfo.headMiddleName}</div>
                                <div><b>Должность:</b> {userProfile.agentInfo.headPost}</div>
                                <div><b>Реквизиты</b></div>
                                <div><b>Банк:</b> {userProfile.agentInfo.requisites.bank}</div>
                                <div><b>БИК:</b> {userProfile.agentInfo.requisites.bic}</div>
                                <div><b>Расчетный счёт:</b> {userProfile.agentInfo.requisites.paymentAccount}</div>
                                <div><b>Корреспондентский счёт:</b> {userProfile.agentInfo.requisites.correspondentAccount}</div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
};

export default UserProfilePage;