import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { PeriodsSearchFormActionProps, periodsLoadPeriodsListThunk, selectorPeriodsCount, selectorPeriodsIsLoading, selectorPeriodsList, PERIODS_SCALE } from '../../../../store/periods/periodsSlice';
import PeriodsCreateFrom from '../../components/form/PeriodsCreateForm';
import PeriodsListItem from './item/PeriodsListItem';
import PeriodsSearchForm from '../../components/search-form/PeriodsSearchForm';
import Paginator from '../../../../components/paginator/Paginator';
import styles from './PeriodsListPage.module.scss';

// TODO интерфейс может расширятся в дальнейшем
interface TableSort {
    field: string;
    sort: number;
};

// TODO такая зависимость от bankId обусловленна тем что данный компонент используется в bankInfo page в идеале нужно вынести отдельный компонент для bankInfoPage
interface PeriodsListPageProps {
    bankId?: string;
}

const PeriodsListPage = ({
    bankId,
}: PeriodsListPageProps) => {
    const dispatch = useAppDispatch();

    const periods = useAppSelector(selectorPeriodsList);
    const periodsCount = useAppSelector(selectorPeriodsCount);
    const isLoading = useAppSelector(selectorPeriodsIsLoading);

    const [page, setPage] = useState<number>(1);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [tableSort, setTableSort] = useState<TableSort | null>(null);
    const [filter, setFilter] = useState<PeriodsSearchFormActionProps>({});

    const handlePaginatorClick = (page: number, pageIndex: number) => {
        setPage(page);
        setPageIndex(pageIndex);

        dispatch(periodsLoadPeriodsListThunk({
            bankId,
            bankName: filter?.bankName,
            status: filter?.status,
            page,
            sortBy: tableSort ? JSON.stringify(tableSort) : '',
            limit: PERIODS_SCALE,
        }));
    };

    const handleSearchPeriodsFormSubmit = ({
        bankName,
        status,
    }: PeriodsSearchFormActionProps) => {
        if (bankName) {
            filter.bankName = bankName;
        } else {
            // удаляется чтобы не запоминалось в стейте в случае передачи пустого поля
            delete filter.bankName;
        }

        if (status) {
            filter.status = status;
        } else {
            // удаляется чтобы не запоминалось в стейте в случае передачи пустого поля
            delete filter.status;
        }

        setFilter(filter);

        dispatch(periodsLoadPeriodsListThunk({
            bankId,
            status,
            bankName,
            page: 1, // поиск начинается всегда с первой страницы
            sortBy: tableSort ? JSON.stringify(tableSort) : '',
            limit: PERIODS_SCALE,
            onSuccess: () => {
                setPage(1);
                setPageIndex(1);
            },
        }));
    };

    const handleTableSort = (tableSort: TableSort) => {
        setTableSort(tableSort);

        dispatch(periodsLoadPeriodsListThunk({
            bankId,
            bankName: filter?.bankName,
            status: filter?.status,
            page,
            sortBy: JSON.stringify(tableSort),
            limit: PERIODS_SCALE,
        }));
    };

    return (
        <>
            {!bankId && (<div className={styles.block}>
                <PeriodsCreateFrom />
            </div>)}
            <div className={styles.block}>
                <PeriodsSearchForm
                    onSubmit={handleSearchPeriodsFormSubmit}
                />
            </div>
            {!!periods.length && (
                <div className={styles.block}>
                    {periodsCount > PERIODS_SCALE && (
                        <Paginator
                            parentPage={page}
                            parentPageIndex={pageIndex}
                            buttonsCount={5}
                            items={periodsCount}
                            scale={PERIODS_SCALE}
                            onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                        />
                    )}
                    {!isLoading && (
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'bankName',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >Банк {tableSort?.sort && tableSort?.field === 'bankName' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                    {/* <th>Декада</th> */}
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'month',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >Месяц {tableSort?.sort && tableSort?.field === 'month' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'year',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >Год {tableSort?.sort && tableSort?.field === 'year' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'createdTimestamp',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >Дата создания {tableSort?.sort && tableSort?.field === 'createdTimestamp' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'status',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >Статус {tableSort?.sort && tableSort?.field === 'status' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {periods.map((period, index) => (
                                    <PeriodsListItem
                                        key={index}
                                        period={period}
                                        index={index + (page - 1) * PERIODS_SCALE}
                                    />
                                ))}
                            </tbody>
                        </table>
                    )}
                    {isLoading && (
                        // TODO сделать лоадер
                        <h2>ЗАГРУЗКА...</h2>
                    )}
                    {periodsCount > PERIODS_SCALE && (
                        <Paginator
                            parentPage={page}
                            parentPageIndex={pageIndex}
                            buttonsCount={5}
                            items={periodsCount}
                            scale={PERIODS_SCALE}
                            onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                        />
                    )}
                </div>
            )}
            {!periods.length && (
                <div className={styles.block}>
                    <b>Отчетные периоды не найдены</b>
                </div>
            )}
        </>
    );
};

export default PeriodsListPage;