import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { agentsAgentLoadCurrentAgentThunk, agentsDeleteAgentThunk, AgentsFormActionProps, agentsSetCurrentAgentAction, agentsUpdateAgentThunk, selectorAgent, selectorAgentsList, selectorCurrentAgent } from '../../../../store/agents/agentsSlice';
import { reportsLoadReportsListThunk } from '../../../../store/reports/reportsSlice';
import { selectorUsersIsLoading, selectorUsersList, User, usersManagerLoadUsersListThunk} from '../../../../store/users/usersSlice';
import ReportsListPage from '../../../reports/pages/list/ReportsListPage';
import AgentsForm from '../../components/form/AgentsForm';
import styles from './AgentInfoPage.module.scss';

const AgentInfoPage = () => {
    const dispatch = useAppDispatch();

    const params = useParams();
    const agentId = params.agentId || '';

    const navigate = useNavigate();

    const agent = useAppSelector(selectorCurrentAgent);
    const users = useAppSelector(selectorUsersList);
    const usersIsLoading = useAppSelector(selectorUsersIsLoading);

    const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(false);
    // TODO в дальнейшем будет приходить список пользователей, так как сейчас пользователь для агента один то берётся первый элемент
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        dispatch(agentsAgentLoadCurrentAgentThunk({ agentId })); // TODO первичные данные которые загружаются при загрузке списка должны браться из массива, а то что подгружается должно выполняться запросом
    }, []);

    useEffect(() => {
        if (!!agent) {
            dispatch(usersManagerLoadUsersListThunk({ agentId }));
            dispatch(reportsLoadReportsListThunk({ agentId }));
        }
    }, [agent]);

    useEffect(() => {  
        // TODO заглушка на одного пользователя, требует расширение на несколько в дальнейшем
        // if (users.length === 1) {
            setUser(users[0]);
        // }
    }, [users]);

    const handleFormSubmit = (props: AgentsFormActionProps) => {
        const {
            login,
            password,
            firstName,
            middleName,
            lastName,
            phone,
            email,
            organisationName,
            headFirstName,
            headMiddleName,
            headLastName,
            headPost,
            inn,
            kpp,
            bank,
            bic,
            paymentAccount,
            correspondentAccount,
        } = props;

        if (agentId && user) {
            dispatch(agentsUpdateAgentThunk({
                agentId,
                userId: user.userId,
                login,
                password,
                firstName,
                middleName,
                lastName,
                phone,
                email,
                organisationName,
                headFirstName,
                headMiddleName,
                headLastName,
                headPost,
                inn,
                kpp,
                bank,
                bic,
                paymentAccount,
                correspondentAccount,
                onSuccess: () => {
                    setIsFormDisplayed(false)
                }
            }));
        }
    };

    const handleDelete = () => {
        if (window.confirm('Точно хотите удалить?')) {
            if(!!agentId) {
                dispatch(agentsDeleteAgentThunk({
                    agentId,
                    onSuccess: () => {
                        navigate('/manager/agents');
                    },
                    onError: () => {
                        // TODO
                    }
                }));
            }
        }

        return;
    };

    return(
        <div>
            <div>
                {agent && user && !usersIsLoading && (
                    <div>
                        <div className={styles.block}>
                            <div className={styles.controls}>
                                {!isFormDisplayed && (
                                    <>
                                        <button
                                            className={styles.button}
                                            onClick={() => setIsFormDisplayed(true)}
                                        >Редактировать</button>
                                        <button
                                            onClick={handleDelete}
                                            className={cn(
                                                styles.button,
                                                styles.buttonDeclined,
                                            )}
                                        >
                                            Удалить
                                        </button>
                                    </>
                                )}
                            </div>
                            {isFormDisplayed && (
                                <>
                                    <button
                                        className={styles.button}
                                        onClick={() => setIsFormDisplayed(false)}
                                    >Отмена</button>
                                    <AgentsForm
                                        // agentId={agentId}
                                        // userId={user.userId}
                                        login={user.login}
                                        password={user.hashPassword}
                                        // role={user.role} // TODO тут в дальнейшем будет смена роли для пользователей агента
                                        firstName={user.userInfo?.firstName}
                                        middleName={user.userInfo?.middleName}
                                        lastName={user.userInfo?.lastName}
                                        phone={user.userInfo?.phone}
                                        email={user.userInfo?.email}
                                        organisationName={agent.organisationName}
                                        headFirstName={agent.headFirstName}
                                        headMiddleName={agent.headMiddleName}
                                        headLastName={agent.headLastName}
                                        headPost={agent.headPost}
                                        inn={agent.inn}
                                        kpp={agent.kpp}
                                        bank={agent.requisites.bank} // TODO такое обращение может привести к багу, если не будет существовать какого то поля
                                        bic={agent.requisites.bic}
                                        paymentAccount={agent.requisites.paymentAccount}
                                        correspondentAccount={agent.requisites.correspondentAccount}
                                        isNewAgent={false}
                                        onSubmit={handleFormSubmit}
                                    />
                                </>
                            )}
                        </div>
                        <div className={styles.block}>
                            <h2>Агентские данные</h2>
                            <div className={styles.blockList}>
                                <div><b>Название организации:</b> {agent.organisationName}</div>
                                <div><b>ИНН:</b> {agent.inn}</div>
                                <div><b>КПП:</b> {agent.kpp}</div>
                                <div><b>ФИО руководителя:</b> {agent.headFirstName} {agent.headLastName} {agent.headMiddleName}</div>
                                <div><b>Должность:</b> {agent.headPost}</div>
                                <div><b>Реквизиты</b></div>
                                <div><b>Банк:</b> {agent.requisites.bank}</div>
                                <div><b>БИК:</b> {agent.requisites.bic}</div>
                                <div><b>Расчетный счёт:</b> {agent.requisites.paymentAccount}</div>
                                <div><b>Корреспондентский счёт:</b> {agent.requisites.correspondentAccount}</div>
                            </div>
                        </div>
                        <div className={styles.block}>
                            <h2>Пользовательские данные</h2>
                            <div className={styles.blockList}>
                                <div><b>Логин:</b> {user.login}</div>
                                <div><b>ФИО:</b> {user.userInfo?.lastName} {user.userInfo?.firstName} {user.userInfo?.middleName}</div>
                                <div><b>Телефон:</b> {user.userInfo?.phone}</div>
                                <div><b>Email:</b> {user.userInfo?.email}</div>
                            </div>
                        </div>
                        <ReportsListPage
                            agentId={agentId}
                        />
                    </div>
                )}
                {usersIsLoading && (
                    <h2>Загрузка...</h2>
                )}
            </div>
        </div>
    )
};

export default AgentInfoPage;
