import React from 'react';
import Accordion from '../../../../components/accordion/Accordion';
import ProtectedComponent from '../../../../components/protected-component/ProtectedComponent';
import { UserRoles } from '../../../../store/users/usersSlice';
import styles from './FaqListPage.module.scss';

const FaqListPage = () => {
    return (
        <div className={styles.list}>
            <Accordion title='Что такое "Актуальный акт"?'>
                <div>
                    <p>На странице <b>Акты</b> или в отчётном периоде у некоторых актов может отображаться красный восклицательный знак (<span className={styles.notification}>!</span>). Этот знак означает, что в акте произошли какие-то изменения и его нужно проверить.</p>
                </div>
            </Accordion>
            <ProtectedComponent allowedRoles={[UserRoles.MANAGER]}>
                <Accordion title='Шаблоны для акта и счёта'>
                    <div>
                        <a href="/api/crm-files/templates/Акт_шаблон.docx">Пример шаблона акта</a>
                    </div>
                    <div>
                        <a href="/api/crm-files/templates/Счёт_шаблон.docx">Пример шаблона счёта</a>
                    </div>
                    <div>При изменении шаблона акта или счёта нужно указать в новом файле шаблона переменные, необходимые для генерации документов.</div>
                    <div>Все переменные должны находиться внутри <b>двойных фигурных скобок</b>. Переменные для вставки в документах шаблонов:</div>

                    <p>Переменные шаблона <b>акта</b></p>

                    <ul>
                    <li>{'{{reportNumber}}'}: <b>Номер акта</b></li>
                    <li>{'{{day}}'}: <b>День</b></li>
                    <li>{'{{monthString}}'}: <b>Месяц</b></li>
                    <li>{'{{year}}'}: <b>Год</b></li>
                    <li>{'{{agentName}}'}: <b>Наименование агента</b></li>
                    <li>{'{{agentInn}}'}: <b>ИНН агента</b></li>
                    <li>{'{{decadeString}}'}: <b>Декада</b></li>
                    <li>{'{{principalsTable}}'}: <b>Таблица привлечённых принципалов</b></li>
                    <li>{'{{summTranslated}}'}: <b>Сумма прописью</b></li>
                    <li>{'{{agentHeadLastName}}'}: <b>Фамилия руководителя агента</b></li>
                    <li>{'{{agentHeadFirstName}}'}: <b>Имя руководителя агента</b></li>
                    <li>{'{{agentHeadMiddleName}}'}: <b>Отчество руководителя агента</b></li>
                    <li>{'{{organisationName}}'}: <b>Наименование нашей организации</b></li>
                    <li>{'{{organisationInn}}'}: <b>ИНН нашей организации</b></li>
                    <li>{'{{organisationKpp}}'}: <b>КПП нашей организации</b></li>
                    <li>{'{{organisationHeadLastName}}'}: <b>Фамилия руководителя нашей организации</b></li>
                    <li>{'{{organisationHeadFirstName}}'}: <b>Имя руководителя нашей организации</b></li>
                    <li>{'{{organisationHeadMiddleName}}'}: <b>Отчество руководителя нашей организации</b></li>
                    </ul>

                    <p>Переменные шаблона <b>счёта</b></p>

                    <ul>
                        <li>{'{{bankName}}'}: <b>Наименование банка получателя (реквизиты агента)</b></li>
                        <li>{'{{bic}}'}: <b>БИК банка (реквизиты агента)</b></li>
                        <li>{'{{paymentAccount}}'}: <b>Расчётный счёт (реквизиты агента)</b></li>
                        <li>{'{{correspondentAccount}}'}: <b>Корреспондентский счёт (реквизиты агента)</b></li>
                        <li>{'{{summ}}'}: <b>Сумма выплаты в цифрах</b></li>
                    </ul>
                </Accordion>
                <Accordion title='Как менеджеру работать с актами?'>
                    <div>
                        <ol>
                            <li>Открыть нужный акт через отчётный период или найти акт на странице <b>Акты</b>.</li>
                            <li>Проверить документы, сгенерированные системой. Для этого нужно скачать документы контрагента и проверить их на корректность генерации. После проверки, если все документы верны то подтвердить их, нажав на кнопку <b>Подтвердить все</b>.</li>
                            <li>Дождаться, когда агент загрузит подписанные файлы акта, счёта и файлы подписей и проверить их. Если все файлы верны, то нажать на кнопку <b>Подтвердить все</b> и сменить статус акта нажатием на кнопку <b>Проверен</b>. Для неправильных файлов нажать на кнопку <b>Скрыть</b> и сменить статус нажатием на кнопку <b>Отправить на доработку</b>.</li>
                            <li>Для правильных файлов поставить галочку <b>Для бухгалтера</b> и нажать на кнопку <b>Отправить документы</b>. На почту бухгалетрии придет письмо с ссылками на файлы, ФИО менеджера, который отправил файлы и ИНН агента.</li>
                        </ol>
                    </div>
                </Accordion>
                <Accordion title='Как создать банк?'>
                    <div>
                        <p>При добавлении банка нужно загрузить два файла: файл шаблона акта и файл шаблона счёта.</p>
                        <p>Тип doc-файла должен быть только <b>.doc</b> или <b>.docx</b>.</p>
                    </div>
                    <p>Примеры файлов шаблонов:&nbsp;
                        <a href="/api/crm-files/templates/Акт_шаблон.docx">Пример шаблона акта</a>
                        &nbsp;и&nbsp;
                        <a href="/api/crm-files/templates/Счёт_шаблон.docx">Пример шаблона счёта</a>.
                    </p>
                </Accordion>
                <Accordion title='Как создать отчётный период?'>
                    <div>
                        <p>При создании отчётного периодна нужно загрузить эксель-файл периода. У этого файла должна быть <b>одинаковая структура, никакие поля не должны быть сдвинуты или переименованы</b>, иначе это приведёт к неправильной генерации документов.</p>
                        <p>Тип загружаемого эксель-файла должен быть только <b>.xls</b> или <b>.xlsx</b>.</p>
                    </div>
                    <p>
                        <a href="/api/crm-files/templates/Период_шаблон.xlsx">Пример шаблона периода</a>.
                    </p>
                </Accordion>
                <Accordion title='Как массово подтвердить, скрыть и перегенерировать документы контрагента (менеджера)?'>
                    <div>
                        <p>Открыть страницу <b>Отчетные периоды</b> отобразится список периодов. Справа от нужного периода нажать на ссылку <b>Подробнее</b>, откроется страница периода.</p>
                        <p>В блоке успешно сгенерированных агентских актов есть 3 кнопки:</p>
                        <ol>
                            <li>Кнопка <b>Подтвердить документы контрагента</b> массово подтверждает все документы, загруженные контрагентом (менеджером).</li>
                            <li>Кнопка <b>Скрыть документы контрагента</b> массово скрывает все документы, загруженные контрагентом (менеджером).</li>
                            <li>Кнопка <b>Перегенерировать документы</b> пересоздает существующие документы, если был загружен неправильный шаблон отчёта или счёта.</li>
                        </ol>
                    </div>
                </Accordion>
                <Accordion title='Как добавить, отредактировать и удалить агента?'>
                    <div>
                        <p>Чтобы добавить агента нужно на странице  нажать на кнопку <b>Добавить агента</b>. Заполнить открывшуюся форму и нажать на кнопку <b>Готово</b>.</p>
                        <p>Чтобы отредактировать агента нужно на странице <b>Агенты</b> в самой правой ячейке нужного агента перейти по ссылке <b>Подробнее</b> и нажать на кнопку <b>Редактировать</b>, внести изменения и нажать на кнопку <b>Готово</b>.</p>
                        <p>Для удаления агента на странице <b>Агенты</b> нужно найти агента, которого требуется удалить, перейти по ссылке <b>Подробнее</b> в самой правой ячейке и нажать на кнопку <b>Удалить</b>.</p>
                    </div>
                </Accordion>
            </ProtectedComponent>
            <ProtectedComponent allowedRoles={[UserRoles.AGENT]}>
                <Accordion title='Как агенту работать с актами?'>
                    <ol>
                        <li>Справа от акта нажать на ссылку <b>Подробнее</b> и в блоке <b>Документы контрагента</b> скачать файлы.</li>
                        <li>Подписать скачанные документы электронной цифровой подписью и прикрепить подписанные акт, счёт и два файла подписей, которыми производилась подпись. Должно быть прикреплено <b>минимум 4 файла</b>, чтобы появилась возможность отправить документы на проверку.</li>
                        <li>После загрузки сохранить документы и нажать на кнопку <b>Отправить на проверку</b>.</li>
                        <li>В случае, если менеджер отклонил какой-то файл то перезагрузить некорректный файл заново и нажать на кнопку <b>Отправить на проверку</b>.</li>
                    </ol>
                </Accordion>
            </ProtectedComponent>
            <ProtectedComponent allowedRoles={[UserRoles.ADMIN]}>
                <Accordion title='Как редактировать и удалять пользователей?'>
                    <p>Чтобы отредактировать пользователя, нужно нажать на ссылку <b>Подробнее</b> в самой правой ячейке и нажать на кнопку <b>Редактировать</b>, изменить значения в нужных полях и нажать на кнопку <b>Готово</b>.</p>
                    <p>Для удаления нужно нажать на кнопку <b>Удалить</b> и подтвердить удаление.</p>
                </Accordion>
            </ProtectedComponent>
        </div>
    );
};

export default FaqListPage;