import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { authLoginThunk } from '../../store/auth/authSlice';
import styles from './LoginPage.module.scss';

const LoginPage = () => {
    const dispatch = useAppDispatch();

    const [isValidForm, setIsValidForm] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        onSubmit: ({
            login,
            password,
        }) => {
            if (isValidForm) {
                dispatch(authLoginThunk({
                    login,
                    password,
                    onError: (code) => {
                        if (code === 401) {
                            setError('Не верный логин или пароль');
                        }
                        if (code === 403) {
                            setError('Превышено количество попыток');
                        }
                    }
                }));
            }
        },
    });

    useEffect(() => {
        if (!!formik.values.login && !!formik.values.password) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    }, [formik.values]);

    return(
        <div className={styles.wrapper}>
            <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
            >
                {!!error && (
                    <div className={styles.error}>{error}</div>
                )}
                <h2>Вход в систему</h2>
                <div className={styles.inputs}>
                    <div className={styles.input}>
                        <input
                            type="text"
                            placeholder="Логин"
                            name="login"
                            value={formik.values.login}
                            onChange={formik.handleChange}
                            required={true}
                        />
                    </div>
                    <div className={styles.input}>
                        <input
                            type="password"
                            placeholder="Пароль"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            required={true}
                        />
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        className={styles.button}
                        type="submit"
                        disabled={!isValidForm}
                    >Войти</button>
                </div>
            </form>
        </div>
    );
};

export default LoginPage;