import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../../../../app/hooks';
import { translateReportStatuses } from '../../../../../helpers/helpers';
import { selectorAgent } from '../../../../../store/agents/agentsSlice';
import { Report } from '../../../../../store/reports/reportsSlice';
import { UserRoles } from '../../../../../store/users/usersSlice';
import styles from './ReportsListItem.module.scss';

interface ReportsListItemProps {
    index: number;
    report: Report;
}
const ReportsListItem = ({
    index,
    report
}: ReportsListItemProps) => {
    return(
        <tr className={styles.tr}>
            <td>
                {report.currentNotification === UserRoles.MANAGER && (
                    <b className={styles.notification}>!</b>
                )}
            </td>
            <td>{index + 1}</td>
            <td>{new Date(report.createdTimestamp).toLocaleString('sv')}</td>
            <td><b>{translateReportStatuses(report.status)}</b></td>
            <td><NavLink to={`/manager/periods/${report.periodId}`}>Период</NavLink></td>
            <td><NavLink to={`/manager/agents/${report.agentId}`}>{report.agent.organisationName}</NavLink></td>
            <th><NavLink to={`/manager/reports/${report.reportId}`}>Подробнее</NavLink></th>
        </tr>
    );
};

export default ReportsListItem;