import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk, RootState } from "../../app/store";
import { ERRORS_LIMIT, RECONNECT_TIMEOUT } from "../../constants/constants";
import { authExecAfterAuthThunk, authLogoutAction } from "../auth/authSlice";
import { errorsDeleteActiveRequestErrorsAction, errorsSetActiveErrorAction, RequestErrorTypes, RequestFunctionNames } from "../errors/errorsSlice";

export const PERIODS_SCALE = 20; // TODO в дальнейшем это может настраиваться через стор

export enum PeriodStatuses {
    CREATED = 'CREATED', // период сформирован
    SUCCESS = 'SUCCESS', // успешно завершён
    DECLINE = 'DECLINE', // отклонён
};

export interface PeriodLogs {
    success: { agentId: string }[], // для кого удалось сгенерировать отчет
    errors: { agentInn: string}[], // для кого не удалось сгенерировать отчёт
}

export interface Period {
    periodId: string;
    bankId: string;
    bankName: string;
    organisationName: string;
    decade: {
        first: boolean,
        second: boolean;
        third: boolean;
    };
    month: number;
    year: number;
    product: string; // TODO в дальнейшем может быть несколько типов продуктов. Поэтому следует выделить отдельный тип
    createdTimestamp: number;
	updatedTimestamp: number;
	deletedTimestamp: number;
    status: PeriodStatuses;
    excelFileId: string;
    logs?: PeriodLogs; // успешно и не успешно сгеренированные
}

export interface PeriodsState {
    isLoading: boolean;
    isFileLoading: boolean;
    isCreating: boolean;
    isCurrentPeriodLoading: boolean;
    periodsList: Period[];
    currentPeriod: Period | null;
    periodsCount: number;
};

const initialState: PeriodsState = {
    isLoading: false,
    isCreating: false,
    isFileLoading: false,
    isCurrentPeriodLoading: false,
    periodsList: [],
    currentPeriod: null,
    periodsCount: 0,
};

export const periodsSlice = createSlice({
    name: 'periods',
    initialState,
    reducers: {
        periodsSetIsLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        periodsSetIsCurrentPeriodLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isCurrentPeriodLoading = action.payload;
        },
        periodsSetIsCreatingAction: (state, action: PayloadAction<boolean>) => {
            state.isCreating = action.payload;
        },
        periodsSetPeriodsListAction: (state, action: PayloadAction<Period[]>) => {
            state.periodsList = action.payload;
        },
        periodsSetIsFileLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isFileLoading = action.payload;
        },
        periodsSetCurrentPeriodAction: (state, action: PayloadAction<Period | null>) => {
            state.currentPeriod = action.payload;
        },
        periodSetPeriodAction: (state, action: PayloadAction<Period>) => {
            state.periodsList = state.periodsList.map((period) => {
                if (period.periodId === action.payload.periodId) {
                    return action.payload;
                }

                return period;
            });
        },
        periodsSetPeriodsCountAction: (state, action: PayloadAction<number>) => {
            state.periodsCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        // очищение стора при logoutAction
        builder.addCase(authLogoutAction, () => {
            return initialState;
        });
    },
});

export const {
    periodsSetIsLoadingAction,
    periodsSetPeriodsListAction,
    periodsSetIsFileLoadingAction,
    periodsSetCurrentPeriodAction,
    periodSetPeriodAction,
    periodsSetPeriodsCountAction,
    periodsSetIsCreatingAction,
    periodsSetIsCurrentPeriodLoadingAction,
} = periodsSlice.actions;

export const selectorPeriodsIsLoading = (state: RootState) => state.periods.isLoading;
export const selectorPeriodsIsCurrentPeriodLoading = (state: RootState) => state.periods.isCurrentPeriodLoading;
export const selectorPeriodsIsCreating = (state: RootState) => state.periods.isCreating;
export const selectorPeriodsList = (state: RootState) => state.periods.periodsList;
export const selectorPeriodsIsFileLoading = (state: RootState) => state.periods.isFileLoading;
export const selectorPeriod = (periodId: string) => {
    // TODO при смене формата id может изменится тип periodId 
    return (state: RootState) => state.periods.periodsList.find((period) => String(period.periodId) === String(periodId));
};
export const selectorCurrentPeriod = (state: RootState) => state.periods.currentPeriod;
export const selectorPeriodsCount = (state: RootState) => state.periods.periodsCount;

interface PeriodsLoadCurrentPeriodThunkProps {
    periodId: string;
    errorCounter?: number;
    onSuccess?: () => {};
    onError?: () => {};
};

// получение периода по periodId
export const periodsLoadCurrentPeriodThunk = (props: PeriodsLoadCurrentPeriodThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorPeriodsIsCurrentPeriodLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        periodId,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.PERIODS__LOAD_CURRENT_PERIOD;

    dispatch(periodsSetIsCurrentPeriodLoadingAction(true));

    axios.get(`/api/manager/periods/${periodId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(periodsSetCurrentPeriodAction(data.body.period));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                props,
                thunk: periodsLoadCurrentPeriodThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(periodsLoadCurrentPeriodThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    // обработка 404
					if (status === 404) {
						dispatch((periodsSetCurrentPeriodAction(null)));
					}

					if (onError) {
						onError();
					}
                },
            }));
        })
        .finally(() => {
            dispatch(periodsSetIsCurrentPeriodLoadingAction(false));
        });
};

interface PeriodsAgentLoadCurrentPeriodThunkProps {
    periodId: string;
    errorCounter?: number;
    onSuccess?: () => {};
    onError?: () => {};
};

// получение периода по periodId для агента
export const periodsAgentLoadCurrentPeriodThunk = (props: PeriodsAgentLoadCurrentPeriodThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorPeriodsIsLoading(getState());
    
    if (isLoading) {
        return;
    }

    const {
        periodId,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.PERIODS__AGENT_LOAD_CURRENT_PERIOD;

    dispatch(periodsSetIsLoadingAction(true));

    axios.get(`/api/periods/${periodId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(periodsSetCurrentPeriodAction(data.body.period));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                props,
                thunk: periodsAgentLoadCurrentPeriodThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(periodsAgentLoadCurrentPeriodThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch((periodsSetCurrentPeriodAction(null)));
					}

                    if (onError) {
                        onError();
                    }
                },
            }));
        })
        .finally(() => {
            dispatch(periodsSetIsLoadingAction(false));
        });
};

export interface PeriodsSearchFormActionProps {
    bankName?: string;
    status?: PeriodStatuses;
};

interface PeriodsLoadPeriodsListThunkProps extends PeriodsSearchFormActionProps {
    bankId?: string;
    sortBy?: string;
    limit?: number;
    page?: number;
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: () => void;
};

export const periodsLoadPeriodsListThunk = (props: PeriodsLoadPeriodsListThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorPeriodsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        bankName,
        status,
        sortBy,
        limit = 20,
        page = 1,
        bankId,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    dispatch(periodsSetIsLoadingAction(true));

    const urlParams = [];

    if (bankName) {
        urlParams.push(`bankName=${bankName}`);
    }

    if (status) {
        urlParams.push(`status=${status}`);
    }

    if (sortBy) {
        urlParams.push(`sortBy=${sortBy}`);
    }

    if (bankId) {
        urlParams.push(`bankId=${bankId}`);
    }

	urlParams.push(`from=${(page - 1) * limit}&limit=${limit}`);

    const request = RequestFunctionNames.PERIODS__LOAD_PERIODS_LIST
    const url = `/api/manager/periods?${urlParams.join('&')}`;

    axios.get(url)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(periodsSetPeriodsListAction(data.body.periods));
                dispatch(periodsSetPeriodsCountAction(data.body.length));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                props,
                thunk: periodsLoadPeriodsListThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(periodsLoadPeriodsListThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
                        dispatch(periodsSetPeriodsListAction([]));
					}

                    if (onError) {
                        onError();
                    }
                },
            }));
        })
        .finally(() => {
            dispatch(periodsSetIsLoadingAction(false));
        });
};

interface PeriodsUploadFileThunkProps {
    // TODO потом нужно будет обрабатывать декады
    // decade: {
    //     first: boolean,
    //     second: boolean;
    //     third: boolean;
    // };
    // month: number;
    // year: number;
    // bankData: Bank; // TODO возможно стоит деструктуризировать на уровне фронта
    bankId: string;
    fileString: string;
    errorCounter?: number;
    onSuccess: (periodData: {
        periodId: string;
        excelFileId: string;
    }) => void;
    onError?: () => {}; 
};

export const periodsUploadFileThunk = (props: PeriodsUploadFileThunkProps): AppThunk => (dispatch, getState) => {
    // TODO нужен обработчик ошибок как в quietex
    const isLoading = selectorPeriodsIsFileLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        // decade,
        // month,
        // year,
        // bankData,
        bankId,
        fileString,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.PERIODS_UPLOAD_FILE;

    dispatch(periodsSetIsFileLoadingAction(true));
    dispatch(periodsSetIsCreatingAction(true));

    axios.post('/api/manager/periods/fileUpload', {
        // bankData,
        // decade,
        // month,
        // year,
        bankId,
        fileString,
    })
        .then(({ data }) => {
            if (data.status === 201) {
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                onSuccess(data.body.periodData);
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                props,
                thunk: periodsUploadFileThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(periodsUploadFileThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
						onError();
					}
                }
            }));
        })
        .finally(() => {
            dispatch(periodsSetIsFileLoadingAction(false));
        });
};

export interface PeriodsCreatePeriodThunkProps {
    bankId: string;
    bankName: string;
    organisationName: string;
    decade: {
        first: boolean,
        second: boolean;
        third: boolean;
    };
    month: number;
    year: number;
    product: string; // TODO в дальнейшем может быть несколько типов продуктов. Поэтому следует выделить отдельный тип
    excelFileId: string;
    periodId: string;
    errorCounter?: number;
    onSuccess: (newPeriod: Period) => void;
    onError?: () => void; 
};

export const periodsCreatePeriodThunk = (props: PeriodsCreatePeriodThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorPeriodsIsLoading(getState());
    
    if (isLoading) {
        return;
    }

    const {
        bankId,
        bankName,
        organisationName,
        decade,
        month,
        year,
        product,
        excelFileId,
        periodId,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.PERIODS__CREATE_PERIOD;
    const periodsList = selectorPeriodsList(getState());
    const periodsCount = selectorPeriodsCount(getState());

    dispatch(periodsSetIsLoadingAction(true));

    axios.post('/api/manager/periods', {
        periodId,
        bankId,
        bankName,
        organisationName,
        decade,
        month,
        year,
        product,
        excelFileId,
    })
        .then(({ data }) => {
            if (data.status === 201) {
                if (onSuccess) {
                    // TODO при создании периода на друой странице пагинатора, элемент тоже добавится, что не очень. Возможно всё таки нужно выполнять именно thunk обновления
                    let newData = [...periodsList];
                    
                    if (newData.length < PERIODS_SCALE) {
                        newData.push(data.body.newPeriod);
                    } else {
                        newData.unshift(data.body.newPeriod);
                        newData.pop();
                    }

                    dispatch(periodsSetPeriodsListAction(newData));
                    dispatch(periodsSetPeriodsCountAction(periodsCount + 1));
                    dispatch(errorsDeleteActiveRequestErrorsAction(request));

                    onSuccess(data.body.newPeriod);
                }

                return;
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: periodsCreatePeriodThunk,
                props,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(periodsCreatePeriodThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
                        onError(); 
					}
                },
            }));
        })
        .finally(() => {
            dispatch(periodsSetIsLoadingAction(false));
            dispatch(periodsSetIsCreatingAction(false));
        });
};

interface PeriodsSendStatusThunkProps {
    periodId: string;
    periodStatus: PeriodStatuses;
    errorCounter?: number;
    onSuccess?: () => {},
    onError?: (error: any) => void,
}

export const periodsSendStatusThunk = (props: PeriodsSendStatusThunkProps): AppThunk => (dispatch, getState) => {
    const isLodaing = selectorPeriodsIsLoading(getState());

    if (isLodaing) {
        return;
    }
    
    const {
        periodId,
        periodStatus,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.PERIODS__SEND_STATUS;

    dispatch(periodsSetIsLoadingAction(true));

    axios.put(`/api/manager/periods/${periodId}`, {
        periodStatus,
    })
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(periodsSetCurrentPeriodAction(data.body.period));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                props,
                thunk: periodsSendStatusThunk,
                onError: (error) => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(periodsSendStatusThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
                        onError(error); 
					}
                },
            }))
        })
        .finally(() => {
            dispatch(periodsSetIsLoadingAction(false));
        });
}

export default periodsSlice.reducer;