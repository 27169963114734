import React from 'react';
import { NavLink } from 'react-router-dom';
import { translateReportStatuses } from '../../../../helpers/helpers';
import { Report } from '../../../../store/reports/reportsSlice';
import { UserRoles } from '../../../../store/users/usersSlice';
import styles from './AgentReportsListItem.module.scss';

interface AgentReportsListItemProps {
    index: number;
    report: Report;
}
const AgentReportsListItem = ({
    index,
    report
}: AgentReportsListItemProps) => {
    return (
        <tr className={styles.tr}>
            <td>
                {report.currentNotification === UserRoles.AGENT && (
                <div className={styles.warn}>!</div>
            )}
            </td>
            <td><b>{index + 1}</b></td>
            <td>{new Date(report.createdTimestamp).toLocaleString('sv')}</td>
            <td>{new Date(report.updatedTimestamp).toLocaleString('sv')}</td>
            <td><b>{translateReportStatuses(report.status)}</b></td>
            <th><NavLink to={`/reports/${report.reportId}`}>Подробнее</NavLink></th>
        </tr>
    );
};

export default AgentReportsListItem;