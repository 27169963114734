import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Paginator from '../../../components/paginator/Paginator';
import { reportsLoadAgentReportsListThunk, ReportsSearchFormActionProps, selectorReportsCount, selectorReportsIsLoading, selectorReportsList } from '../../../store/reports/reportsSlice';
import AgentReportsSearchForm from '../components/search-form/AgentReportsSearchForm';
import AgentReportsListItem from './item/AgentReportsListItem';
import styles from './AgentReportsListPage.module.scss';

const SCALE = 20;

// TODO интерфейс может расширятся в дальнейшем
interface TableSort {
    field: string;
    sort: number;
};

const AgentReportsListPage = () => {
    const dispatch = useAppDispatch();

    const reports = useAppSelector(selectorReportsList);
    const reportsCount = useAppSelector(selectorReportsCount);
    const isLodaing = useAppSelector(selectorReportsIsLoading);

    const [page, setPage] = useState<number>(1);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [tableSort, setTableSort] = useState<TableSort | null>(null);
    const [filter, setFilter] = useState<ReportsSearchFormActionProps>({});

    const handlePaginatorClick = (page: number, pageIndex: number) => {
        setPage(page);
        setPageIndex(pageIndex);

        dispatch(reportsLoadAgentReportsListThunk({
            status: filter?.status,
            currentNotification: filter?.currentNotification,
            page,
            sortBy: tableSort ? JSON.stringify(tableSort): '',
            limit: SCALE,
        }));
    };

    const handleSearchAgentReportsFormSubmit = ({
        status,
        currentNotification,
    }: ReportsSearchFormActionProps) => {
        if (status) {
            filter.status = status;
        } else {
            // удаляется чтобы не запоминалось в стейте в случае передачи пустого поля
            delete filter.status;
        }

        if (currentNotification) {
            filter.currentNotification = currentNotification;
        } else {
            // удаляется чтобы не запоминалось в стейте в случае передачи пустого поля
            delete filter.currentNotification;
        }

        setFilter(filter);
        
        dispatch(reportsLoadAgentReportsListThunk({
            status,
            currentNotification,
            page: 1, // поиск начинается всегда с первой страницы
            sortBy: tableSort ? JSON.stringify(tableSort): '',
            limit: SCALE,
            onSuccess: () => {
                setPage(1);
                setPageIndex(1);
            },
        }));
    };

    const handleTableSort = (tableSort: TableSort) => {
        setTableSort(tableSort);
        
        dispatch(reportsLoadAgentReportsListThunk({
            status: filter?.status,
            currentNotification: filter?.currentNotification,
            page,
            sortBy: JSON.stringify(tableSort),
            limit: SCALE,
        }));
    };
    
    return (
        <>
            <div className={styles.block}>
                <AgentReportsSearchForm
                    onSubmit={handleSearchAgentReportsFormSubmit}
                />
            </div>
            <div className={styles.block}>
                {reportsCount > SCALE && (
                    <Paginator
                        parentPage={page}
                        parentPageIndex={pageIndex}
                        buttonsCount={5}
                        items={reportsCount}
                        scale={SCALE}
                        onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                    />
                )}
                {!isLodaing && (
                    <>
                        {!!reports.length && (
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>№</th>
                                        <th
                                            className={styles.tableSort}
                                            onClick={() => {
                                                handleTableSort({
                                                    field: 'createdTimestamp',
                                                    sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                                });
                                            }}
                                        >Создан {tableSort?.sort && tableSort?.field === 'createdTimestamp' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                        <th
                                            className={styles.tableSort}
                                            onClick={() => {
                                                handleTableSort({
                                                    field: 'updatedTimestamp',
                                                    sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                                });
                                            }}
                                        >Обновлён {tableSort?.sort && tableSort?.field === 'updatedTimestamp' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                        <th
                                            className={styles.tableSort}
                                            onClick={() => {
                                                handleTableSort({
                                                    field: 'status',
                                                    sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                                });
                                            }}
                                        >Cтатус {tableSort?.sort && tableSort?.field === 'status' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports.map((report, index) => (
                                        <AgentReportsListItem
                                            key={report.reportId}
                                            report={report}
                                            index={index + (page - 1) * SCALE}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {!reports.length && (
                            <h2>Актов пока нет!</h2>
                        )}
                    </>
                )}
                {reportsCount > SCALE && (
                    <Paginator
                        parentPage={page}
                        parentPageIndex={pageIndex}
                        buttonsCount={5}
                        items={reportsCount}
                        scale={SCALE}
                        onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                    />
                )}
                {!!isLodaing && (
                    //TODO сделать лоадер
                    <h2>Загрузка...</h2>
                )}
            </div>
        </>
    );
};

export default AgentReportsListPage;