import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import axios from 'axios';
import { authExecAfterAuthThunk, authLogoutAction } from '../auth/authSlice';
import { errorsDeleteActiveRequestErrorsAction, errorsSetActiveErrorAction, RequestErrorTypes, RequestFunctionNames } from '../errors/errorsSlice';
import { ERRORS_LIMIT, RECONNECT_TIMEOUT } from '../../constants/constants';

export const USERS_SCALE = 20; // TODO в дальнейшем это может настраиваться через стор

export const enum UserRoles {
    ADMIN = 'ADMIN',
    MANAGER = 'MANAGER',
    AGENT = 'AGENT',
}
// TODO переделать взять из userSlice
export interface AgentInfo {
    agentId: string;
    organisationName: string;
    headFirstName: string;
    headLastName: string;
    headMiddleName: string;
    headPost: string;
    inn: string;
    kpp: string;
    requisites: {
        bank: string;
        bic: string;
        paymentAccount: string;
        correspondentAccount: string;
    };
};

export interface UserInfo {
    firstName: string;
    lastName: string;
    middleName: string;
    phone: string;
    email: string;
}

export interface User {
    agentId: string;
    userId: string;
    login: string;
    hashPassword: string;
    roles: UserRoles[];
    createdTimestamp: number;
	updatedTimestamp: number;
	deletedTimestamp: number;
    isBlocked: boolean;
    userInfo?: UserInfo;
    agentInfo?: AgentInfo; // данное поле берётся из отдельной коллекции в бд 
};

// стейт профиля для пользователя
export interface UserProfile {
    login: string;
    roles: UserRoles[];
    createdTimestamp: number;
	updatedTimestamp: number;
    userInfo?: UserInfo;
    agentInfo?: AgentInfo;
};

// TODO возможно стоит добавить стейты состояния isLoading для каждого отдельного thunk 
export interface UsersState {
    isLoading: boolean; // стейт состояния выполняется ли какой нибудь thunk в данный момент
    isCurrentUserLoading: boolean;
    usersList: User[];
    currentUser: User | null;
    userProfile: UserProfile | null;
    usersCount: number;
};

const initialState: UsersState = {
    isLoading: false,
    isCurrentUserLoading: false,
    usersList: [],
    currentUser: null,
    userProfile: null,
    usersCount: 0,
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        usersSetIsLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        usersSetIsCurrentUserLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isCurrentUserLoading = action.payload;
        },
        usersSetUsersListAction: (state, action: PayloadAction<User[]>) => {
            state.usersList = action.payload;
        },
        usersSetCurrentUserAction: (state, action: PayloadAction<User | null>) => {
            state.currentUser = action.payload;
        },
        usersSetUserAction: (state, action: PayloadAction<User>) => {
            state.usersList = state.usersList.map((user) => {
                if (user.userId === action.payload.userId) {
                    return action.payload;
                }

                return user;
            });
        },
        usersSetUserProfileAction: (state, action: PayloadAction<UserProfile | null>) => {
            state.userProfile = action.payload;
        },
        usersSetUsersCountAction: (state, action: PayloadAction<number>) => {
            state.usersCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        // очищение стора при logoutAction
        builder.addCase(authLogoutAction, () => {
            return initialState;
        });
    },
});

export const {
    usersSetIsLoadingAction,
    usersSetIsCurrentUserLoadingAction,
    usersSetUsersListAction,
    usersSetCurrentUserAction,
    usersSetUserProfileAction,
    usersSetUserAction,
    usersSetUsersCountAction,
} = usersSlice.actions;

export const selectorUsersIsLoading = (state: RootState) => state.users.isLoading;
export const selectorUsersIsCurrentUserLoading = (state: RootState) => state.users.isCurrentUserLoading;
export const selectorUsersList = (state: RootState) => state.users.usersList;
export const selectorCurrentUser = (state: RootState) => state.users.currentUser;
export const selectorUserProfile = (state: RootState) => state.users.userProfile;
export const selectorUser = (userId: string) => {
    return (state: RootState) => state.users.usersList.find((user) => user.userId === userId);
};
export const selectorUsersCount = (state: RootState) => state.users.usersCount;

interface UsersLoadCurrentUserThunkProps {
    userId: string;
    errorCounter?: number;
};

export const usersLoadCurrentUserThunk = (props: UsersLoadCurrentUserThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorUsersIsCurrentUserLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        userId,
        errorCounter = 0,
    } = props;

    const request = RequestFunctionNames.USERS__LOAD_CURRENT_USER;

    dispatch(usersSetIsCurrentUserLoadingAction(true));

    axios.get(`/api/users/${userId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(usersSetCurrentUserAction(data.body.user));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                props,
                thunk: usersLoadCurrentUserThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(usersLoadCurrentUserThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch((usersSetCurrentUserAction(null)));
					}
                }
            }));
        })
        .finally(() => {
            dispatch(usersSetIsCurrentUserLoadingAction(false));
        });
};

interface UsersLoadUserProfileThunkProps {
    errorCounter?: number;
};

export const usersLoadUserProfileThunk = (props: UsersLoadUserProfileThunkProps): AppThunk => (dispatch, getState) => {
    const {
        errorCounter = 0,
    } = props;

    const request = RequestFunctionNames.USERS__LOAD_USER_PROFILE;

    axios.get('/api/users/profile')
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(usersSetUserProfileAction(data.body.profile));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: usersLoadUserProfileThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(usersLoadUserProfileThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch((usersSetUserProfileAction(null)))
					}
                },
            }));
        })
};

interface UsersManagerLoadUsersListThunkProps {
    agentId?: string;
    errorCounter?: number;
}

export const usersManagerLoadUsersListThunk = (props: UsersManagerLoadUsersListThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorUsersIsLoading(getState());

    if (isLoading) {
        return;
    }

    dispatch(usersSetIsLoadingAction(true));
    
    const {
        agentId,
        errorCounter = 0,
    } = props;

    let url = '/api/manager/users?';

    if (agentId) {
        url += `agentId=${agentId}&`
    }

    const request = RequestFunctionNames.USERS__MANAGER_LOAD_USERS_LIST;

    axios.get(url)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(usersSetUsersListAction(data.body.users));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: usersManagerLoadUsersListThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(usersManagerLoadUsersListThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch((usersSetUsersListAction([])));
					}
                }
            }))
        })
        .finally(() => {
            dispatch(usersSetIsLoadingAction(false));
        });
};

export interface UsersSearchFormActionProps {
    login?: string;
    email?: string;
};

interface UsersAdminLoadUsersListThunkProps extends UsersSearchFormActionProps {
    sortBy?: string;
    limit?: number;
    page?: number;
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: () => void;
};

export const usersAdminLoadUsersListThunk = (props: UsersAdminLoadUsersListThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorUsersIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        login,
        email,
        sortBy,
        limit = 20,
        page = 1,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.USERS__ADMIN_LOAD_USERS_LIST;

    dispatch(usersSetIsLoadingAction(true));

    const urlParams = [];

    if (login) {
        urlParams.push(`login=${login}`);
    }

    if (email) {
        urlParams.push(`email=${email}`);
    }

    if (sortBy) {
        urlParams.push(`sortBy=${sortBy}`);
    }

	urlParams.push(`from=${(page - 1) * limit}&limit=${limit}`);

    const url = `/api/admin/users?${urlParams.join('&')}`;

    axios.get(url)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(usersSetUsersListAction(data.body.users));
                dispatch(usersSetUsersCountAction(data.body.length));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: usersAdminLoadUsersListThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(usersAdminLoadUsersListThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));
                    
					// обработка 404
					if (status === 404) {
						dispatch((usersSetUsersListAction([])));
					}

                    if (onError) {
                        onError();
                    }
                }
            }))
        })
        .finally(() => {
            dispatch(usersSetIsLoadingAction(false));
        });
};

export interface UsersFormActionProps {
    login: string;
    password: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phone: string;
    email: string;
    roles: UserRoles[];
    isBlocked?: boolean;
    // agentId?: string; // TODO пока админ создаёт только менеджера и админа
};

interface UsersCreateUserThunkProps extends UsersFormActionProps {
    // login: string;
    // hashPassword: string;
    // role: string;
    // userInfo?: UserInfo;
    // agentInfo?: AgentInfo;
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: () => void;
};

export const usersCreateUserThunk = (props: UsersCreateUserThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorUsersIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        login,
        password,
        firstName,
        middleName,
        lastName,
        phone,
        email,
        roles,
        isBlocked,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.USERS__CREATE_USER;
    const usersList = selectorUsersList(getState());
    const usersCount = selectorUsersCount(getState());

    dispatch(usersSetIsLoadingAction(true));

    axios.post('/api/admin/users', {
        login,
        password,
        firstName,
        middleName,
        lastName,
        phone,
        email,
        roles,
        isBlocked,
    })
        .then(({ data }) => {
            if (data.status === 201) {
                // TODO такая конструкция имеет изъян, так как она заменяет 1ый элемент списка, однако этот список при пагинации может иметь всего 20 элементов, а добавлятся в стейт будет именно к этим 20ти элементам, даже если пагинация будет на 10й странице например
                // TODO возможно стоит перезапрашивать именно со стороны сервера, из за такой конструкции
                let newData = [...usersList];
                
                if (newData.length < USERS_SCALE) {
                    newData.push(data.body.newUser);
                } else {
                    newData.unshift(data.body.newUser);
                    newData.pop();
                }

                dispatch(usersSetUsersListAction(newData));
                dispatch(usersSetUsersCountAction(usersCount + 1));
				dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }

                return;
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: usersCreateUserThunk,
                props,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(usersCreateUserThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
                        onError(); 
					}
                },
            }));
        })
        .finally(() => {
            dispatch(usersSetIsLoadingAction(false));
        });
};

interface UsersUpdateUserThunkProps extends UsersFormActionProps {
    userId: string;
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: () => void;
}

export const usersUpdateUserThunk = (props: UsersUpdateUserThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorUsersIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        userId,
        login,
        password,
        firstName,
        middleName,
        lastName,
        phone,
        email,
        roles,
        isBlocked,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    const request = RequestFunctionNames.USERS__UPDATE_USER;

    dispatch(usersSetIsLoadingAction(true));
    
    axios.put(`/api/admin/users/${userId}`, {
        login,
        password,
        firstName,
        middleName,
        lastName,
        phone,
        email,
        roles,
        isBlocked,
    })
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(usersSetUserAction(data.body.user));
                dispatch(usersSetCurrentUserAction(data.body.user));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
          
                return;
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: usersUpdateUserThunk,
                props,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(usersUpdateUserThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    if (onError) {
                        onError(); 
                    }
                },
            }));
        })
        .finally(() => {
            dispatch(usersSetIsLoadingAction(false));
        });
};

interface UsersDeleteUserThunkProps {
    userId: string;
    errorCounter?: number;
    onSuccess: () => void;
    onError: () => void;
};

export const usersDeleteUserThunk = (props: UsersDeleteUserThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorUsersIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        userId,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    dispatch(usersSetIsLoadingAction(true));

    const request = RequestFunctionNames.USERS__DELETE_USER;
    const users = selectorUsersList(getState());
    const usersCount = selectorUsersCount(getState());

    axios.delete(`/api/admin/users/${userId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(usersSetUsersListAction(users.filter((user) => user.userId !== userId)));
                dispatch(usersSetUsersCountAction(usersCount - 1));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                onSuccess();
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: usersDeleteUserThunk,
                props,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(usersDeleteUserThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    onError(); 
                },
            }));
        })
        .finally(() => {
            dispatch(usersSetIsLoadingAction(false));
        });
};

export default usersSlice.reducer;