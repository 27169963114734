import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { periodsLoadPeriodsListThunk } from '../../store/periods/periodsSlice';
import styles from './Periods.module.scss';

const PeriodsPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(periodsLoadPeriodsListThunk({
            limit: 20,
        }));
    }, []);

    return(
        <div className={styles.wrapper}>
            <h1>Отчетные периоды</h1>
            <Outlet />
        </div>
    );
};

export default PeriodsPage;