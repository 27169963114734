import React from 'react';
import { LocalStorageValues } from '../../store/auth/authSlice';
import { UserRoles } from '../../store/users/usersSlice';

interface ProtectedComponentProps {
    allowedRoles: UserRoles[];
    children: any;
};

const ProtectedComponent = ({
    allowedRoles,
    children,
}: ProtectedComponentProps) => {
    const authUserRoles = localStorage.getItem(LocalStorageValues.ROLES)?.split(',') || [];

    const isAllowedVisit = allowedRoles.some((role: UserRoles) => authUserRoles.includes(role));

    if (!allowedRoles.length) {
        return <>{children}</>;
    }

    return (
        <>
            {isAllowedVisit || !allowedRoles.length ? (
                <>{children}</>
            ) : (
                <></> // TODO редирект на форму логина при переходе на недоступную для пользователя с данной ролью ссылку
            )}
        </>
    );
};

export default ProtectedComponent;