import React from 'react';
import FaqListPage from './pages/list/FaqListPage';
import styles from './FaqPage.module.scss';

const FaqPage = () => {
    return (
        <div className={styles.wrapper}>
            <h1>FAQ</h1>
            <div className={styles.block}>
                <h3 className={styles.subtitle}>Найдите свой вопрос в разделе часто задаваемых вопросов.</h3>
                <FaqListPage />
            </div>
        </div>
    );
};

export default FaqPage;