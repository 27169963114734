import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { translateError } from '../../../../helpers/helpers';
import { RequestErrorTypes, selectorError } from '../../../../store/errors/errorsSlice';
import { UserRoles, UsersFormActionProps } from '../../../../store/users/usersSlice';
import styles from './UsersForm.module.scss';

interface UsersFormProps {
    login?: string;
    password?: string;
    roles?: UserRoles[];
    firstName?: string;
    middleName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    isBlocked?: boolean;
    // agentId?: string; // TODO админ пока создаёт только админов и менджеров
    isNewUser: boolean;
    onSubmit: (props: UsersFormActionProps) => void;
};

const UsersForm = ({
    login = '',
    password = '',
    roles = [UserRoles.MANAGER],
    firstName = '',
    middleName = '',
    lastName = '',
    phone = '',
    email = '',
    isBlocked = false,
    // agentId = '',
    isNewUser,
    onSubmit,
}: UsersFormProps) => {
    const isErrorPassword = useAppSelector(selectorError(RequestErrorTypes.PASSWORD_FORMAT_ERROR));
    const [isValidForm, setIsValidForm] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            login,
            password,
            roles,
            firstName,
            middleName,
            lastName,
            phone,
            email,
            isBlocked,
            // agentId,
        },
        onSubmit: ({
            login,
            password,
            roles,
            firstName,
            middleName,
            lastName,
            phone,
            email,
            isBlocked,
            // agentId,
        }) => {
            if (isValidForm) {
                onSubmit({
                    login,
                    password,
                    roles,
                    firstName,
                    middleName,
                    lastName,
                    phone,
                    email,
                    isBlocked,
                    // agentId,
                });
            }
        }
    });

    useEffect(() => {
        if (
            !!formik.values.login
            && ((isNewUser && !!formik.values.password) || !isNewUser)
            && !!formik.values.firstName
            && !!formik.values.middleName
            && !!formik.values.lastName
            && !!formik.values.phone
            && !!formik.values.email
        ) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    }, [formik.values]);

    return (
        <div>
            <form
                onSubmit={formik.handleSubmit}
            >
                <div>
                    <div>
                        <h3>Пользовательские данные</h3>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Логин</div>
                            <input
                                type="text"
                                placeholder="Логин"
                                name="login"
                                value={formik.values.login}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div>
                            <div className={styles.formRow}>
                                <div className={styles.formTitle}>Пароль</div>
                                <input
                                    type="text"
                                    placeholder="Пароль"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    required={isNewUser}
                                    className={styles.input}
                                />
                                {!!isErrorPassword && (
                                    <div className={styles.error}>{translateError(isErrorPassword.type)}</div>
                                )}
                            </div>
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Роль</div>
                            <select
                                name="roles"
                                value={formik.values.roles} // TODO несколько ролей
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.select}
                                multiple={true}
                            >
                                <option value="" disabled>Выберите роль</option>
                                <option value={UserRoles.ADMIN}>Админ</option>
                                <option value={UserRoles.MANAGER}>Менеджер</option>
                                <option value={UserRoles.AGENT}>Агент</option>
                            </select>
                        </div>
                        <div className={styles.formTitle}>
                            <b>ФИО</b>
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Фамилия</div>
                            <input
                                type="text"
                                placeholder="Фамилия"
                                name="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Имя</div>
                            <input
                                type="text"
                                placeholder="Имя"
                                name="firstName"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Отчество</div>
                            <input
                                type="text"
                                placeholder="Отчество"
                                name="middleName"
                                value={formik.values.middleName}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formTitle}>
                            <b>Контактные данные</b>
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Телефон</div>
                            <input
                                type="text"
                                placeholder="Телефон"
                                name="phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <div className={styles.formTitle}>Email</div>
                            <input
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                required={true}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formCheckbox}>
                            <input
                                type="checkbox"
                                name="isBlocked"
                                checked={formik.values.isBlocked}
                                onChange={formik.handleChange}
                            />
                            Заблокирован
                        </div>
                    </div>
                </div>
                <button
                    disabled={!isValidForm}
                    type="submit"
                    className={styles.button}
                >
                    Готово
                </button>
            </form>
        </div>
    )
};

export default UsersForm;