import React, { useEffect } from "react";
import { Outlet } from "react-router";
import { useAppDispatch } from "../../app/hooks";
import { usersAdminLoadUsersListThunk } from "../../store/users/usersSlice";
import styles from './Users.module.scss';

// TODO тут разделение в зависимости от роли
const UsersPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(usersAdminLoadUsersListThunk({
            limit: 20,
        }));
    }, []);
    
    return(
        <div className={styles.wrapper}>
            <h1>Пользователи</h1>
            <div>
                <Outlet />
            </div>
        </div>
    );
};

export default UsersPage;