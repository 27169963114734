import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk, RootState } from "../../app/store";
import { ERRORS_LIMIT, RECONNECT_TIMEOUT } from "../../constants/constants";
import { authExecAfterAuthThunk, authLogoutAction } from "../auth/authSlice";
import { errorsDeleteActiveRequestErrorsAction, errorsSetActiveErrorAction, RequestErrorTypes, RequestFunctionNames } from "../errors/errorsSlice";
import { AgentInfo } from "../users/usersSlice";

export const AGENTS_SCALE = 20; // TODO в дальнейшем это может настраиваться через стор

export interface AgentsState {
    isLoading: boolean; // стейт состояния выполняется ли какой нибудь thunk в данный момент
    isCurrentAgentLoading: boolean;
    agentsList: AgentInfo[];
    currentAgent: AgentInfo | null;
    agentsCount: number;
};

const initialState: AgentsState = {
    isLoading: false,
    isCurrentAgentLoading: false,
    agentsList: [],
    currentAgent: null,
    agentsCount: 0,
};

export const agentsSlice = createSlice({
    name: 'agents',
    initialState,
    reducers: {
        agentsSetAgentsListAction: (state, action: PayloadAction<AgentInfo[]>) => {
            state.agentsList = action.payload;
        },
        agentsSetCurrentAgentAction: (state, action: PayloadAction<AgentInfo | null>) => {
            state.currentAgent = action.payload;
        },
        agentsSetIsLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        agentsSetIsCurrentAgentLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isCurrentAgentLoading = action.payload;
        },
        agentsSetAgentAction: (state, action: PayloadAction<AgentInfo>) => {
            state.agentsList = state.agentsList.map((agent) => {
                if (agent.agentId === action.payload.agentId) {
                    return action.payload;
                }

                return agent;
            });
        },
        agentsSetAgentsCountAction: (state, action: PayloadAction<number>) => {
            state.agentsCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        // очищение стора при logoutAction
        builder.addCase(authLogoutAction, () => {
            return initialState;
        });
    },
});

export const {
    agentsSetAgentsListAction,
    agentsSetCurrentAgentAction,
    agentsSetIsLoadingAction,
    agentsSetAgentAction,
    agentsSetAgentsCountAction,
    agentsSetIsCurrentAgentLoadingAction,
} = agentsSlice.actions;

export const selectorAgentsIsLoading = (state: RootState) => state.agents.isLoading;
export const selectorAgentsIsCurrentAgentLoading = (state: RootState) => state.agents.isCurrentAgentLoading;
export const selectorAgentsList = (state: RootState) => state.agents.agentsList;
export const selectorCurrentAgent = (state: RootState) => state.agents.currentAgent;
export const selectorAgent = (agentId: string) => {
    return (state: RootState) => state.agents.agentsList.find((agent) => agent.agentId === agentId);
};
export const selectorAgentsCount = (state: RootState) => state.agents.agentsCount;

interface AgentsManagerLoadCurrentAgentThunkProps {
    agentId: string;
    errorCounter?: number;
};

export const agentsManagerLoadCurrentAgentThunk = (props: AgentsManagerLoadCurrentAgentThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorAgentsIsCurrentAgentLoading(getState());
    
    if (isLoading) {
        return;
    }

    const {
        agentId,
        errorCounter = 0,
    } = props;
    const request = RequestFunctionNames.AGENTS__MANAGER_LOAD_CURRENT_AGENT;

    dispatch(agentsSetIsCurrentAgentLoadingAction(true));
    
    axios.get(`/api/manager/agents/${agentId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(agentsSetCurrentAgentAction(data.body.agent));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: agentsManagerLoadCurrentAgentThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

                    // Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

                    // Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(agentsManagerLoadCurrentAgentThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

                    dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    // обработка 404
					if (status === 404) {
						dispatch((agentsSetCurrentAgentAction(null)))
					}
                },
            }))
        })
        .finally(() => {
            dispatch(agentsSetIsCurrentAgentLoadingAction(false));
        });
};

interface AgentsAgentLoadCurrentAgentThunkProps {
    agentId: string;
    errorCounter?: number;
};

// TODO другой эндпоинт для агента
export const agentsAgentLoadCurrentAgentThunk = (props: AgentsAgentLoadCurrentAgentThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorAgentsIsCurrentAgentLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        agentId,
        errorCounter = 0,
    } = props;
    const request = RequestFunctionNames.AGENTS__AGENT_LOAD_CURRENT_AGENT;

    dispatch(agentsSetIsCurrentAgentLoadingAction(true));

    axios.get(`/api/agents/${agentId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(agentsSetCurrentAgentAction(data.body.agent));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                props,
                error,
                thunk: agentsAgentLoadCurrentAgentThunk,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

                    // Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

                    // Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(agentsAgentLoadCurrentAgentThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

                    dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    // обработка 404
					if (status === 404) {
						dispatch((agentsSetCurrentAgentAction(null)))
					}
                },
            }))
        })
        .finally(() => {
            dispatch(agentsSetIsCurrentAgentLoadingAction(false));     
        });
};

export interface AgentsSearchFormActionProps {
    inn?: string;
    organisationName?: string;
};

interface AgentsLoadAgentsListThunkProps extends AgentsSearchFormActionProps {
    sortBy?: string;
    limit?: number;
    page?: number;
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: (error: string) => void;
};

export const agentsLoadAgentsListThunk = (props: AgentsLoadAgentsListThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorAgentsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        inn,
        organisationName,
        sortBy,
        limit = 20,
        page = 1,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;

    dispatch(agentsSetIsLoadingAction(true));

    const urlParams = [];

    if (inn) {
        urlParams.push(`inn=${inn}`);
    }

    if (organisationName) {
        urlParams.push(`organisationName=${organisationName}`);
    }

    if (sortBy) {
        urlParams.push(`sortBy=${sortBy}`);
    }

    urlParams.push(`from=${(page - 1) * limit}&limit=${limit}`);

    const request = RequestFunctionNames.AGENTS__LOAD_AGENTS_LIST;
    const url = `/api/manager/agents?${urlParams.join('&')}`;
    
    axios.get(url)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(agentsSetAgentsListAction(data.body.agents));
                dispatch(agentsSetAgentsCountAction(data.body.length));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: agentsLoadAgentsListThunk,
                props,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(agentsLoadAgentsListThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    // обработка 404
					if (status === 404) {
                        dispatch(agentsSetAgentsListAction([]));
					}

                    if (onError) {
                        onError(error); 
                    }
                },
            }));
        })
        .finally(() => {
            dispatch(agentsSetIsLoadingAction(false));
        });
};

interface AgentsLoadAgentsListByIdsThunkProps {
    agentIds: string[];
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: (error: string) => void;
};

export const agentsLoadAgentsListByIdsThunk = (props: AgentsLoadAgentsListByIdsThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorAgentsIsLoading(getState());
    
    if (isLoading) {
        return;
    }

    const {
        agentIds,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;
    const request = RequestFunctionNames.AGENTS__LOAD_AGENTS_LIST_BY_IDS

    dispatch(agentsSetIsLoadingAction(true));

    axios.post('/api/manager/agents/list', { agentIds })
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(agentsSetAgentsListAction(data.body.agents));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: agentsLoadAgentsListByIdsThunk,
                props,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
						|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(agentsLoadAgentsListByIdsThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					// обработка 404
					if (status === 404) {
						dispatch(agentsSetAgentsListAction([]));
					}

					if (onError) {
						onError(error);
					}
                },
            }));
        })
        .finally(() => {
            dispatch(agentsSetIsLoadingAction(false));
        });
}

export interface AgentsFormActionProps {
    login: string;
    password: string;
    firstName: string,
    middleName: string,
    lastName: string,
    phone: string,
    email: string,
    organisationName: string,
    headFirstName: string,
    headMiddleName: string,
    headLastName: string,
    headPost: string,
    inn: string,
    kpp?: string,
    bank: string,
    bic: string,
    paymentAccount: string,
    correspondentAccount: string,
};

interface AgentsCreateAgentThunkProps extends AgentsFormActionProps {
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: (error: any) => void;
}

export const agentsCreateAgentThunk = (props: AgentsCreateAgentThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorAgentsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        login,
        password,
        // role,
        firstName,
        middleName,
        lastName,
        phone,
        email,
        organisationName,
        headFirstName,
        headMiddleName,
        headLastName,
        headPost,
        inn,
        kpp,
        bank,
        bic,
        paymentAccount,
        correspondentAccount,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;
    const agentsList = selectorAgentsList(getState());
    const agentsCount = selectorAgentsCount(getState());
    const request = RequestFunctionNames.AGENTS__CREATE_AGENT;

    dispatch(agentsSetIsLoadingAction(true));

    axios.post('/api/manager/agents', {
        login,
        password,
        firstName,
        middleName,
        lastName,
        phone,
        email,
        organisationName,
        headFirstName,
        headMiddleName,
        headLastName,
        headPost,
        inn,
        kpp,
        bank,
        bic,
        paymentAccount,
        correspondentAccount,
    })
        .then(({ data }) => {
            if (data.status === 201) {
                // TODO при создании периода на друой странице пагинатора, элемент тоже добавится, что не очень. Возможно всё таки нужно выполнять именно thunk обновления
                // TODO возможно стоит перезапрашивать именно со стороны сервера, из за такой конструкции
                let newData = [...agentsList];

                if (newData.length < AGENTS_SCALE) {
                    newData.push(data.body.newAgent);
                } else {
                    newData.unshift(data.body.newAgent);
                    newData.pop();
                }

                dispatch(agentsSetAgentsListAction(newData)); 
                dispatch(agentsSetAgentsCountAction(agentsCount + 1));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
          
                return;
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: agentsCreateAgentThunk,
                props,
                onError: (error) => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(agentsCreateAgentThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

					if (onError) {
                        onError(error); 
					}
                },
            }));
        })
        .finally(() => {
            dispatch(agentsSetIsLoadingAction(false));
        });
};

interface AgentsUpdateAgentThunkProps extends AgentsFormActionProps {
    agentId: string;
    userId: string;
    errorCounter?: number;
    onSuccess?: () => void;
    onError?: () => void;
}

export const agentsUpdateAgentThunk = (props: AgentsUpdateAgentThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorAgentsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        agentId,
        userId,
        login,
        password,
        firstName,
        middleName,
        lastName,
        phone,
        email,
        organisationName,
        headFirstName,
        headMiddleName,
        headLastName,
        headPost,
        inn,
        kpp,
        bank,
        bic,
        paymentAccount,
        correspondentAccount,
        // role,
        // userInfo,
        // agentInfo,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;
    const request = RequestFunctionNames.AGENTS__UPDATE_AGENT;

    dispatch(agentsSetIsLoadingAction(true));
    
    axios.put(`/api/manager/agents/${agentId}`, {
        userId,
        login,
        password,
        firstName,
        middleName,
        lastName,
        phone,
        email,
        organisationName,
        headFirstName,
        headMiddleName,
        headLastName,
        headPost,
        inn,
        kpp,
        bank,
        bic,
        paymentAccount,
        correspondentAccount,
        // role,
        // userInfo,
        // agentInfo,
    })
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(agentsSetAgentAction(data.body.agent));
                dispatch(agentsSetCurrentAgentAction(data.body.agent));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                if (onSuccess) {
                    onSuccess();
                }
          
                return;
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: agentsUpdateAgentThunk,
                props,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(agentsUpdateAgentThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    if (onError) {
                        onError(); 
                    }
                },
            }));
        })
        .finally(() => {
            dispatch(agentsSetIsLoadingAction(false));
        });
};

interface AgentsDeleteAgentThunkProps {
    agentId: string;
    errorCounter?: number;
    onSuccess: () => void;
    onError: () => void;
};

export const agentsDeleteAgentThunk = (props: AgentsDeleteAgentThunkProps): AppThunk => (dispatch, getState) => {
    const isLoading = selectorAgentsIsLoading(getState());

    if (isLoading) {
        return;
    }

    const {
        agentId,
        errorCounter = 0,
        onSuccess,
        onError,
    } = props;
    const agents = selectorAgentsList(getState());
	const agentsCount = selectorAgentsCount(getState());
    const request = RequestFunctionNames.AGENTS__DELETE_AGENT;

    dispatch(agentsSetIsLoadingAction(true));


    axios.delete(`/api/manager/agents/${agentId}`)
        .then(({ data }) => {
            if (data.status === 200) {
                dispatch(agentsSetAgentsListAction(agents.filter((agent) => agent.agentId !== agentId)));
                dispatch(agentsSetAgentsCountAction(agentsCount - 1));
                dispatch(errorsDeleteActiveRequestErrorsAction(request));

                onSuccess();
            }
        })
        .catch((error) => {
            dispatch(authExecAfterAuthThunk({
                error,
                thunk: agentsDeleteAgentThunk,
                props,
                onError: () => {
                    const status = error.response ? error.response.status : null;
					let type = RequestErrorTypes.ERR_UNEXPECTED;

					// Обработка ошибок без ответа от сервера
					if (error.code === RequestErrorTypes.ERR_NETWORK
						|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					) {
						type = error.code;
					}

					if (!!error.response && !!error.response.data.body) {
						type = error.response.data.body.error.type;
					}

					// Обработка ошибки c рекконектом
					const isRecconect = error.code === RequestErrorTypes.ERR_NETWORK
					|| error.code === RequestErrorTypes.ERR_BAD_RESPONSE
					|| type === RequestErrorTypes.SERVER_ERROR;

					if (isRecconect && errorCounter <= ERRORS_LIMIT) {
						setTimeout(() => {
							dispatch(agentsDeleteAgentThunk({
								...props,
								errorCounter: errorCounter + 1,
							}));
						}, RECONNECT_TIMEOUT);

						return;
					}

					dispatch(errorsSetActiveErrorAction({
						request,
						status,
						type,
					}));

                    onError(); 
                },
            }));
        })
        .finally(() => {
            dispatch(agentsSetIsLoadingAction(false));
        });
};

export default agentsSlice.reducer;