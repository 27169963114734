import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Paginator from '../../../../components/paginator/Paginator';
import {
    banksCreateBankThunk,
    BanksFormActionProps,
    banksLoadBanksListThunk,
    BanksSearchFormActionProps,
    banksUpdateDocTemplateFile,
    banksUploadDocTemplateFile,
    BANKS_SCALE,
    selectorBanksCount,
    selectorBanksIsLoading,
    selectorBanksList,
} from '../../../../store/banks/banksSlice';
import { DocTypes } from '../../../../store/files/filesSlice';
import BanksForm from '../../components/form/BanksForm';
import BanksSearchForm from '../../components/search-form/BanksSearchForm';
import BanksListItem from './item/BanksListItem';
import styles from './BanksListPage.module.scss';

// TODO интерфейс может расширятся в дальнейшем
interface TableSort {
    field: string;
    sort: number;
};

const BanksListPage = () => {
    const dispatch = useAppDispatch();

    const banks = useAppSelector(selectorBanksList);
    const banksCount = useAppSelector(selectorBanksCount);
    const isLoading = useAppSelector(selectorBanksIsLoading);

    const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [tableSort, setTableSort] = useState<TableSort | null>(null);
    const [filter, setFilter] = useState<BanksSearchFormActionProps>({});

    const handlePaginatorClick = (page: number, pageIndex: number) => {
        setPage(page);
        setPageIndex(pageIndex);

        dispatch(banksLoadBanksListThunk({
            name: filter.name,
            bic: filter.bic,
            page,
            sortBy: tableSort ? JSON.stringify(tableSort) : '',
            limit: BANKS_SCALE,
        }));
    };

    const handleSearchBanksFormSubmit = ({
        name,
        bic,
        inn,
    }: BanksSearchFormActionProps) => {
        filter.name = name || '';
        filter.bic = bic || '';
        filter.inn = inn || '';

        setFilter(filter);

        dispatch(banksLoadBanksListThunk({
            name,
            bic,
            page: 1, // поиск начинается всегда с первой страницы
            inn,
            sortBy: tableSort ? JSON.stringify(tableSort) : '',
            limit: BANKS_SCALE,
            onSuccess: () => {
                setPage(1);
                setPageIndex(1);
            },
        }));
    };

    const handleFormSubmit = (props: BanksFormActionProps) => {
        const {
            name,
            aliasName,
            correspondentAccount,
            bic,
            inn,
            kpp,
            siteAddres,
            instance,
            organisationName,
            organisationInn,
            organisationKpp,
            organisationHeadFirstName,
            organisationHeadMiddleName,
            organisationHeadLastName,
            organisationHeadPost,
            templateReportFileString,
            templateBillFileString,
        } = props;

        dispatch(banksCreateBankThunk({
            name,
            aliasName,
            correspondentAccount,
            bic,
            inn,
            kpp,
            siteAddres,
            instance,
            organisationName,
            organisationInn,
            organisationKpp,
            organisationHeadFirstName,
            organisationHeadMiddleName,
            organisationHeadLastName,
            organisationHeadPost,
            templateReportFileString,
            templateBillFileString,
            onSuccess: (bankId) => {
                if (bankId) {
                    dispatch(banksUploadDocTemplateFile({
                        bankId,
                        fileString: templateReportFileString,
                        docType: DocTypes.REPORT_TEMPLATE,
                        onSuccess: (docFileId) => {
                            dispatch(banksUpdateDocTemplateFile({
                                bankId,
                                docFileId,
                                docType: DocTypes.REPORT_TEMPLATE,
                            }));

                            dispatch(banksUploadDocTemplateFile({
                                bankId,
                                fileString: templateBillFileString,
                                docType: DocTypes.BILL_TEMPLATE,
                                onSuccess: (docFileId) => {
                                    dispatch(banksUpdateDocTemplateFile({
                                        bankId,
                                        docFileId,
                                        docType: DocTypes.BILL_TEMPLATE,
                                        onSuccess: () => {
                                            //TODO данный подход лучше чем обновление list
                                            dispatch(banksLoadBanksListThunk({
                                                page: 1, // поиск начинается всегда с первой страницы
                                                limit: BANKS_SCALE,
                                                onSuccess: () => {
                                                    setPage(1);
                                                    setPageIndex(1);
                                                },
                                            }));
                                        }
                                    }));
                                }
                            }));
                        },
                    }))
                }
            },
            onError: (error) => {
                // TODO тут должна быть всплывашка с ошибкой
                // if (error.response.status === 400) {
                //     alert('ОШИБКА | Банк с такой организацией уже есть в системе');
                // }
            }
        }));
    };

    const handleTableSort = (tableSort: TableSort) => {
        setTableSort(tableSort);

        dispatch(banksLoadBanksListThunk({
            name: filter.name,
            bic: filter.bic,
            page,
            sortBy: JSON.stringify(tableSort),
            limit: BANKS_SCALE,
        }));
    };

    return (
        <>
            <div className={styles.block}>
                {!isFormDisplayed && (
                    <button
                        className={styles.button}
                        onClick={() => setIsFormDisplayed(true)}
                    >Добавить банк</button>
                )}
                {isFormDisplayed && (
                    <div className={styles.wrapper}>
                        <div className={styles.controls}>
                            <button
                                className={styles.button}
                                onClick={() => setIsFormDisplayed(false)}
                            >Отмена</button>
                        </div>
                        <BanksForm
                            isEditFile={true}
                            onSubmit={handleFormSubmit}
                        />
                    </div>
                )}
            </div>
            <div className={styles.block}>
                <BanksSearchForm
                    onSubmit={handleSearchBanksFormSubmit}
                />
            </div>
            {!!banks.length && (
                <div className={styles.block}>
                    {banksCount > BANKS_SCALE && (
                        <Paginator
                            parentPage={page}
                            parentPageIndex={pageIndex}
                            buttonsCount={5}
                            items={banksCount}
                            scale={BANKS_SCALE}
                            onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                        />
                    )}
                    {!isLoading && (
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th
                                        className={styles.tableSort}
                                        onClick={() => {
                                            handleTableSort({
                                                field: 'name',
                                                sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                            });
                                        }}
                                    >Наименование {tableSort?.sort && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                    <th>Внутреннее наименование</th>
                                    <th>БИК</th>
                                    <th>Организация</th>
                                    <th>Инн организации</th>
                                    <th>Кпп организации</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {banks.map((bank, index) => (
                                    <BanksListItem
                                        key={bank.bankId}
                                        bank={bank}
                                        index={index + (page - 1) * BANKS_SCALE}
                                    />
                                ))}
                            </tbody>
                        </table>
                    )}
                    {banksCount > BANKS_SCALE && (
                        <Paginator
                            parentPage={page}
                            parentPageIndex={pageIndex}
                            buttonsCount={5}
                            items={banksCount}
                            scale={BANKS_SCALE}
                            onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                        />
                    )}
                    {isLoading && (
                        // TODO сделать лоадер
                        <h2>ЗАГРУЗКА...</h2>
                    )}
                </div>
            )}
            {!banks.length && (
                <div className={styles.block}>
                    <b>Банки не найдены</b>
                </div>
            )}
        </>
    )
};

export default BanksListPage;