import React from 'react';
import { useFormik } from 'formik';
import { AgentsSearchFormActionProps } from '../../../../store/agents/agentsSlice';
import styles from './AgentsSearchForm.module.scss';

interface AgentsSearchFormProps {
    onSubmit: (formData: AgentsSearchFormActionProps) => void;
};

const AgentsSearchForm = ({
    onSubmit,
}: AgentsSearchFormProps) => {
    const formik = useFormik({
        initialValues: {
            inn: '',
            organisationName: '',
        },
        onSubmit: (props: AgentsSearchFormActionProps) => {
            onSubmit(props);
        },
    });

    const handleResetForm = (e: any) => {
        formik.handleReset(e);
        onSubmit({});
    };

    return (
        <div>
            <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
            >
                <div className={styles.controls}>
                    <input
                        type="text"
                        name="organisationName"
                        value={formik.values.organisationName}
                        onChange={formik.handleChange}
                        placeholder="Название организации"
                        className={styles.input}
                    />
                    <input
                        type="text"
                        name="inn"
                        value={formik.values.inn}
                        onChange={formik.handleChange}
                        placeholder="ИНН"
                        className={styles.input}
                    />
                </div>
                <div className={styles.controls}>
                    <button
                        className={styles.button}
                        type="submit"
                    >
                        Найти агентов
                    </button>
                    <button
                        className={styles.button}
                        type="button"
                        onClick={handleResetForm}
                    >
                        Сбросить фильтр
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AgentsSearchForm;
