import React from 'react';
import { useFormik } from 'formik';
import { UsersSearchFormActionProps } from '../../../../store/users/usersSlice';
import styles from './UsersSearchForm.module.scss';

interface UsersSearchFormProps {
    onSubmit: (formData: UsersSearchFormActionProps) => void;
};

const UsersSearchForm = ({
    onSubmit,
}: UsersSearchFormProps) => {
    const formik = useFormik({
        initialValues: {
            login: '',
            email: '',
        },
        onSubmit: (props: UsersSearchFormActionProps) => {
            onSubmit(props);
        },
    });

    const handleResetForm = (e: any) => {
        formik.handleReset(e);
        onSubmit({});
    };

    return (
        <div>
            <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
            >
                <div className={styles.controls}>
                    <input
                        type="text"
                        name="login"
                        value={formik.values.login}
                        onChange={formik.handleChange}
                        placeholder="Логин"
                        className={styles.input}
                    />
                    <input
                        type="text"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Email"
                        className={styles.input}
                    />
                </div>
                <div className={styles.controls}>
                    <button
                        className={styles.button}
                        type="submit"
                    >
                        Найти пользователей
                    </button>
                    <button
                        className={styles.button}
                        type="button"
                        onClick={handleResetForm}
                    >
                        Сбросить фильтр
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UsersSearchForm;