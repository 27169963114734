import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectorUsersCount, selectorUsersIsLoading, selectorUsersList, User, usersAdminLoadUsersListThunk, usersCreateUserThunk, UsersFormActionProps, UsersSearchFormActionProps, USERS_SCALE } from '../../../../store/users/usersSlice';
import UsersForm from '../../components/form/UsersForm';
import UsersListItem from './item/UsersListItem';
import UsersSearchForm from '../../components/search-form/UsersSearchForm';
import Paginator from '../../../../components/paginator/Paginator';
import styles from './UsersListPage.module.scss';

// TODO интерфейс может расширятся в дальнейшем
interface TableSort {
    field: string;
    sort: number;
};

const UsersListPage = () => {
    const dispatch = useAppDispatch();

    const users = useAppSelector(selectorUsersList);
    const usersCount = useAppSelector(selectorUsersCount);
    const isLoading = useAppSelector(selectorUsersIsLoading);

    const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [tableSort, setTableSort] = useState<TableSort | null>(null);
    const [filter, setFilter] = useState<UsersSearchFormActionProps>({});

    const handlePaginatorClick = (page: number, pageIndex: number) => {
        setPage(page);
        setPageIndex(pageIndex);

        dispatch(usersAdminLoadUsersListThunk({
            login: filter.login,
            email: filter.email,
            page,
            sortBy: tableSort ? JSON.stringify(tableSort): '',
            limit: USERS_SCALE,
        }));
    };

    const handleSearchUsersFormSubmit = ({
        login,
        email,
    }: UsersSearchFormActionProps) => {
        filter.login = login || '';
        filter.email = email || '';

        setFilter(filter);

        dispatch(usersAdminLoadUsersListThunk({
            login,
            email,
            page: 1, // поиск начинается всегда с первой страницы
            sortBy: tableSort ? JSON.stringify(tableSort): '',
            limit: USERS_SCALE,
            onSuccess: () => {
                setPage(1);
                setPageIndex(1);
            },
        }));
    };

    const handleTableSort = (tableSort: TableSort) => {
        setTableSort(tableSort);

        dispatch(usersAdminLoadUsersListThunk({
            login: filter.login,
            email: filter.email,
            page,
            sortBy: JSON.stringify(tableSort),
            limit: USERS_SCALE,
        }));
    };

    const handleFormSubmit = (props: UsersFormActionProps) => {
        const {
            login,
            password,
            firstName,
            middleName,
            lastName,
            phone,
            email,
            roles,
            // agentId,
        } = props;

        dispatch(usersCreateUserThunk({
            login,
            password,
            firstName,
            middleName,
            lastName,
            phone,
            email,
            roles,
            // agentId,
        }));
    };

    return (
        <>
            <div className={styles.block}>
                {!isFormDisplayed && (
                    <button
                        className={styles.button}
                        onClick={() => setIsFormDisplayed(true)}
                    >Добавить пользователя</button>
                )}
                {isFormDisplayed && (
                    <>
                        <button
                            className={styles.button}
                            onClick={() => setIsFormDisplayed(false)}
                        >Отмена</button>
                        <UsersForm
                            isNewUser={true}
                            onSubmit={handleFormSubmit}
                        />
                    </>
                )}
            </div>
            <div className={styles.block}>
                <UsersSearchForm
                    onSubmit={handleSearchUsersFormSubmit}
                />
            </div>
            <div className={styles.block}>
                {usersCount > USERS_SCALE && (
                    <Paginator
                        parentPage={page}
                        parentPageIndex={pageIndex}
                        buttonsCount={5}
                        items={usersCount}
                        scale={USERS_SCALE}
                        onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                    />
                )}
                {!isLoading && (
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th
                                    className={styles.tableSort}
                                    onClick={() => {
                                        handleTableSort({
                                            field: 'login',
                                            sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                        });
                                    }}
                                >Логин {tableSort?.sort && tableSort?.field === 'login' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                <th
                                    className={styles.tableSort}
                                    onClick={() => {
                                        handleTableSort({
                                            field: 'email',
                                            sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                        });
                                    }}
                                >Email {tableSort?.sort && tableSort?.field === 'email' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                <th>ФИО</th>
                                <th
                                    className={styles.tableSort}
                                    onClick={() => {
                                        handleTableSort({
                                            field: 'createdTimestamp',
                                            sort: tableSort ? tableSort.sort * -1 : 1, // cмена сортировки по возрастанию/убыванию
                                        });
                                    }}
                                >Дата создания {tableSort?.sort && tableSort?.field === 'createdTimestamp' && (tableSort?.sort < 0 ? '↓' : '↑')}</th>
                                <th>Роль</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!users && users.map((user, index) => (
                                <UsersListItem
                                    key={user.userId}
                                    user={user}
                                    index={index + (page - 1) * USERS_SCALE}
                                />
                            ))}
                        </tbody>
                    </table>
                )}
                {isLoading && (
                    // TODO сделать лоадер
                    <h2>Загрузка...</h2>
                )}
                {usersCount > USERS_SCALE && (
                    <Paginator
                        parentPage={page}
                        parentPageIndex={pageIndex}
                        buttonsCount={5}
                        items={usersCount}
                        scale={USERS_SCALE}
                        onPage={(page, pageIndex) => handlePaginatorClick(page, pageIndex)}
                    />
                )}
            </div>
        </>
    )
};

export default UsersListPage;