import React from 'react';
import { useFormik } from 'formik';
import { PeriodStatuses, PeriodsSearchFormActionProps } from '../../../../store/periods/periodsSlice';
import { translatePeriodStatuses } from '../../../../helpers/helpers';
import styles from './PeriodsSearchForm.module.scss';

interface PeriodsSearchFormProps {
    onSubmit: (formData: PeriodsSearchFormActionProps) => void;
};

const PeriodsSearchForm = ({
    onSubmit,
}: PeriodsSearchFormProps) => {
    const formik = useFormik({
        initialValues: {
            bankName: '',
            status: PeriodStatuses.CREATED,
        },
        onSubmit: (props: PeriodsSearchFormActionProps) => {
            onSubmit(props);
        },
    });

    const handleResetForm = (e: any) => {
        formik.handleReset(e);
        onSubmit({});
    };

    return (
        <div>
            <form
                className={styles.form}
                onSubmit={formik.handleSubmit}
            >
                <div className={styles.controls}>
                    <input
                        type="text"
                        name="bankName"
                        value={formik.values.bankName}
                        onChange={formik.handleChange}
                        placeholder="Название банка"
                        className={styles.input}
                    />
                    <select
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        required={true}
                        className={styles.select}
                    >
                        <option value="" disabled>Статус</option>
                        <option value={[PeriodStatuses.CREATED]}>{translatePeriodStatuses(PeriodStatuses.CREATED)}</option>
                        <option value={[PeriodStatuses.DECLINE]}>{translatePeriodStatuses(PeriodStatuses.DECLINE)}</option>
                        <option value={[PeriodStatuses.SUCCESS]}>{translatePeriodStatuses(PeriodStatuses.SUCCESS)}</option>

                    </select>
                </div>
                <div className={styles.controls}>
                    <button
                        className={styles.button}
                        type="submit"
                    >
                        Найти периоды
                    </button>
                    <button
                        className={styles.button}
                        type="button"
                        onClick={handleResetForm}
                    >
                        Сбросить фильтр
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PeriodsSearchForm;