import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FileLoader from '../../../components/file-loader/FileLoader';
import { translateReportStatuses } from '../../../helpers/helpers';
import {
    agentsAgentLoadCurrentAgentThunk,
    agentsSetCurrentAgentAction,
    selectorCurrentAgent,
} from '../../../store/agents/agentsSlice';
import {
    DocTypes,
    filesLoadFilesListThunk,
    filesSetFilesListAction,
} from '../../../store/files/filesSlice';
import {
    periodsAgentLoadCurrentPeriodThunk,
    periodsSetCurrentPeriodAction,
    selectorCurrentPeriod,
} from '../../../store/periods/periodsSlice';
import {
    FileStatuses,
    reportsAgentLoadCurrentReportThunk,
    reportsAgentSendStatusThunk,
    reportsCreateAgentFileThunk,
    reportsSendMessageThunk,
    reportsSetCurrentReportAction,
    ReportStatuses,
    reportsUploadFileThunk,
    selectorCurrentReport,
    UploadedFile,
} from '../../../store/reports/reportsSlice';
import AgentFileItem from '../components/file/AgentFileItem';
import styles from './AgentReportInfoPage.module.scss';
import cn from 'classnames';

const TIMER = 15 * 1000;

const AgentReportInfoPage = () => {
    const dispatch = useAppDispatch();

    const params = useParams();
    const reportId = params.reportId;

    // TODO переделать на отдельный эндпоинт для выборки
    // TODO планируемая оптимизация: брать селектором из готового списка, даже если он отфильтрован пагинаций, и дополнительно загружать по current при обновлении страницы и не нашёл нужный отчёт
    
    const report = useAppSelector(selectorCurrentReport);
    const period = useAppSelector(selectorCurrentPeriod); // TODO нужно избавится о данных периода
    const agent = useAppSelector(selectorCurrentAgent);

    const [message, setMesage] = useState<string>('');
    const [isAddFileDisplayed, setIsAddFileDisplayed] = useState<boolean>(false);
    //TODO массовая отправка файлов
    const [files, setFiles] = useState<UploadedFile[]>([]);

    const fileLoaderRef = useRef<any>(null);

    useEffect(() => {
        dispatch(reportsAgentLoadCurrentReportThunk({ reportId: reportId || ''}));
        
        // TODO проработать переодическое обновление, т.к он запускает лоадер и выглядит неоч
        const interval = setInterval(() => {
            dispatch(reportsAgentLoadCurrentReportThunk({ reportId: reportId || ''}));
        }, TIMER);
        
        return () => {
            // обнуление стейта при первом рендере
            dispatch(reportsSetCurrentReportAction(null));
            dispatch(periodsSetCurrentPeriodAction(null));
            dispatch(filesSetFilesListAction([]));
            dispatch(agentsSetCurrentAgentAction(null));

            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        let fileIds: string[] = [];
        
        // формирование массива id файлов
        if (!!report) {
            const {
                mainDocs = [], // пустой массив по умолчанию
                agentDocs = [],
            } = report;

            const files = mainDocs.concat(agentDocs);
            
            files.forEach(({ fileId }) => {
                fileIds.push(fileId);
            });

            // TODO необходимо избавится от лишних данных и периоде, и убрать запрос поиска периода, а возвращать собранные данные одним ответом от сервера
            dispatch(periodsAgentLoadCurrentPeriodThunk({ periodId: report.periodId }));
            dispatch(filesLoadFilesListThunk({ fileIds }));
            dispatch(agentsAgentLoadCurrentAgentThunk({ agentId: report.agentId }));
        }
    }, [report]);

    const getFile = (files: UploadedFile[]) => {
        setFiles(files);
    };
    
    const saveDoc = () => {
        if (report && period && agent && !!files.length) {
            dispatch(reportsUploadFileThunk({
                files,
                reportId: report.reportId,
                agentInn: agent?.inn,
                onSuccess: (fileIds) => {
                    dispatch(reportsCreateAgentFileThunk({
                        reportId: report.reportId,
                        fileIds,
                        docType: DocTypes.REPORT, // TODO может быть несколько видов файлов
                    }));

                    setFiles([]);
                    fileLoaderRef.current.clear();
                }
            }));
        }
    };

    const handleAcceptFiles = () => {
        if (reportId) {
            dispatch(reportsAgentSendStatusThunk({ reportId }));
        }
    };

    const handleMessageSubmit = () => {
		if (reportId) {
            dispatch(reportsSendMessageThunk({
                reportId,
                message,
            }));

            setMesage('');
        }
	};

    const handleMessageKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            
            handleMessageSubmit();
		}
	};
    
    return (
        <div>
            {/* TODO сделать в идеале каждый эелемент это свой компонент со своим current стейтом из которых потом собирается страница */}
            {!!report && (
                <div className={styles.content}>
                    {/* <h2>Акт № {reportId}</h2> */}
                    {!!period && (
                        <div className={styles.block}>
                            <h2>Информация о отчетё</h2>
                            <div className={styles.blockList}>
                                <div>Банк: <b>{period.bankName}</b></div>
                                <div>Организация: <b>{period.organisationName}</b></div>
                                <div>Статус: <b>{translateReportStatuses(report.status)}</b></div>
                                <div>Создан: <b>{new Date(report.createdTimestamp || 0).toLocaleString('sv')}</b></div>
                                <div>Обновлён: <b>{new Date(report.updatedTimestamp || 0).toLocaleString('sv')}</b></div>
                                <div>Продукт: <b>{period.product}</b></div>
                            </div>
                        </div>
                    )}
                    {!period && (
                        // TODO loader
                        <div>Загрузка...</div>
                    )}

                    {!!agent && (
                        <div className={styles.block}>
                            <h2>Информация об агенте</h2>
                            <div className={styles.blockList}>
                                <div>Агент: <b>{report.agentId}</b></div>
                                <div>ФИО: <b>{agent.headLastName} {agent.headFirstName} {agent.headMiddleName}</b></div>
                                <div>ИНН: <b>{agent.inn}</b></div>
                                <div>КПП: <b>{agent.kpp}</b></div>
                                <div>Организация: <b>{agent.organisationName}</b></div>
                                <div>Должность: <b>{agent.headPost}</b></div>
                                <div><b>Реквизиты:</b>
                                    <div>БАНК: <b>{agent.requisites.bank}</b></div>
                                    <div>БИК: <b>{agent.requisites.bic}</b></div>
                                    <div>Корреспондентский счет: <b>{agent.requisites.correspondentAccount}</b></div>
                                    <div>Расчетный счет: <b>{agent.requisites.paymentAccount}</b></div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!agent && (
                        <div>Загрузка...</div>
                    )}
                    {/* TODO компонент коментариев */}
                    <div className={styles.documents}>
                        <div className={styles.documentsWrapper}>
                            <div className={styles.block}>
                                <h2>Документы контрагента</h2>
                                {!!report.mainDocs && (
                                    <>
                                        {!!report.mainDocs.length && report.mainDocs.map((doc, index) => (
                                            <div
                                                key={index}
                                                className={cn(styles.file)}
                                            >
                                                {/* TODO Можео объеденить с FileItem вы общий компонент */}
                                                <div>{index + 1}</div>
                                                <AgentFileItem file={doc}/>
                                            </div>
                                        ))}
                                        {!report.mainDocs.length && <div>Документы пока не сформированны</div>}
                                    </> 
                                )}
                                {!report.mainDocs && (
                                    <div>Загрузка...</div>
                                )}
                            </div>
                            {report.mainDocs && !!report.mainDocs.length && (
                                <div className={styles.block}>
                                    <h2>Документы агента</h2>
                                    <div>
                                        {report.status !== ReportStatuses.ACCEPTED && report.status !== ReportStatuses.CANCELED && (
                                            <div className={styles.controls}>
                                                {!isAddFileDisplayed && (
                                                    <button
                                                        className={styles.button}
                                                        onClick={() => setIsAddFileDisplayed(true)}
                                                    >Прикрепить файл</button>
                                                )}
                                                {isAddFileDisplayed && (
                                                    <>
                                                        <FileLoader
                                                            type='.sig,.doc,.docx'
                                                            ref={fileLoaderRef}
                                                            onLoadFile={getFile}
                                                            multiply={true}
                                                        />
                                                        <button
                                                            className={styles.button}
                                                            disabled={!files.length}
                                                            onClick={saveDoc}
                                                        >Сохранить</button>
                                                        <button
                                                            className={styles.button}
                                                            onClick={() => setIsAddFileDisplayed(false)}
                                                        >Отмена</button>
                                                    </>
                                                )}
                                                {!!report.agentDocs && report.status !== ReportStatuses.ON_VERIFICATION && (
                                                    <button
                                                        className={styles.button}
                                                        disabled={report.agentDocs.length < 4}
                                                        onClick={handleAcceptFiles}
                                                    >Отправить на проверку</button>
                                                )}
                                            </div>
                                        )}
                                        {!!report.agentDocs && report.agentDocs.map((doc, index) => (
                                            <div
                                                key={index}
                                                className={cn(styles.file, {
                                                    [styles.fileAccepted]: doc.status === FileStatuses.ACCEPTED,
                                                    [styles.fileDecilned]: doc.status === FileStatuses.DECLINED,
                                                })}
                                            >
                                                <div>{index + 1}</div>
                                                <AgentFileItem file={doc}/>
                                            </div>
                                        ))}
                                        {!report.agentDocs && <div>Загрузите файлы и подписи к ним</div>}
                                    </div>
                                    {/* {!report.agentDocs && !report.agentDocs !== undefined && (
                                        <div>Загрузка...</div>
                                    )} */}
                                </div>
                            )}
                        </div>
                        {/* TODO вынести в другой компонент и добавить formik */}
                        <div className={cn(styles.block, styles.messanger)}>
                            <h2>Коментарии</h2>
                            <div className={styles.messangerMessages}>
                                <div>
                                    {report.messages && report.messages.map((message) => (
                                        <div
                                            key={message.createdTimestamp}
                                            className={styles.messangerMessage}
                                        >
                                            <div><b>{message.author}: </b>{message.message}</div>
                                            <div className={styles.messangerMessageDate}>
                                                <b>{new Date(message.createdTimestamp).toLocaleString('sv')}</b>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.messangerText}>
                                <textarea
                                    placeholder="Сообщение"
                                    className={styles.messangerTextInput}
                                    value={message}
                                    onKeyDown={handleMessageKeyDown}
                                    onChange={(e) => { setMesage(e.target.value); }}
                                />
                                <button
                                    className={styles.button}
                                    onClick={handleMessageSubmit}
                                >{`>`}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(!report) && (
                <h2>ЗАГРУЗКА...</h2>
            )}
        </div>
    );
};

export default AgentReportInfoPage;