import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FileLoader from '../../../../components/file-loader/FileLoader';
import { translateReportStatuses } from '../../../../helpers/helpers';
import {
    agentsManagerLoadCurrentAgentThunk,
    agentsSetCurrentAgentAction,
    selectorCurrentAgent
} from '../../../../store/agents/agentsSlice';
import { DocTypes, filesLoadFilesListThunk, filesSetFilesListAction } from '../../../../store/files/filesSlice';
import {
    periodsLoadCurrentPeriodThunk,
    periodsSetCurrentPeriodAction,
    selectorCurrentPeriod,
} from '../../../../store/periods/periodsSlice';
import {
    Doc,
    FileStatuses,
    reportsCreateMainFileThunk,
    ReportsEmailFormActionProps,
    reportsLoadCurrentReportThunk,
    reportsManagerSendMessageThunk,
    reportsSendDocsForAccountingThunk,
    reportsSendFileStatusThunk,
    reportsSendStatusThunk,
    reportsSetCurrentReportAction,
    reportsSetDocsAction,
    ReportStatuses,
    reportsUploadFileThunk,
    selectorCurrentReport,
    selectorDocsListForAccouning,
    UploadedFile,
} from '../../../../store/reports/reportsSlice';
import { selectorUserProfile } from '../../../../store/users/usersSlice';
import ManagerAgentFileItem from '../../components/file/ManagerAgentFileItem';
import ManagerFileItem from '../../components/file/ManagerFileItem';
import styles from './ReportInfoPage.module.scss';
import cn from 'classnames';
import Modal from '../../../../components/modal/Modal';
import ReportsEmailForm from '../../components/email-form/ReportsEmailForm';

const TIMER = 5 * 1000;

const ReportInfoPage = () => {
    const dispatch = useAppDispatch();

    const params = useParams();
    const reportId = params.reportId;

    // TODO переделать на отдельный эндпоинт для выборки
    
    const report = useAppSelector(selectorCurrentReport);
    const period = useAppSelector(selectorCurrentPeriod);
    const agent = useAppSelector(selectorCurrentAgent);
    const profile = useAppSelector(selectorUserProfile);
    const docsListForAccounting = useAppSelector(selectorDocsListForAccouning);

    const [message, setMesage] = useState<string>('');
    const [isAddFileDisplayed, setIsAddFileDisplayed] = useState<boolean>(false);
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

    const fileLoaderRef = useRef<any>(null);

    useEffect(() => {
        dispatch(reportsLoadCurrentReportThunk({ reportId: reportId || ''}));

        const interval = setInterval(() => {
            dispatch(reportsLoadCurrentReportThunk({ reportId: reportId || ''}));
        }, TIMER);
        
        return () => {
            // обнуление стейта при первом рендере        
            dispatch(reportsSetCurrentReportAction(null));
            dispatch(periodsSetCurrentPeriodAction(null));
            dispatch(filesSetFilesListAction([]));
            dispatch(agentsSetCurrentAgentAction(null));

            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        let fileIds: string[] = [];

        // формирование массива id файлов
        if (!!report) {
            const {
                mainDocs = [], // пустой массив по умолчанию
                agentDocs = [],
            } = report;

            const files = mainDocs.concat(agentDocs);
            
            files.forEach(({ fileId }) => {
                fileIds.push(fileId);
            });

            // TODO тут баг мерцания из за того что перезаписывается current данные. Возможно стоит использователь селектор из существующих
            // TODO необходимо избавится от лишних данных и периоде, и убрать запрос поиска периода, а возвращать собранные данные одним ответом от сервера
            dispatch(periodsLoadCurrentPeriodThunk({ periodId: report.periodId }))
            dispatch(filesLoadFilesListThunk({ fileIds }));
            dispatch(agentsManagerLoadCurrentAgentThunk({ agentId: report.agentId }))
        }
    }, [report]);

    const getFile = (files: UploadedFile[]) => {
        setFiles(files);
    };
    
    const saveDoc = () => {
        if (report && period && agent) {
            // TODO переделать в один эндпоинт и отправлять массив файлов
            dispatch(reportsUploadFileThunk({
                files,
                reportId: report.reportId,
                agentInn: agent?.inn,
                onSuccess: (fileIds) => {
                    dispatch(reportsCreateMainFileThunk({
                        reportId: report.reportId,
                        fileIds,
                        docType: DocTypes.REPORT, // TODO может быть несколько видов файлов
                    }));

                    setFiles([]);
                    fileLoaderRef.current.clear();
                },
            }));
        }
    };

    const handleMessageSubmit = () => {
		if (reportId) {
            dispatch(reportsManagerSendMessageThunk({
                reportId,
                message,
            }));

            setMesage('');
        }
	};

    const handleMessageKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            
            handleMessageSubmit();
		}
	};

    const handleChangeAll = ({
        docs,
        docStatus,
    }: {
        docs: Doc[];
        docStatus: FileStatuses;
    }) => {
        if (docs && reportId) {
            const fileIds = docs.map(({ fileId }) => fileId);
            
            dispatch(reportsSendFileStatusThunk({
                reportId,
                fileIds,
                docStatus,
            }));
        }
    };

    const handleEmailFormSubmit = ({
        targetMail,
    }: ReportsEmailFormActionProps) => {
        if (profile?.userInfo) {
            dispatch(reportsSendDocsForAccountingThunk({
                targetMail,
                docs: docsListForAccounting,
                managerFio: `${profile?.userInfo?.lastName} ${profile?.userInfo?.firstName} ${profile?.userInfo?.middleName}`,
                managerMail: profile?.userInfo?.email,
                agentInn: agent?.inn || '',
            }));
        }

        onModalClose();
    };

    const onModalClose = () => {
        setIsModalOpened(false);
    };

    return (
        <div >
            {/* TODO сделать в идеале каждый эелемент это свой компонент со своим current стейтом из которых потом собирается страница */}
            {report && (
                <div>
                    <div className={styles.block}>
                        <h2>Акт № {reportId}</h2>
                        <div className={cn(
                            styles.controls,
                            styles.controlsBlock,
                        )}>
                            {report.status !== ReportStatuses.ON_REVISION && (
                                <button
                                    className={styles.button}
                                    onClick={() => {
                                        if (reportId) {
                                            dispatch(reportsSendStatusThunk({
                                                reportId,
                                                reportStatus: ReportStatuses.ON_REVISION,
                                            }));
                                        }
                                    }}
                                >На доработку</button>
                            )}
                            {report.status !== ReportStatuses.ACCEPTED && (
                                <button
                                    className={styles.button}
                                    onClick={() => {
                                        if (reportId) {
                                            setIsModalOpened(true);
        
                                            dispatch(reportsSendStatusThunk({
                                                reportId,
                                                reportStatus: ReportStatuses.ACCEPTED,
                                            }));
                                        }
                                    }}
                                >Проверен</button>
                            )}
                            {report.status !== ReportStatuses.CANCELED && (
                                <button
                                    className={styles.button}
                                    onClick={() => {
                                        if (reportId) {
                                            dispatch(reportsSendStatusThunk({
                                                reportId,
                                                reportStatus: ReportStatuses.CANCELED,
                                            }));
                                        }
                                    }}
                                >Отменить</button>
                            )}
                        </div>
                    </div>
                    {!!period && (
                        <div className={styles.block}>
                            <h2>Информация об отчетё</h2>
                            <div className={styles.blockList}>
                                <div>Банк: <b>{period.bankName}</b></div>
                                <div>Организация: <b>{period.organisationName}</b></div>
                                <div>Статус: <b>{translateReportStatuses(report.status)}</b></div>
                                <div>Создан: <b>{new Date(report.createdTimestamp || 0).toLocaleString('sv')}</b></div>
                                <div>Обновлён: <b>{new Date(report.updatedTimestamp || 0).toLocaleString('sv')}</b></div>
                            </div>
                        </div>
                    )}
                    {!period && (
                        <h3>Загрузка банка...</h3>
                    )}
                    {/* TODO небольшой баг мерцания не обнуляется агент */}
                    {!!agent && (
                        <div className={styles.block}>
                            <h2>Ифнормация об агенте</h2>
                            <div className={styles.blockList}>
                                <div>Агент: <b><a href={`/manager/agents/${report.agentId}`}>Ссылка</a></b></div>
                                <div>ФИО: <b>{agent.headLastName} {agent.headFirstName} {agent.headMiddleName}</b></div>
                                <div>ИНН: <b>{agent.inn}</b></div>
                                <div>КПП: <b>{agent.kpp}</b></div>
                                <div>Организация: <b>{agent.organisationName}</b></div>
                                <div>Должность: <b>{agent.headPost}</b></div>
                                <div>Реквизиты:
                                    <div>БАНК: <b>{agent.requisites.bank}</b></div>
                                    <div>БИК: <b>{agent.requisites.bic}</b></div>
                                    <div>Корреспондентский счет: <b>{agent.requisites.correspondentAccount}</b></div>
                                    <div>Расчетный счет: <b>{agent.requisites.paymentAccount}</b></div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!agent && (
                        <h3>Загрузка агента...</h3>
                    )}
                    <div className={styles.documents}>
                        <div className={styles.documentsWrapper}>
                            <div className={styles.block}>
                                <div className={styles.title}>
                                    <h2>Документы агента</h2>
                                    <div className={styles.controls}>
                                        <button
                                            className={styles.button}
                                            onClick={() => setIsModalOpened(true)}
                                            disabled={!docsListForAccounting.length || report.status !== ReportStatuses.ACCEPTED}
                                        >Отправить документы</button>
                                        <button
                                            className={styles.button}
                                            disabled={!report.agentDocs}
                                            onClick={() => handleChangeAll({
                                                docs: report.agentDocs,
                                                docStatus: FileStatuses.ACCEPTED
                                            })}
                                        >Подтвердить все</button>
                                    </div>
                                </div>
                                <div>
                                    {!!report.agentDocs && report.agentDocs.map((doc, index) => (
                                        <div
                                            key={doc.fileId}
                                            className={cn(styles.file, {
                                                [styles.fileAccepted]: doc.status === FileStatuses.ACCEPTED,
                                                [styles.fileDeclined]: doc.status === FileStatuses.DECLINED,
                                            })}
                                        >
                                            <div>{index + 1}</div>
                                            <ManagerAgentFileItem
                                                file={doc}
                                                reportId={reportId || ''}
                                            />
                                        </div>
                                    ))}
                                    {!report.agentDocs && (
                                        <div>Агент пока не загрузил документы!</div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.block}>
                                <div>
                                    <h2>Документы контрагента</h2>
                                    <div className={styles.controls}>
                                        <button
                                            className={styles.button}
                                            disabled={!report.mainDocs}
                                            onClick={() => handleChangeAll({
                                                docs: report.mainDocs,
                                                docStatus: FileStatuses.ACCEPTED
                                            })}
                                        >Подтвердить все</button>
                                        {!isAddFileDisplayed && (
                                            <button
                                                className={styles.button}
                                                onClick={() => setIsAddFileDisplayed(true)}
                                            >Прикрепить файл</button>
                                        )}
                                        {isAddFileDisplayed && (
                                            <>
                                                <FileLoader
                                                    type='.sig,.doc,.docx'
                                                    onLoadFile={getFile}
                                                    multiply={true}
                                                    ref={fileLoaderRef}
                                                />
                                                <button
                                                    className={styles.button}
                                                    onClick={saveDoc}
                                                >Сохранить</button>
                                                <button
                                                    className={styles.button}
                                                    onClick={() => setIsAddFileDisplayed(false)}
                                                >Отмена</button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {!!report.mainDocs.length && report.mainDocs.map((doc, index) => (
                                    <div
                                        key={doc.fileId}
                                        className={cn(styles.file, {
                                            [styles.fileAccepted]: doc.status === FileStatuses.ACCEPTED,
                                            [styles.fileDecilned]: doc.status === FileStatuses.DECLINED,
                                            [styles.fileHidden]: doc.status === FileStatuses.HIDDEN,
                                        })}
                                    >
                                        <div>{index + 1}</div>
                                        <ManagerFileItem
                                            file={doc}
                                            reportId={reportId || ''}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={cn(styles.block, styles.messanger)}>
                            {/* TODO компонент коментариев */}
                            <h2>Коментарии</h2>
                            <div className={styles.messangerMessages}>
                                <div>
                                    {report.messages && report.messages.map((message) => (
                                        <div
                                            key={message.createdTimestamp}
                                            className={styles.messangerMessage}
                                        >
                                            <div><b>{message.author}: </b>{message.message}</div>
                                            <div className={styles.messangerMessageDate}>
                                                <b>{new Date(message.createdTimestamp).toLocaleString('sv')}</b>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.messangerText}>
                                <textarea
                                    placeholder="Сообщение"
                                    className={styles.messangerTextInput}
                                    value={message}
                                    onKeyDown={handleMessageKeyDown}
                                    onChange={(e) => setMesage(e.target.value)}
                                />
                                <button
                                    className={styles.button}
                                    onClick={handleMessageSubmit}
                                >{`>`}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!report && (
                <h2>ЗАГРУЗКА...</h2>
            )}
            <Modal
                isOpened={isModalOpened}
                onClose={onModalClose}
            >
                <h2>Выберите почту для отправки</h2>
                <ReportsEmailForm onSubmit={handleEmailFormSubmit} />
            </Modal>
        </div>
    );
};

export default ReportInfoPage;