import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch } from '../../../../app/hooks';
import FileLoader from '../../../../components/file-loader/FileLoader';
import { BanksFormActionProps } from '../../../../store/banks/banksSlice';
import styles from './BanksForm.module.scss'
import { UploadedFile } from '../../../../store/reports/reportsSlice';

interface BanksFormProps {
    // bank?: Bank;
    // onHideForm: () => void;
    name?: string;
    aliasName?: string;
    correspondentAccount?: string;
    bic?: string;
    inn?: string;
    kpp?: string;
    siteAddres?: string;
    instance?: string;
    organisationName?: string;
    organisationInn?: string;
    organisationKpp?: string;
    organisationHeadFirstName?: string;
    organisationHeadMiddleName?: string;
    organisationHeadLastName?: string;
    organisationHeadPost?: string;
    // templateFileString?: string;
    isEditFile?: boolean;
    onSubmit: (props: BanksFormActionProps) => void;
};

const BanksForm = ({
    name = '',
    aliasName = '',
    correspondentAccount = '',
    bic = '',
    inn = '',
    kpp = '',
    siteAddres = '',
    instance = '',
    organisationName = '',
    organisationInn = '',
    organisationKpp = '',
    organisationHeadFirstName = '',
    organisationHeadMiddleName = '',
    organisationHeadLastName = '',
    organisationHeadPost = '',
    isEditFile = false,
    // templateFileString ='',
    onSubmit,
}: BanksFormProps) => {
    const dispatch = useAppDispatch();

    // TODO обратока ошибок по аналогии с квайтексом
    const [formError, setFormError] = useState<string>('');
    const [isValidForm, setIsValidForm] = useState<boolean>(false);
    const [reportFile, setReportFile] = useState<string>('');
    const [billFile, setBillFile] = useState<string>('');

    const formik = useFormik({
        initialValues: {
            name,
            aliasName,
            correspondentAccount,
            bic,
            inn,
            kpp,
            siteAddres,
            instance,
            organisationName,
            organisationInn,
            organisationKpp,
            organisationHeadFirstName,
            organisationHeadMiddleName,
            organisationHeadLastName,
            organisationHeadPost,
        },
        onSubmit: ({
            name,
            aliasName,
            correspondentAccount,
            bic,
            inn,
            kpp,
            siteAddres,
            instance,
            organisationName,
            organisationInn,
            organisationKpp,
            organisationHeadFirstName,
            organisationHeadMiddleName,
            organisationHeadLastName,
            organisationHeadPost,
        }) => {
            if (isValidForm) {
                onSubmit({
                    name,
                    aliasName,
                    correspondentAccount,
                    bic,
                    inn,
                    kpp,
                    siteAddres,
                    instance,
                    organisationName,
                    organisationInn,
                    organisationKpp,
                    organisationHeadFirstName,
                    organisationHeadMiddleName,
                    organisationHeadLastName,
                    organisationHeadPost,
                    templateReportFileString: reportFile,
                    templateBillFileString: billFile,
                })
            }
        }
    });

    useEffect(() => {
        if (
            !!formik.values.name
            && !!formik.values.aliasName
            && !!formik.values.correspondentAccount
            && !!formik.values.bic
            && !!formik.values.inn
            && !!formik.values.kpp
            && !!formik.values.siteAddres
            && !!formik.values.instance
            && !!formik.values.organisationName
            && !!formik.values.organisationInn
            && !!formik.values.organisationKpp
            && !!formik.values.organisationHeadFirstName
            && !!formik.values.organisationHeadMiddleName
            && !!formik.values.organisationHeadLastName
            && !!formik.values.organisationHeadPost
        ) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    }, [formik.values]);

    const getReportFile = (files: UploadedFile[]) => {
        const { fileString } = files[0];
        setReportFile(fileString);
    };

    const getBillFile = (files: UploadedFile[]) => {
        const { fileString } = files[0];
        setBillFile(fileString);
    };
    
    return(
        <form
            className={styles.form}
            onSubmit={formik.handleSubmit}
        >
            <div className={styles.formRow}>
                <div className={styles.formTitle}>Название<span>*</span></div>
                <input
                    type="text"
                    placeholder="Название"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>Внутреннее наименование<span>*</span></div>
                <input
                    type="text"
                    placeholder="Внутреннее наименование"
                    name="aliasName"
                    value={formik.values.aliasName}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>Корреспондентский счет<span>*</span></div>
                <input
                    type="text"
                    placeholder="Корреспондентский счет"
                    name="correspondentAccount"
                    value={formik.values.correspondentAccount}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>БИК<span>*</span></div>
                <input
                    type="text"
                    placeholder="БИК"
                    name="bic"
                    value={formik.values.bic}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>ИНН<span>*</span></div>
                <input
                    type="text"
                    placeholder="ИНН"
                    name="inn"
                    value={formik.values.inn}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>КПП<span>*</span></div>
                <input
                    type="text"
                    placeholder="КПП"
                    name="kpp"
                    value={formik.values.kpp}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>Адрес сайта<span>*</span></div>
                <input
                    type="text"
                    placeholder="Адрес сайта"
                    name="siteAddres"
                    value={formik.values.siteAddres}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>Инстанция<span>*</span></div>
                <input
                    type="text"
                    placeholder="Инстанция"
                    name="instance"
                    value={formik.values.instance}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            {/* TODO нужно добавить возможность создания организаций, чтобы не вводить каждый раз вручную */}
            <div className={styles.formRow}>
                <div className={styles.formTitle}>Название организации<span>*</span></div>
                <input
                    type="text"
                    placeholder="Название организации"
                    name="organisationName"
                    value={formik.values.organisationName}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>ИНН организации<span>*</span></div>
                <input
                    type="text"
                    placeholder="ИНН организации"
                    name="organisationInn"
                    value={formik.values.organisationInn}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>КПП организации<span>*</span></div>
                <input
                    type="text"
                    placeholder="КПП организации"
                    name="organisationKpp"
                    value={formik.values.organisationKpp}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>Имя руководителя организации<span>*</span></div>
                <input
                    type="text"
                    placeholder="Имя"
                    name="organisationHeadFirstName"
                    value={formik.values.organisationHeadFirstName}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>Фамилия руководителя организации<span>*</span></div>
                <input
                    type="text"
                    placeholder="Фамилия"
                    name="organisationHeadLastName"
                    value={formik.values.organisationHeadLastName}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>Отчество руководителя организации<span>*</span></div>
                <input
                    type="text"
                    placeholder="Отчество"
                    name="organisationHeadMiddleName"
                    value={formik.values.organisationHeadMiddleName}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            <div className={styles.formRow}>
                <div className={styles.formTitle}>Должность руководителя организации<span>*</span></div>
                <input
                    type="text"
                    placeholder="Должность"
                    name="organisationHeadPost"
                    value={formik.values.organisationHeadPost}
                    onChange={formik.handleChange}
                    required={true}
                    className={styles.input}
                />
            </div>
            {isEditFile && (
                <>
                    <div className={styles.formRow}>
                        <div className={styles.formTitle}>Файл шаблона акта<span>*</span></div>
                            <FileLoader
                                type='.doc,.docx'
                                onLoadFile={getReportFile}
                            />
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.formTitle}>Файл шаблона счёта<span>*</span></div>
                        <FileLoader
                            type='.sig,.doc,.docx'
                            onLoadFile={getBillFile}
                        />
                    </div>
                </>
            )}
            <button
                disabled={!isValidForm}
                type="submit"
                className={styles.button}
            >Готово</button>
        </form>
    )
};

export default BanksForm;